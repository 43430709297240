import { Card, Col, Row, Tooltip } from "antd";
import { Switch } from "@mui/joy";
import Button from "components/UI/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "routers/route";
import { NotificationSettingAction } from "constants/notifications";
import RealTimeAlertFormModal from "./RealTimeAlertFormModal";
import styles from "../EmailNotification.module.scss";
import alertSettingsApi from "api/alert-settings";
import { Alert, AlertFrequency, Selected } from "types/alert-settings";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import ConfirmModal from "components/UI/Modal/ConfirmModal";

const RealTimeAlert = () => {
  const navigate = useNavigate();
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const { action = null } = useParams<{
    action?: NotificationSettingAction;
  }>();

  const getAlerts = useCallback(async () => {
    const dataRes: Alert[] = await alertSettingsApi.get();
    setAlerts(dataRes ?? []);
  }, []);

  useEffect(() => {
    getAlerts();
  }, [getAlerts]);

  const handleSubmit = async (
    companies: Selected[],
    topics: Selected[],
    id?: string
  ) => {
    if (id) {
      return onAlertUpdate({
        ...alerts[0],
        companies,
        topics,
      });
    }
    try {
      await alertSettingsApi.create({
        companies: companies.map((c) => c.id),
        topics: topics.map((t) => t.id),
        frequency: AlertFrequency.REALTIME,
        isActive: true,
      });
      getAlerts();
      onClose();
      toast.success("Real-time alert created");
    } catch (error) {
      toast.error("Failed to create real-time alert");
    }
  };

  const onAlertUpdate = async (alert: Alert) => {
    try {
      await alertSettingsApi.update(
        {
          ...alert,
          companies: alert.companies.map((c) => c.id),
          topics: alert.topics.map((t) => t.id),
        },
        alert.id
      );
      toast.success("Real-time alert updated");
      getAlerts();
      onClose();
    } catch (error) {
      toast.error("Failed to update real-time alert");
    }
  };

  const handleDeleteAlert = async () => {
    try {
      await alertSettingsApi.delete(alerts[0].id);
      toast.success("Real-time alert deleted");
      getAlerts();
      onClose();
    } catch (error) {
      toast.error("Failed to delete real-time alert");
    }
  };

  const onClose = () => {
    navigate(`${Path.Settings}`);
  };

  return (
    <Row gutter={[24, 24]} className={styles["setting-item"]}>
      <Col className={styles["setting-description"]} md={24} lg={6}>
        <h5>Your Real-Time Alerts</h5>
        <span className={styles["subtitle"]}>
          We will send you an email alert for each matching new articles as soon
          as they are published.
        </span>
      </Col>
      <Col sm={24} xs={24} md={24} lg={18}>
        <Card type="inner" title="REAL-TIME ALERTS">
          <Card.Grid
            style={{
              width: "100%",
            }}
            hoverable={false}
          >
            {alerts.length
              ? alerts.map((alert) => (
                  <div key={alert.id}>
                    <div className={styles["notification-item"]}>
                      <div>
                        <Card.Meta
                          className={styles["notification-item-title"]}
                          title="Companies"
                          description={alert.companies
                            .map((c) => c.name)
                            .join(", ")}
                        />
                        <Card.Meta
                          className={styles["notification-item-title"]}
                          style={{
                            marginTop: 5,
                          }}
                          title="Topics"
                          description={alert.topics
                            .map((c) => c.name)
                            .join(", ")}
                        />
                      </div>
                      <Tooltip title="Turn on/off this notification">
                        <Switch
                          checked={alert.isActive}
                          onChange={(e) => {
                            onAlertUpdate({
                              ...alert,
                              isActive: e.target.checked,
                            });
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className={styles["notification-item-buttons"]}>
                      <Button
                        sx={{
                          borderRadius: "8px",
                        }}
                        variant="outlined"
                        onClick={() =>
                          navigate(
                            `${Path.Settings}/${NotificationSettingAction.EDIT_REALTIME_NOTIFICATION}`
                          )
                        }
                      >
                        Edit
                      </Button>
                      <Button
                        sx={{
                          borderRadius: "8px",
                        }}
                        variant="outlined"
                        onClick={() =>
                          navigate(
                            `${Path.Settings}/${NotificationSettingAction.REMOVE_REALTIME_NOTIFICATION}`
                          )
                        }
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                ))
              : "No real-time alerts"}
          </Card.Grid>
        </Card>
        {!alerts.length && (
          <Button
            sx={{
              maxWidth: "200px",
              marginTop: "20px",
            }}
            onClick={() =>
              navigate(
                `${Path.Settings}/${NotificationSettingAction.ADD_REALTIME_NOTIFICATION}`
              )
            }
          >
            Create new alert
          </Button>
        )}
      </Col>

      <>
        {/* Modals */}
        {action === NotificationSettingAction.ADD_REALTIME_NOTIFICATION && (
          <RealTimeAlertFormModal
            open={true}
            onClose={onClose}
            onSubmit={handleSubmit}
          />
        )}
        {action === NotificationSettingAction.EDIT_REALTIME_NOTIFICATION && (
          <RealTimeAlertFormModal
            value={alerts[0]}
            open={true}
            onClose={onClose}
            onSubmit={handleSubmit}
          />
        )}
        {action === NotificationSettingAction.REMOVE_REALTIME_NOTIFICATION && (
          <ConfirmModal
            open={true}
            onClose={onClose}
            onConfirm={handleDeleteAlert}
            variant="danger"
            message="Are you sure you want to proceed?"
          />
        )}
      </>
    </Row>
  );
};

export default RealTimeAlert;
