import { FC } from "react";
import Carousel from "react-multi-carousel";
import AuthContentMobileItem from "components/Pages/Authentication/AuthContentMobile";
import CustomDot from "./CustomDot";
import "../style.scss";

type Props = {
  title: string;
  text: string;
};
const AuthContent: FC<Props> = ({ title, text }) => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <div className="auth-content">
        <div className="auth-content-top">
          <h2
            style={{
              letterSpacing: "normal",
            }}
          >
            {title}
          </h2>
          <p
            style={{
              letterSpacing: "normal",
            }}
          >
            {text}
          </p>
        </div>
      </div>
      <div className="auth-content-mobile">
        <div className="my-own-custom-container">
          <Carousel
            arrows={false}
            partialVisible
            responsive={responsive}
            renderButtonGroupOutside={true}
            customDot={<CustomDot active={false} onClick={() => {}} />}
            showDots
            swipeable
          >
            <AuthContentMobileItem
              text={
                "Join Bondvexity Today Unlock Unmatched Corporate Credit Insights"
              }
            />
            <AuthContentMobileItem
              text={
                "Welcome to Bondvexity, where professional enterprise customers gain access to expert, unbiased research on high-yield and leveraged loan issuers."
              }
            />
            <AuthContentMobileItem
              text={
                "Join our platform to enhance your investment strategies with comprehensive analysis, actionable recommendations, and advanced analytics tools."
              }
            />
          </Carousel>
        </div>
      </div>
    </>
  );
};

export default AuthContent;
