import axiosApiInstance from "../utils/axiosClient";

const authApi = {
  signIn: async (data: any) => {
    const res = await axiosApiInstance.post("/auth/sign-in", data);
    return res.data;
  },
  signUp: async (data: any) => {
    const res = await axiosApiInstance.post("/auth/sign-up", data);
    return res.data;
  },
  forgotPassword: async (data: any) => {
    return axiosApiInstance.post("/auth/forgot-password", data);
  },

  resetPassword: async (data: any) => {
    const res = await axiosApiInstance.post("/auth/reset-password", data);
    return res.data;
  },

  changePassword: async (data: any) => {
    const res = await axiosApiInstance.post("/auth/change-password", data);
    return res.data;
  },
};

export default authApi;
