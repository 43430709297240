import Sidebar from "components/Sidebar/Sidebar";
import { useEffect } from "react";
import "./style.scss";
import { SidebarMobile } from "components/Sidebar/SidebarMobile";
import { checkDevice } from "utils/helpers";

const PrivateLayout = ({ children }: any) => {
  const { isDesktop } = checkDevice();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!isDesktop) {
    return (
      <div className="layout-mobile">
        <SidebarMobile />
        <div
          style={{
            marginTop: 70,
          }}
        >
          {children}
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <Sidebar />
      <div className="cms">{children}</div>
    </div>
  );
};

export default PrivateLayout;
