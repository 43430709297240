import logoImage from "assets/images/logo_group.svg";
import styles from "./JoinBondVexity.module.scss";

const JoinBondVexity = () => {
  return (
    <div className={styles["join-bond-vexity"]}>
      <div className={styles["join-bond-vexity__content"]}>
        <h1 className={styles["join-bond-vexity__title"]}>
          Join Bondvexity: Be Part of the Future of Credit Research
        </h1>
        <p className={styles["join-bond-vexity__description"]}>
          At Bondvexity, we’re revolutionizing the world of corporate credit
          research, and we’re looking for talented individuals to join us on
          this exciting journey. Our team is made up of passionate professionals
          driven by innovation, excellence, and a commitment to providing
          unbiased, real-time insights that empower investors. If you’re ready
          to make an impact in the financial industry, Bondvexity is the place
          for you.
        </p>
      </div>
      <div className={styles["image-container"]}>
        <img src={logoImage} alt="join-us" />
      </div>
    </div>
  );
};

export default JoinBondVexity;
