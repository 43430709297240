import arrow_back from "../../../assets/images/arrow_back.svg";
import arrow_next from "../../../assets/images/arrow_next.svg";

import styles from "./CustomArrow.module.scss";
const CustomArrow = ({
  next,
  previous,
  className,
}: {
  next?: () => void;
  previous?: () => void;
  className?: string;
}) => {
  return (
    <div className={`${styles["custom-arrow"]} ${className}`}>
      <div className={styles["back"]} onClick={() => previous && previous()}>
        <img src={arrow_back} alt="" />
      </div>
      <div className={styles["next"]} onClick={() => next && next()}>
        <img src={arrow_next} alt="" />
      </div>
    </div>
  );
};

export default CustomArrow;
