import { Image } from "antd";
import icon_facebook from "assets/images/icon_facebook.svg";
import icon_instagram from "assets/images/icon_instagram.svg";
import icon_linkedin from "assets/images/icon_linkedin.svg";
import icon_twitter from "assets/images/icon_twitter.svg";
import icon_youtube from "assets/images/icon_youtube.svg";
import logoGroup from "assets/images/logo_group.svg";
import { itemsMenuFooter, subItemsMenuFooter } from "../../../common";

import "./style.scss";
import { Link } from "react-router-dom";
import { checkDevice } from "utils/helpers";
interface Props {
  rootStyle?: React.CSSProperties;
}
const Footer = ({ rootStyle }: Props) => {
  const { isMobile } = checkDevice();

  return (
    <div
      className="footer-wrapper"
      style={{
        ...rootStyle,
      }}
    >
      <div className="footer-container">
        <div className="footer-logo">
          <Image
            className="logo-group"
            src={logoGroup}
            alt=""
            preview={false}
          />
        </div>
        <div className="footer-menu">
          <div
            className="footer-menu__items"
            style={{
              gap: isMobile ? 16 : 40,
            }}
          >
            {itemsMenuFooter.map((item, index) => (
              <Link key={index} to={`/${item.key}`}>
                {item.label}
              </Link>
            ))}
          </div>
          <div
            className="footer-menu__subItems"
            style={{
              gap: isMobile ? 12 : 30,
            }}
          >
            {subItemsMenuFooter.map((item) => (
              <Link key={item.key} to={"#"}>
                {item.label}
              </Link>
            ))}
          </div>
        </div>

        <div className="footer-social">
          <img src={icon_youtube} alt="" />
          <img src={icon_twitter} alt="" />
          <img src={icon_facebook} alt="" />
          <img src={icon_linkedin} alt="" />
          <img src={icon_instagram} alt="" />
        </div>
      </div>
      <div
        className="footer-coppyright"
        style={{
          marginBottom: 16,
        }}
      >
        <p>© 2024 Bondvexity Inc. All rights reserved. </p>
      </div>
    </div>
  );
};

export default Footer;
