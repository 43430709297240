import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
} from "@mui/joy";
import { Col, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import authApi from "api/auth";
import configApi from "api/config";
import icon_select from "assets/images/icon_select.svg";
import logo from "assets/images/icon_logo_auth.svg";
import { httpRequestErrors } from "utils/errors";
import { defaultRule, emailRule, phoneRule } from "utils/rules";
import { trimObject } from "utils/helpers";
import InputPhone from "components/UI/Input/InputPhone";
import FormInput from "components/UI/Form/FormInput";
import { Path } from "routers/route";

export type SignUpDto = {
  email: string;
  phone: string;
  aboutUsIds: number[];
  companyName: string;
  firstName: string;
  lastName: string;
};

const SignUpForm = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<SignUpDto>({
    defaultValues: {
      email: "",
      phone: "",
      aboutUsIds: [],
      companyName: "",
      firstName: "",
      lastName: "",
    },
  });
  const [aboutUs, setAboutUs] = useState<any[]>([]);

  useEffect(() => {
    getAboutUs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAboutUs = useCallback(async () => {
    const res = await configApi.aboutUs();
    if (!res?.length) {
      return;
    }
    setAboutUs(res);
  }, []);

  const onSubmit = async (data: SignUpDto) => {
    try {
      await authApi.signUp(trimObject(data));
      navigate(Path.SignUpSuccess, {
        state: {
          ...data,
          isSetNewPassword: true,
        },
      });
    } catch (error) {
      httpRequestErrors(error);
    }
  };

  return (
    <div className="auth-form">
      <form className="form-content" onSubmit={handleSubmit(onSubmit)}>
        <img className="form-logo" src={logo} alt="" />
        <div className="form">
          <h3>Contact Us About a Trial Subscription</h3>
          <div className="form-item">
            <Row>
              <Col
                span={12}
                style={{
                  paddingRight: 10,
                }}
              >
                <FormInput
                  placeholder="First Name"
                  name="firstName"
                  control={control}
                  label={"First Name"}
                />
              </Col>
              <Col
                span={12}
                style={{
                  paddingLeft: 10,
                }}
              >
                <FormInput
                  placeholder="Last Name"
                  name="lastName"
                  control={control}
                  label={"Last Name"}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormInput
                  placeholder="Company name"
                  name="companyName"
                  control={control}
                  rules={defaultRule("Company name")}
                  label={"Company name"}
                />
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <FormInput
                  placeholder="you@company.com"
                  name="email"
                  control={control}
                  rules={emailRule}
                  label={"E-mail"}
                />
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Controller
                  control={control}
                  name="phone"
                  rules={phoneRule}
                  render={({ field, fieldState }) => (
                    <FormControl>
                      <FormLabel>Phone number</FormLabel>
                      <InputPhone
                        value={field.value}
                        onChange={(newValue) => field.onChange(newValue)}
                      />
                      {fieldState.error && (
                        <FormHelperText
                          sx={{
                            color: "red",
                          }}
                        >
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Controller
                  name="aboutUsIds"
                  control={control}
                  render={({ field }) => {
                    return (
                      <FormControl>
                        <FormLabel>How you heard about us?</FormLabel>
                        <Select
                          {...field}
                          multiple
                          style={{
                            height: 48,
                            padding: "11px 16px",
                          }}
                          value={field.value}
                          onChange={(event, newValue) =>
                            field.onChange(newValue)
                          }
                          indicator={<img src={icon_select} alt="" />}
                          // placeholder="How you heard about us"
                        >
                          {aboutUs.map((item) => {
                            return (
                              <Option key={item.id} value={item.id}>
                                {item.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    );
                  }}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="btn-container">
          <Row>
            <Col span={24}>
              <button type="submit" className="btn-get-started">
                Get Started
              </button>
            </Col>
          </Row>
          <div className="text-bottom">
            <p>
              Already have an account?&nbsp;
              <span onClick={() => navigate("/sign-in")}>Sign In</span>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SignUpForm;
