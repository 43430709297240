import { Controller, ControllerProps } from "react-hook-form";
import { FormControl, FormLabel, FormHelperText, InputProps } from "@mui/joy";
import Input from "../Input/Input";
type Props = Omit<ControllerProps, "render" | "control"> &
  InputProps & {
    control: any;
    label: string;
    inputStyles?: React.CSSProperties;
    styles?: React.CSSProperties;
    styleFormHelperText?: React.CSSProperties;
  };
const FormInput = ({
  control,
  name,
  label,
  rules,
  inputStyles,
  styles,
  styleFormHelperText,
  ...restProps
}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <FormControl
          // error={fieldState.error ? true : false}
          sx={{
            ...styles,
          }}
        >
          <FormLabel>{label}</FormLabel>
          <Input {...field} {...restProps} />
          {fieldState.error && (
            <FormHelperText
              sx={{
                color: "red",
                ...styleFormHelperText,
              }}
            >
              {fieldState.error.message}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
};

export default FormInput;
