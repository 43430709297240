import { Image } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ellipse_59 from "../../../assets/images/ellipse_59.svg";
import logoIconSmalll from "../../../assets/images/logo_icon.svg";
import logo_icon_big from "../../../assets/images/logo_header.svg";
import menu_hamburger from "../../../assets/images/menu_hamburger.svg";
import NavRight from "../NavRight";
import Navigation from "../Navigation";
import MenuDrawer from "./MenuDrawer";
import "./style.scss";
const Header = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const openMenuMobile = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className="header-wrapper">
        <div className="header-container">
          <img
            className="animation-ellipse"
            src={ellipse_59}
            alt=""
            style={{
              zIndex: -1,
            }}
          />

          <div className="btn-menu-mobile">
            <MenuDrawer open={open} setOpen={setOpen} />
            <div className="btn-menu">
              <div onClick={openMenuMobile}>
                <Image
                  className="menu-hamburger"
                  src={menu_hamburger}
                  alt=""
                  preview={false}
                />
              </div>

              <Image
                onClick={() => {
                  navigate("/");
                }}
                className="logo-icon"
                src={logoIconSmalll}
                alt=""
                preview={false}
              />
            </div>
          </div>

          <div
            className="header-img"
            onClick={() => {
              navigate("/");
            }}
          >
            <Image
              className="logo-icon"
              src={logo_icon_big}
              alt=""
              preview={false}
            />
          </div>
          <div className="menu-pc">
            <Navigation />
          </div>
          <NavRight />
        </div>
      </div>
    </>
  );
};

export default Header;
