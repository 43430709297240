import { Grid, GridProps, Sheet } from "@mui/joy";

import { styled } from "@mui/joy/styles";
const BoxMui = styled(Sheet)(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.background.level1 : "#fff",
  ...theme.typography["body-sm"],
  padding: 16,
  borderRadius: 16,
  color: theme.vars.palette.text.secondary,
}));
const BoxGrid = ({
  children,
  style,
  ...props
}: GridProps & { item?: boolean }) => {
  return (
    <Grid {...props}>
      <BoxMui sx={{ ...style }}>{children}</BoxMui>
    </Grid>
  );
};

export default BoxGrid;
