import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormLabel,
  Modal,
  ModalDialog,
  Option,
  Select,
} from "@mui/joy";
import Input from "components/UI/Input/Input";
import TimePicker from "components/UI/TimePicker/TimePicker";
import {
  DIGEST_FREQUENCY_OPTIONS,
  WEEK_DAYS
} from "constants/notifications";
import dayjs from "dayjs";
import { useState } from "react";
import { Alert, AlertFrequency, Selected } from "types/alert-settings";
import { Sector } from "types/company";
import { DigestSettings } from "types/notification";
import styles from "./DigestAlertFormModal.module.scss";
import SelectSection from "./SelectSection";

const format = "HH:mm";

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    companies: Selected[],
    topics: Selected[],
    setting: DigestSettings,
    id?: string
  ) => void;
  value?: Alert;
}

const DigestAlertFormModal = ({ open, onClose, onSubmit, value }: Props) => {
  const [digestSetting, setDigestSetting] = useState<DigestSettings>({
    frequency: value?.frequency!,
    days: value?.weekDays,
    time: value?.deliveryTime! || new Date().toISOString(),
    title: value?.description!,
  });

  const [selectedCompanies, setSelectedCompanies] = useState<Selected[]>(
    value?.companies ?? []
  );
  const [selectedTopics, setSelectedTopics] = useState<Selected[]>(
    value?.topics ?? []
  );

  const handleSelectTopic = (topic: Sector) => {
    if (selectedTopics.find((t) => t.id === topic.id)) {
      setSelectedTopics((prev) => prev.filter((t) => t.id !== topic.id));
    } else {
      setSelectedTopics((prev) => [...prev, topic]);
    }
  };
  const handleSelectCompany = (company: Selected) => {
    if (selectedCompanies.find((c) => c.id === company.id)) {
      setSelectedCompanies((prev) => prev.filter((c) => c.id !== company.id));
    } else {
      setSelectedCompanies((prev) => [...prev, company]);
    }
  };
  const resetSelect = () => {
    // setSelectedCompanies([]);
    // setSelectedTopics([]);
  };

  const handleSubmit = () => {
    onSubmit(
      selectedCompanies.map((c) => ({ id: c.id, name: c.name })),
      selectedTopics.map((t) => ({ id: t.id, name: t.name })),
      digestSetting,
      value?.id
    );
  };

  const disableBtn = () => {
    if (
      (!selectedCompanies.length && !selectedTopics.length) ||
      !digestSetting.title
    ) {
      return true;
    }
    if (digestSetting.frequency === AlertFrequency.WEEKLY) {
      return !digestSetting?.days!.length || !digestSetting?.time;
    }

    return !digestSetting?.time;
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        background: "#162f3f80",
        "& .MuiModalDialog-root": {
          width: 800,
          backgroundColor: "white",
        },
        "& .MuiModal-backdrop": {
          backdropFilter: "none",
        },
      }}
    >
      <ModalDialog variant="outlined">
        <DialogTitle>{value?.id ? "Update a Digest" : "Create New Digest"}</DialogTitle>
        <Divider />
        <DialogContent
          sx={{
            paddingRight: "16px",
          }}
        >
          <div
            className={styles["delivery-preferences"]}
            style={{
              paddingBottom: "16px",
              paddingLeft: "4px",
            }}
          >
            <p>Title</p>
            <Input
              value={digestSetting.title}
              onChange={(e) => {
                setDigestSetting((prev) => ({
                  ...prev,
                  title: e.target.value,
                }));
              }}
              placeholder="Enter digest title"
            />
          </div>

          <div className={styles["delivery-preferences"]}>
            <p>Set Delivery Preferences</p>
            <FormControl>
              <FormLabel
                id="select-field-demo-label"
                htmlFor="select-field-demo-button"
              >
                Frequency
              </FormLabel>
              <Select
                defaultValue={digestSetting.frequency}
                onChange={(e, value) => {
                  setDigestSetting((prev) => ({
                    ...prev,
                    frequency: value as AlertFrequency,
                  }));
                }}
                sx={{
                  backgroundColor: "white",
                }}
              >
                {DIGEST_FREQUENCY_OPTIONS.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </FormControl>
            {digestSetting?.frequency === AlertFrequency.WEEKLY && (
              <FormControl>
                <FormLabel>Day Selection</FormLabel>
                <Select
                  sx={{
                    backgroundColor: "white",
                  }}
                  onChange={(_e, value) => {
                    setDigestSetting((prev) => ({
                      ...prev,
                      days: [value] as number[],
                    }));
                  }}
                  defaultValue={[digestSetting.days]}
                >
                  {WEEK_DAYS.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            )}
            <TimePicker
              style={{
                marginBottom: 10,
              }}
              title={"Time"}
              defaultValue={dayjs(digestSetting.time, format)}
              use12Hours
              format={format}
              onChange={(value) => {
                setDigestSetting((prev) => ({
                  ...prev,
                  time: value ? value.format(format) : "",
                }));
              }}
            />
          </div>

          <Divider />
          <SelectSection
            handleSelectCompany={handleSelectCompany}
            handleSelectTopic={handleSelectTopic}
            resetSelect={resetSelect}
            selectedCompanies={selectedCompanies}
            selectedTopics={selectedTopics}
          />
        </DialogContent>
        <DialogActions>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              gap: 16,
            }}
          >
            <Button
              onClick={onClose}
              variant="outlined"
              style={{
                width: "100%",
                height: 44,
                borderRadius: 30,
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                width: "100%",
                height: 44,
                borderRadius: 30,
              }}
              variant="solid"
              color="primary"
              disabled={disableBtn()}
              onClick={handleSubmit}
            >
              {value?.id ? "Update" : "Create"}
            </Button>
          </div>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default DigestAlertFormModal;
