import { Variants } from "framer-motion";

export const transition = { duration: 1.4, ease: [0.8, 0.01, -0.05, 0.9] };
export const letter: Variants = {
  initial: {
    y: 400,
  },
  animate: {
    y: 0,
    transition: { ...transition, duration: 0.8 },
  },
};

let easeing = [0.8, -0.05, 0.01, 0.99];
export const btnGroup = {
  initial: {
    y: -20,
    opacity: 0,
    transition: {
      duration: 2,
      ease: easeing,
    },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 2,
      ease: easeing,
    },
  },
};
export const fadeInUp = {
  initial: {
    y: -60,
    opacity: 0,
    transition: {
      duration: 1.5,
      ease: easeing,
    },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.5,
      delay: 0.5,
      ease: easeing,
    },
  },
};

export const fadeInDown = {
  initial: {
    y: 60,
    opacity: 0,
    transition: {
      duration: 1.5,
      ease: easeing,
    },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1.5,
      delay: 0.5,
      ease: easeing,
    },
  },
};
export const fadeInRight = {
  initial: {
    x: 60,
    opacity: 0,
    transition: {
      duration: 1.5,
      ease: easeing,
    },
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1.5,
      delay: 0.5,
      ease: easeing,
    },
  },
};
export const fadedText = {
  initial: {
    y: -20,
  },
  animate: {
    y: 0,
    transition: {
      delayChildren: 0.4,
      staggerChildren: 0.04,
      staggerDirection: 1,
    },
  },
};

export const fadeInLeft = {
  initial: {
    x: -60,
    opacity: 0,
    transition: {
      duration: 0.8,
      ease: easeing,
    },
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.8,
      delay: 0.5,
      ease: easeing,
    },
  },
};

export const container = {
  show: {
    transition: {
      staggerChildren: 0.3,
    },
  },
};

export const item = {
  hidden: { opacity: 0, y: 20 },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: "easeInOut",
      duration: 0.3,
    },
  },
};

export const hiddenFromLeft = {
  hidden: { x: -100, opacity: 0 },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};
export const hiddenFromRight = {
  hidden: { x: 100, opacity: 0 },
  show: {
    x: 0,
    opacity: 1,
    transition: {
      esase: "easeInOut",
      duration: 1,
    },
  },
};
export const hiddenFromTop = {
  hidden: { y: -100, opacity: 0 },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};
export const hiddenFromBottom = {
  hidden: { y: 100, opacity: 0 },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
};
