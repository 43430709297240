import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the desired Quill theme
import EditorToolbar, { modules } from "./EditToolBar";
import "./style.scss";
import { useEffect, useRef } from "react";

interface HtmlEditorProps {
  value: string;
  onChange?: (value: string) => void;
  idToolbar: string;
  placeholder: string;
  readOnly: boolean;
  handleFileUpload?: (event: any) => void;
}

export const HtmlEditor = ({
  value,
  onChange,
  idToolbar,
  placeholder,
  readOnly,
  handleFileUpload,
}: HtmlEditorProps) => {
  const editorRef = useRef<ReactQuill>(null);
  useEffect(() => {
    const quillEditorContext = editorRef.current;
    if (!quillEditorContext) return;
    const quill = quillEditorContext.getEditor();
    const imageResize = quill.getModule("imageResize") as any;

    // hide resize handler when scrolling
    const hideOverlay = () => {
      imageResize?.hide();
    };
    editorRef.current
      ?.getEditor()
      .root?.addEventListener("scroll", hideOverlay);
    return () => {
      editorRef.current
        ?.getEditor()
        .root?.removeEventListener("scroll", hideOverlay);
    };
  }, []);
  return (
    <div className={`rich-text ${readOnly ? "read-quill" : ""}`}>
      <EditorToolbar
        toolbarId={idToolbar}
        handleFileUpload={handleFileUpload}
      />
      <ReactQuill
        ref={editorRef}
        theme="snow"
        value={value}
        onChange={onChange}
        modules={modules(idToolbar, readOnly)}
        placeholder={placeholder}
        readOnly={readOnly}
      />
    </div>
  );
};
