import Banner from "pages/landing-page/components/Banner";
import Card from "pages/landing-page/components/Card";

import styles from "./AboutBondVexity.module.scss";

const BANNER = {
  title: `About Bondvexity`,
  description:
    "Bondvexity is redefining corporate credit research with a commitment to transparency, speed, and unbiased insights. Founded by seasoned buy-side professionals, our platform addresses the gaps in traditional research, providing real-time data, expert analysis, and strategic recommendations to empower institutional investors and credit professionals.",
};
const CARDS = [
  {
    title: "Our Mission",
    description:
      "To deliver clear, actionable credit insights without the noise of sell-side agendas or outdated systems. We believe in empowering our clients with the tools and data they need to make informed investment decisions.",
  },
  {
    title: "Our Approach",
    description:
      "Combining advanced technology with deep market expertise, Bondvexity provides a user-friendly platform designed for speed, accuracy, and reliability. Our research is independent and entirely focused on delivering value to our subscribers.",
  },
  {
    title: "Our Commitment",
    description:
      "We are dedicated to maintaining the highest standards of integrity, independence, and excellence in everything we do. At Bondvexity, our only agenda is to serve the needs of our clients with unbiased, high-quality research.",
  },
];

const AboutBondVexity = () => {
  return (
    <div className={styles["about-bondvexity"]}>
      <Banner headline={BANNER.title} description={BANNER.description} />
      <div className={styles["about-bondvexity__grid"]}>
        {CARDS.map(({ title, description }, index) => (
          <Card
            title={title}
            description={description}
            key={index}
            cardStyles={{
              borderRadius: 20,
              backgroundColor: index % 2 === 0 ? "#EBF6FD" : "#00BFA514",
              padding: 30,
              maxWidth: 380,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default AboutBondVexity;
