import { Switch } from "@mui/joy";
import { Card, Col, Divider, Row, Tooltip } from "antd";
import alertSettingsApi from "api/alert-settings";
import Button from "components/UI/Button/Button";
import ConfirmModal from "components/UI/Modal/ConfirmModal";
import { NotificationSettingAction } from "constants/notifications";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Path } from "routers/route";
import { Alert, Selected } from "types/alert-settings";
import { DigestSettings } from "types/notification";
import { renderFrequency } from "utils/helpers";
import styles from "../EmailNotification.module.scss";
import DigestAlertFormModal from "./DigestAlertFormModal";

const Digest = () => {
  const navigate = useNavigate();
  const [alerts, setAlerts] = useState<Alert[]>([]);

  const { action = null } = useParams<{ action?: NotificationSettingAction }>();

  const [query] = useSearchParams();

  const getDigests = useCallback(async () => {
    const dataRes: Alert[] = await alertSettingsApi.get(false);
    setAlerts(dataRes ?? []);
  }, []);

  useEffect(() => {
    getDigests();
  }, [getDigests]);

  const handleSubmit = async (
    companies: Selected[],
    topics: Selected[],
    setting: DigestSettings,
    id?: string
  ) => {
    if (id) {
      return onAlertUpdate({
        ...(alerts.find((alert) => alert.id === id) as Alert),
        companies,
        topics,
        frequency: setting.frequency,
        weekDays: setting.days,
        deliveryTime: setting.time,
        description: setting.title,
      });
    }
    try {
      await alertSettingsApi.create({
        companies: companies.map((c) => c.id),
        topics: topics.map((t) => t.id),
        frequency: setting.frequency,
        weekDays: setting.days,
        deliveryTime: setting.time,
        description: setting.title,
        isActive: true,
      });
      getDigests();
      onClose();
      toast.success("Digest created");
    } catch (error) {
      toast.error("Failed to create digest");
    }
  };

  const onAlertUpdate = async (alert: Alert) => {
    try {
      await alertSettingsApi.update(
        {
          ...alert,
          companies: alert.companies.map((c) => c.id),
          topics: alert.topics.map((t) => t.id),
        },
        alert.id
      );
      toast.success("Digest updated");
      getDigests();
      onClose();
    } catch (error) {
      toast.error("Failed to update digest");
    }
  };

  const handleDeleteAlert = async () => {
    try {
      await alertSettingsApi.delete(query.get("id")!);
      toast.success("Digest deleted");
      getDigests();
      onClose();
    } catch (error) {
      toast.error("Failed to delete digest");
    }
  };

  const onClose = () => {
    navigate(`${Path.Settings}`);
  };

  return (
    <Row gutter={[24, 24]} className={styles["setting-item"]}>
      <Col className={styles["setting-description"]} md={24} lg={6}>
        <h5>Your Digests</h5>
        <span className={styles["subtitle"]}>
          We will combine all matching new articles into a single email and send
          it to you at the time you specify.
        </span>
      </Col>
      <Col sm={24} xs={24} md={24} lg={18}>
        <Card type="inner" title="DIGESTS">
          <Card.Grid
            style={{
              width: "100%",
            }}
            hoverable={false}
          >
            {alerts.length
              ? alerts.map((alert, idx) => (
                  <div key={alert.id}>
                    <div className={styles["notification-item"]}>
                      <div>
                        <Card.Meta title={alert.description}></Card.Meta>
                        <Card.Meta title={renderFrequency(alert)}></Card.Meta>
                        <Card.Meta
                          title="Companies"
                          description={
                            alert.companies.length
                              ? alert.companies.map((c) => c.name).join(", ")
                              : "No companies"
                          }
                        />
                        <Card.Meta
                          className={styles["notification-item-title"]}
                          style={{
                            marginTop: 5,
                          }}
                          title="Topics"
                          description={
                            alert.topics?.length
                              ? alert.topics.map((c) => c.name).join(", ")
                              : "No topics"
                          }
                        />
                      </div>
                      <Tooltip title="Turn on/off this notification">
                        <Switch
                          checked={alert.isActive}
                          onChange={(e) => {
                            onAlertUpdate({
                              ...alert,
                              isActive: e.target.checked,
                            });
                          }}
                        />
                      </Tooltip>
                    </div>
                    <div className={styles["notification-item-buttons"]}>
                      <Button
                        sx={{
                          borderRadius: "8px",
                        }}
                        variant="outlined"
                        onClick={() =>
                          navigate(
                            `${Path.Settings}/${NotificationSettingAction.EDIT_DIGEST_NOTIFICATION}?id=${alert.id}`
                          )
                        }
                      >
                        Edit
                      </Button>
                      <Button
                        sx={{
                          borderRadius: "8px",
                        }}
                        variant="outlined"
                        onClick={() =>
                          navigate(
                            `${Path.Settings}/${NotificationSettingAction.REMOVE_DIGEST_NOTIFICATION}?id=${alert.id}`
                          )
                        }
                      >
                        Delete
                      </Button>
                    </div>
                    {idx < alerts.length - 1 && <Divider />}
                  </div>
                ))
              : "No digest found"}
          </Card.Grid>
        </Card>
        <Button
          sx={{
            maxWidth: "200px",
            marginTop: "20px",
          }}
          onClick={() => {
            navigate(
              `${Path.Settings}/${NotificationSettingAction.ADD_DIGEST_NOTIFICATION}`
            );
          }}
        >
          Create new digest
        </Button>
      </Col>

      {action === NotificationSettingAction.ADD_DIGEST_NOTIFICATION && (
        <DigestAlertFormModal
          open={true}
          onClose={() => {
            navigate(`${Path.Settings}`);
          }}
          onSubmit={handleSubmit}
        />
      )}
      {action === NotificationSettingAction.EDIT_DIGEST_NOTIFICATION && (
        <DigestAlertFormModal
          value={alerts.find((alert) => alert.id === query.get("id"))}
          open={true}
          onClose={onClose}
          onSubmit={handleSubmit}
        />
      )}
      {action === NotificationSettingAction.REMOVE_DIGEST_NOTIFICATION && (
        <ConfirmModal
          open={true}
          onClose={onClose}
          onConfirm={handleDeleteAlert}
          variant="danger"
          message="Are you sure you want to proceed?"
        />
      )}
    </Row>
  );
};

export default Digest;
