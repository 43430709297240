import { AlertSettings } from "types/alert-settings";
import axiosApiInstance from "../utils/axiosClient";

const alertSettingsApi = {
  get: async (isAlert = true) => {
    const res = await axiosApiInstance.get("/alert-settings", {
      params: {
        isAlert,
      },
    });
    return res.data;
  },
  create: async (data: AlertSettings) => {
    const res = await axiosApiInstance.post("/alert-settings", data);
    return res.data;
  },
  update: async (data: AlertSettings, id: string) => {
    const res = await axiosApiInstance.put(`/alert-settings/${id}`, data);
    return res.data;
  },
  delete: async (id: string) => {
    await axiosApiInstance.delete(`/alert-settings/${id}`);
  },
  details: async (id: string) => {
    const res = await axiosApiInstance.get(`/alert-settings/${id}`);
    return res.data;
  },
};

export default alertSettingsApi;
