import { Chip, ChipDelete, Tab, tabClasses, TabList, Tabs } from "@mui/joy";
import { Row } from "antd";
import { CompanyDetail } from "pages/dashboard/components/CompanyTable";
import { FC, useState } from "react";
import { Sector } from "types/company";
import TopicTab from "./TopicTab";
import CompanyTab from "./CompanyTab";
import { Selected } from "types/alert-settings";

const TABS = [
  {
    title: "Company",
    value: "company",
  },
  {
    title: "Topic",
    value: "topic",
  },
];

type Props = {
  selectedCompanies: Selected[];
  selectedTopics: Selected[];
  handleSelectTopic: (topic: Selected) => void;
  handleSelectCompany: (company: Selected) => void;
  resetSelect: () => void;
};
const SelectSection: FC<Props> = ({
  selectedCompanies,
  selectedTopics,
  handleSelectCompany,
  handleSelectTopic,
  resetSelect,
}) => {
  return (
    <div>
      <p
        style={{
          marginBottom: 12,
          marginTop: 12,
          color: "#162f3f",
        }}
      >
        Company/Topic Selection
      </p>

      {!!selectedCompanies.length && (
        <Row
          style={{
            display: "flex",
            gap: 8,
            padding: 8,
          }}
        >
          Companies:
          {selectedCompanies.map((company) => (
            <Chip
              variant="outlined"
              endDecorator={
                <ChipDelete onDelete={() => handleSelectCompany(company)} />
              }
            >
              {company.name}
            </Chip>
          ))}
        </Row>
      )}
      {!!selectedTopics.length && (
        <Row
          style={{
            display: "flex",
            gap: 8,
            padding: 8,
          }}
        >
          Topics:
          {selectedTopics.map((topic) => (
            <Chip
              variant="outlined"
              endDecorator={
                <ChipDelete onDelete={() => handleSelectTopic(topic)} />
              }
            >
              {topic.name}
            </Chip>
          ))}
        </Row>
      )}
      <Tabs
        defaultValue={"company"}
        onChange={resetSelect}
        sx={{
          backgroundColor: "white",
        }}
      >
        <TabList
          style={{
            overflow: "auto",
            marginBottom: 8,
          }}
          sx={{
            [`& .${tabClasses.root}`]: {
              fontSize: "sm",
              [`&[aria-selected="true"]`]: {
                color: "#0091EA",
                bgcolor: "transparent",
                fontWeight: "700",
              },
              [`&.${tabClasses.focusVisible}`]: {
                outlineOffset: "-4px",
              },
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value}>
              {tab.title}
            </Tab>
          ))}
        </TabList>
        <Row>
          <CompanyTab
            selectedCompanies={selectedCompanies}
            handleSelectCompany={handleSelectCompany}
          />
          <TopicTab
            selectedTopics={selectedTopics}
            handleSelectTopic={handleSelectTopic}
          />
        </Row>
      </Tabs>
    </div>
  );
};

export default SelectSection;
