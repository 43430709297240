export enum AlertType {
  COMPANY = "company",
  TOPIC = "topic",
}

export enum AlertFrequency {
  REALTIME = "realtime",
  DAILY = "daily",
  WEEKLY = "weekly",
}

export type AlertSettings = {
  frequency: AlertFrequency;

  deliveryTime?: string;

  weekDays?: number[];

  companies: string[];

  topics: string[];

  isActive: boolean;

  description?: string;
};

export type Selected = {
  id: string;
  name: string;
};

export type Alert = {
  id: string;
  description?: string;
  companies: Selected[];
  topics: Selected[];
  isActive: boolean;
  frequency: AlertFrequency;
  deliveryTime?: string;
  weekDays?: number[];
};
