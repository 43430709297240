import { Input } from "@mui/joy";
import { Popover } from "antd";
import searchGlobalApi from "api/search-global";
import { ReactComponent as SearchIcon } from "assets/images/icon_search.svg";
import { orderBy } from "lodash";
import { useEffect, useRef, useState } from "react";
import "./style.scss";
import { Path } from "routers/route";
import { useNavigate } from "react-router-dom";

interface SearchResultItem {
  id: string;
  name: string;
  summary: string;
  type: "Company" | "Article";
  createdAt: string;
}

export const SearchGlobal = () => {
  const navigate = useNavigate();

  const [search, setSearch] = useState("");
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputWidth, setInputWidth] = useState(0);
  // const [page, setPage] = useState(1);
  const [searchResults, setSearchResults] = useState<SearchResultItem[]>([]);

  useEffect(() => {
    if (inputRef.current) {
      const width = inputRef.current.offsetWidth; // Get the current width of the input
      setInputWidth(width); // Set the input width state
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPopover, inputRef.current]); // Update the width when the popover opens

  const getSearchGlobal = async (search: string, page: number) => {
    const res: {
      feeds: SearchResultItem[];
      companies: SearchResultItem[];
    } = await searchGlobalApi.getList({
      page: page,
      limit: 50,
      search,
    });
    let newSearchResults: SearchResultItem[] = [];
    if (res?.feeds.length) {
      newSearchResults = newSearchResults.concat(
        res.feeds.map((feed) => ({
          id: feed.id,
          name: feed.name,
          summary: feed.summary,
          type: "Article",
          createdAt: feed.createdAt,
        }))
      );
    }
    if (res?.companies.length) {
      newSearchResults = newSearchResults.concat(
        res.companies.map((company) => {
          return {
            id: company.id,
            name: company.name,
            summary: "",
            type: "Company",
            createdAt: company.createdAt,
          };
        })
      );
    }
    setSearchResults(orderBy(newSearchResults, "createdAt", "desc"));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearch(newValue); // Update the search state with the new input value
    setOpenPopover(newValue ? true : false);
    // setPage(1);
    if (newValue) {
      getSearchGlobal(newValue, 1);
    }
  };

  const handleRedirectPage = (result: SearchResultItem) => () => {
    setOpenPopover(false);
    setSearch(""); // Clear the search input
    if (result.type === "Company") {
      navigate("/company/" + result.id);
      return;
    }
    navigate(Path.ResearchView.replace(":researchId", result.id));
  };

  const handleClearSearch = () => {
    setSearch("");
    setOpenPopover(false);
    // setPage(1);
  };

  return (
    <Popover
      arrow={false}
      open={!searchResults.length ? false : openPopover}
      placement="bottom"
      rootClassName="search-global-popover"
      onOpenChange={(visible) => search && setOpenPopover(visible)}
      content={
        <div
          className="popover"
          style={{
            overflowY: "auto",
            minHeight: searchResults.length ? "auto" : "auto",
            maxHeight: 300,
            width: inputWidth - 24, // Set the width dynamically
          }}
        >
          {searchResults.length ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {searchResults.map((result) => {
                return (
                  <div
                    key={result.id}
                    className="item"
                    onClick={handleRedirectPage(result)}
                  >
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 500,
                        lineHeight: "18px",
                        color: "#162F3F",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {result.name}
                      {result.summary && (
                        <span
                          style={{
                            color: "#667085",
                            fontWeight: 400,
                            fontSize: 12,
                            fontStyle: "italic",
                            marginLeft: 5,
                          }}
                        >
                          {result.summary}
                        </span>
                      )}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 5,
                      }}
                    >
                      <div
                        style={{
                          padding: "3px 0px",
                          borderRadius: 5,
                          width: 85,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor:
                            result.type === "Article"
                              ? "rgba(0, 145, 234, 0.08)"
                              : "rgba(0, 191, 165, 0.08)",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 12,
                            fontWeight: 700,
                            lineHeight: "18px",
                            color:
                              result.type === "Article" ? "#0091EA" : "#00BFA5",
                          }}
                        >
                          {result.type}
                        </p>
                        <svg
                          style={{
                            width: 16,
                            height: 16,
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <mask
                            id="mask0_1714_6392"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                          >
                            <rect
                              width="24"
                              height="24"
                              fill={
                                result.type === "Article"
                                  ? "#0091EA"
                                  : "#00BFA5"
                              }
                            />
                          </mask>
                          <g mask="url(#mask0_1714_6392)">
                            <path
                              d="M6.29425 17.6443L5.25 16.6L15.0905 6.75H6.14425V5.25H17.6443V16.75H16.1443V7.80375L6.29425 17.6443Z"
                              fill={
                                result.type === "Article"
                                  ? "#0091EA"
                                  : "#00BFA5"
                              }
                            />
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  padding: "3px 0px",
                  borderRadius: 5,
                  width: 85,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#F9F9F9",
                }}
              >
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 700,
                    lineHeight: "18px",
                    color: "#162F3F",
                  }}
                >
                  No data
                </p>
              </div>
            </div>
          )}
        </div>
      }
      trigger={["click"]}
    >
      <Input
        value={search}
        ref={inputRef} // Attach the ref to the input
        placeholder="Search by keyword or phrase"
        startDecorator={<SearchIcon />}
        onChange={handleInputChange} // Add the onChange event handler
        sx={{
          borderRadius: 20,
          border: "none",
          width: "100%",
          height: "48px !important",
          paddingLeft: "12px !important",
          paddingRight: "12px !important",
        }}
        endDecorator={
          search ? (
            <svg
              onClick={handleClearSearch}
              style={{
                width: 24,
                cursor: "pointer",
              }}
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.8941 11.3943L8.5 8.00014M8.5 8.00014L5.10589 4.60603M8.5 8.00014L11.8941 4.60603M8.5 8.00014L5.10589 11.3943"
                stroke="#E53935"
                strokeWidth="1.5"
                strokeLinecap="round"
              />
            </svg>
          ) : undefined
        }
      />
    </Popover>
  );
};
