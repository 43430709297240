import Banner from "../components/Banner";
import Card from "../components/Card";
import advancedAnalyticsIcon from "assets/images/solution/advanced-analytics.svg";
import insightIcon from "assets/images/solution/insights.svg";
import tailoredIcon from "assets/images/solution/tailored.svg";
import clockIcon from "assets/images/solution/clock.svg";
import pricingDataImage from "assets/images/solution/pricing-data.png";
import marketStatsImage from "assets/images/solution/market-stats.png";
import topMoversImage from "assets/images/solution/top-movers.png";
import summaryFinancials from "assets/images/solution/summary-financials.png";
import styles from "./Solutions.module.scss";
import WhyChoose from "components/Pages/LandingPage/WhyChoose";
import KeyFeatures from "components/Pages/LandingPage/KeyFeatures";

const SESSIONS = [
  {
    infoCard: {
      image: advancedAnalyticsIcon,
      title: "Advanced Analytics",
      description:
        "Bondvexity offers a suite of solutions designed to simplify the complexities of high-yield bond and leveraged loan markets. Our platform provides real-time, unbiased insights delivered by experts with deep buy-side experience. Here’s how Bondvexity’s solutions can empower you:",
    },
    sideImage: pricingDataImage,
  },
  {
    infoCard: {
      image: insightIcon,
      title: "Real-Time Market Insights",
      description:
        "Stay ahead of the market with up-to-the-minute data and instant updates. Bondvexity doesn’t wait for quarterly reports—we update our research in real-time as market conditions change, ensuring you have the latest insights when making critical decisions.",
    },
    sideImage: marketStatsImage,
  },
  {
    infoCard: {
      image: tailoredIcon,
      title: "Tailored Recommendations",
      description:
        "Receive targeted, actionable trade ideas and credit recommendations backed by rigorous analysis. Whether you’re seeking new opportunities or managing existing positions, our tailored research helps you make informed moves.",
    },
    sideImage: topMoversImage,
  },
  {
    infoCard: {
      image: clockIcon,
      title: "Simplicity and Speed",
      description:
        "Our platform is built for speed and ease-of-use. No clunky, outdated systems—Bondvexity’s modern interface ensures you get straight to the data that matters most, without the noise.",
    },
    sideImage: summaryFinancials,
  },
];

const Solutions = () => {
  const description =
    "Bondvexity offers a suite of solutions designed to simplify the complexities of high-yield bond and leveraged loan markets. Our platform provides real-time, unbiased insights delivered by experts with deep buy-side experience.\nHere’s how Bondvexity’s solutions can empower you:";
  const headline =
    "Innovative Credit Research Solutions Tailored to Your Needs";
  return (
    <div className={styles["solutions"]}>
      <Banner description={description} headline={headline} />
      <div className={styles["body-container"]}>
        {SESSIONS.map((session, index) => (
          <div className={styles["session-container"]}>
            <Card
              cardStyles={{
                maxWidth: 533,
              }}
              imageStyles={{
                borderRadius: 10,
                background: "#F9F9F9",
                padding: 4,
              }}
              key={index}
              image={session.infoCard.image}
              title={session.infoCard.title}
              description={session.infoCard.description}
            />
            <div
              className={styles["side-image"]}
              style={{
                paddingRight: index === 0 ? 0 : 40,
                backgroundColor:
                  index % 2 === 0 ? "rgba(0, 145, 234, 0.08)" : "#00BFA514",
              }}
            >
              <img src={session.sideImage} alt="side-image-solution" />
            </div>
          </div>
        ))}
      </div>
      <div className={styles["why-choose-container"]}>
        <WhyChoose />
        <KeyFeatures />
      </div>
    </div>
  );
};

export default Solutions;
