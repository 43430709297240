export const MENU_SELECT_DATE_RANGE = [
  {
    value: "Within last 24 hours",
    id: "last24Hours",
  },
  {
    value: "Within last week",
    id: "last7Days",
  },
  {
    value: "Within last month",
    id: "lastMonth",
  },
  {
    value: "Within last 90 days",
    id: "last90Days",
  },
];
