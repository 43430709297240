import { Col } from "antd";
import { LayoutContent } from "components/Layout/LayoutContent";
import { PermissionKey, useCheckPermission } from "hooks/useCheckPermission";
import Feeds from "pages/dashboard/components/Feeds";
import { checkDevice } from "utils/helpers";

const WeeklyRelativeValue = () => {
  const editFeedPermission = useCheckPermission(
    PermissionKey.EDIT_FEED_DASHBOARD
  );
  const { isDesktop } = checkDevice();

  const keyColumns = ["name", "company", "sector", "user", "createdAt"].concat(
    editFeedPermission && isDesktop ? ["action"] : []
  );

  return (
    <LayoutContent
      title="WEEKLY RELATIVE VALUE"
      header={<div />}
      styleChildrenBody={{
        paddingRight: 0,
        paddingTop: 0,
      }}
      styleContainer={{
        height: "100%",
        // paddingTop: 20,
      }}
      styleTitle={
        isDesktop
          ? undefined
          : {
              height: 40,
              display: "flex",
              alignItems: "center",
            }
      }
      styleBody={{
        height: "100%",
      }}
    >
      <Col>
        <Feeds isWeeklyRV keyColumns={keyColumns} />
      </Col>
    </LayoutContent>
  );
};

export default WeeklyRelativeValue;
