import { FC } from "react";
import dot_active from "../../../assets/images/dot_active.svg";
import dot_unactive from "../../../assets/images/dot_unactive.svg";

type Props = {
  onClick: () => void;
  active: boolean;
};
const CustomDot: FC<Props> = ({ onClick, active }) => {
  return (
    <div>
      <li className={active ? "active" : "inactive"} onClick={() => onClick()}>
        {active ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
          >
            <circle cx="6.5" cy="6" r="6" fill="#5D8BA8" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
          >
            <circle cx="6.5" cy="6" r="6" fill="#5D8BA8" fill-opacity="0.4" />
          </svg>
        )}
        {/* <img src={active ? dot_active : dot_unactive} alt="" /> */}
      </li>
    </div>
  );
};

export default CustomDot;
