import { AlertFrequency } from "types/alert-settings";
import { DigestSettings } from "types/notification";

export enum NotificationSettingAction {
  ADD_REALTIME_NOTIFICATION = "add-realtime-notification",
  EDIT_REALTIME_NOTIFICATION = "edit-realtime-notification",
  REMOVE_REALTIME_NOTIFICATION = "remove-realtime-notification",
  ADD_DIGEST_NOTIFICATION = "add-digest-notification",
  EDIT_DIGEST_NOTIFICATION = "edit-digest-notification",
  REMOVE_DIGEST_NOTIFICATION = "remove-digest-notification",
}

export const DIGEST_FREQUENCY_OPTIONS = [
  { label: "Daily", value: AlertFrequency.DAILY },
  { label: "Weekly", value: AlertFrequency.WEEKLY },
];

export const WEEK_DAYS = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];

export const TIMEZONES = [
  { label: "(UTC-12:00) International Date Line West", value: "Etc/GMT+12" },
  { label: "(UTC-11:00) Coordinated Universal Time-11", value: "Etc/GMT+11" },
  { label: "(UTC-10:00) Hawaii", value: "Pacific/Honolulu" },
  { label: "(UTC-09:00) Alaska", value: "America/Anchorage" },
  {
    label: "(UTC-08:00) Pacific Time (US & Canada)",
    value: "America/Los_Angeles",
  },
  { label: "(UTC-07:00) Mountain Time (US & Canada)", value: "America/Denver" },
  { label: "(UTC-06:00) Central Time (US & Canada)", value: "America/Chicago" },
  {
    label: "(UTC-05:00) Eastern Time (US & Canada)",
    value: "America/New_York",
  },
  { label: "(UTC-04:00) Atlantic Time (Canada)", value: "America/Halifax" },
  {
    label: "(UTC-03:00) Buenos Aires, Georgetown",
    value: "America/Argentina/Buenos_Aires",
  },
  { label: "(UTC-02:00) Coordinated Universal Time-02", value: "Etc/GMT+2" },
  { label: "(UTC-01:00) Azores", value: "Atlantic/Azores" },
  {
    label: "(UTC+00:00) Greenwich Mean Time : Dublin, Edinburgh, London",
    value: "Europe/London",
  },
  { label: "(UTC+01:00) Central European Time (CET)", value: "Europe/Paris" },
  { label: "(UTC+02:00) Eastern European Time (EET)", value: "Europe/Athens" },
  { label: "(UTC+03:00) Moscow, St. Petersburg", value: "Europe/Moscow" },
  { label: "(UTC+04:00) Dubai, Abu Dhabi", value: "Asia/Dubai" },
  { label: "(UTC+05:00) Pakistan Standard Time", value: "Asia/Karachi" },
  { label: "(UTC+05:30) India Standard Time", value: "Asia/Kolkata" },
  { label: "(UTC+06:00) Bangladesh Standard Time", value: "Asia/Dhaka" },
  { label: "(UTC+07:00) Bangkok, Hanoi, Jakarta", value: "Asia/Bangkok" },
  {
    label: "(UTC+08:00) China Standard Time, Singapore Time",
    value: "Asia/Singapore",
  },
  { label: "(UTC+09:00) Japan Standard Time", value: "Asia/Tokyo" },
  { label: "(UTC+09:30) Australian Central Time", value: "Australia/Adelaide" },
  { label: "(UTC+10:00) Australian Eastern Time", value: "Australia/Sydney" },
  {
    label: "(UTC+11:00) Solomon Is., New Caledonia",
    value: "Pacific/Guadalcanal",
  },
  { label: "(UTC+12:00) Fiji, Kamchatka, Marshall Is.", value: "Pacific/Fiji" },
  { label: "(UTC+13:00) Nuku'alofa", value: "Pacific/Tongatapu" },
];

export const defaultDigestSettings: DigestSettings = {
  title: "",
  frequency: AlertFrequency.DAILY,
  time: "00:00",
  days: [],
};
