import { useEffect, useState } from "react";
import { httpRequestErrors } from "../../../utils/errors";
import feedApi from "../../../api/feed";
import fileApi from "../../../api/file";
import { toast } from "react-toastify";
import { FeedBlockType } from "../../../constants/feed";
import configApi from "api/config";
import { useNavigate, useParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import { isAllowedFileType, isValidFormatId } from "utils/helpers";
import { PermissionKey, useCheckPermission } from "hooks/useCheckPermission";
import { useSelector } from "react-redux";
import { RootState } from "libs/store";
interface Props {
  control: any;
  type?: "create" | "edit";
  researchId?: string;
}
const useResearch = ({ type = "create", researchId }: Props) => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const editFeedPermission = useCheckPermission(
    PermissionKey.EDIT_FEED_DASHBOARD
  );
  const isShowCompany = isValidFormatId(companyId || "");
  const showSelectCompany = editFeedPermission && !isShowCompany;

  const [sectorOptions, setSectorOptions] = useState<
    {
      id: string;
      name: string;
    }[]
  >([
    {
      id: "",
      name: "No Sector",
    },
  ]);

  const [companyOptions, setCompanyOptions] = useState<
    {
      id: string;
      name: string;
      sectorId?: string;
    }[]
  >([
    {
      id: "",
      name: "No Company",
    },
  ]);

  const [contentHtml, setContentHtml] = useState("");
  const [blockId, setBlockId] = useState<string>("");
  const sectors = useSelector((state: RootState) => state.user.sectors);

  useEffect(() => {
    if (sectors && sectors.length) {
      const newSectorOptions = [
        {
          id: "",
          name: "No Sector",
        },
      ];
      const newData = sectors || [];
      setSectorOptions(newSectorOptions.concat(newData));
    }
  }, [sectors]);

  useEffect(() => {
    if (!showSelectCompany || type === "edit") {
      return;
    }
    configApi.companies().then((data) => {
      const newCompanyOptions = [
        {
          id: "",
          name: "No Company",
        },
      ];
      const newData = data || [];

      setCompanyOptions(newCompanyOptions.concat(newData));
    });
  }, [showSelectCompany, type]);

  const goBack = () => {
    if (showSelectCompany) {
      navigate("/dashboard");
      return;
    }
    navigate(-1);
  };

  const onSubmitReseach = async (value: any) => {
    await onSaveResearch(value, false);
  };

  const onSaveResearch = async (value: any, isDraft: boolean) => {
    try {
      type === "edit" && researchId
        ? await feedApi.upload(
            researchId,
            {
              ...value,
              name: value.title,
              companyId: showSelectCompany ? value.companyId : companyId,
              blocks: [
                {
                  type: FeedBlockType.TEXT,
                  content: contentHtml,
                  order: 1,
                  id: blockId,
                },
              ],
            },
            value.feedType,
            isDraft
          )
        : await feedApi.create(
            {
              ...value,
              name: value.title,
              companyId: showSelectCompany ? value.companyId : companyId,
              blocks: [
                {
                  type: FeedBlockType.TEXT,
                  content: contentHtml,
                  order: 1,
                },
              ],
            },
            value.feedType,
            isDraft
          );

      toast.success("Research uploaded successfully");
      goBack();
    } catch (error) {
      console.log("error: ", error);
      httpRequestErrors(error);
    }
  };

  const handleFileUpload = async (event: any) => {
    if (!event.target.files) return;

    try {
      let newFileList = [...event.target.files];
      if (isAllowedFileType(newFileList[0].name)) {
        const formData = new FormData();
        formData.append("file", newFileList[0]);
        const data = await fileApi.coverFileToHtml(formData);
        const newContentHtml = cloneDeep(contentHtml) + data;
        setContentHtml(newContentHtml);
      }
    } catch (error) {
      httpRequestErrors("Error uploading file");
    }
  };

  return {
    sectorOptions,
    onSaveResearch,
    onSubmitReseach,
    goBack,
    handleFileUpload,
    contentHtml,
    setContentHtml,
    setBlockId,
    editFeedPermission,
    companyOptions,
    showSelectCompany,
    setCompanyOptions,
    companyId,
  };
};

export default useResearch;
