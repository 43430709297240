import { Drawer, Image } from "antd";
import { NavLink } from "react-router-dom";
import logoIconSmalll from "assets/images/logo_icon.svg";
import { ReactComponent as CloseIcon } from "assets/images/close-icon.svg";
import styles from "./MenuDrawer.module.scss";
import { itemsMenu } from "common";

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
}
const MenuDrawer = ({ open, setOpen }: Props) => {
  return (
    <Drawer
      placement="top"
      closable={false}
      onClose={() => setOpen(false)}
      open={open}
      className={styles["menu-drawer"]}
    >
      <div className={styles["header"]}>
        <Image
          className="logo-icon"
          src={logoIconSmalll}
          alt=""
          preview={false}
        />
        <div onClick={() => setOpen(false)} className={styles["close-btn"]}>
          <CloseIcon />
        </div>
      </div>
      <div className={styles["body"]}>
        {itemsMenu.map((item, index) => (
          <p
            key={index}
            className={styles["item-drawer"]}
            onClick={() => setOpen(false)}
          >
            <NavLink className="nav-link" to={`/${item.key}`}>
              <span>{item.label}</span>
            </NavLink>
          </p>
        ))}
      </div>
    </Drawer>
  );
};

export default MenuDrawer;
