import { Checkbox, TabPanel } from "@mui/joy";
import { Col, Row } from "antd";
import { RootState } from "libs/store";
import { useSelector } from "react-redux";
import { Sector } from "types/company";

interface Props {
  handleSelectTopic: (topic: Sector) => void;
  selectedTopics: Sector[];
}

const TopicTab = ({ handleSelectTopic, selectedTopics }: Props) => {
  const sectors = useSelector(
    (state: RootState) => state.user.sectors
  ) as Sector[];

  return (
    <TabPanel value="topic">
      <Row gutter={[24, 24]}>
        {sectors?.map((sector) => (
          <Col key={sector.id} xs={24} sm={24} md={12} lg={12}>
            <Row gutter={[12, 0]} justify={"space-between"} align={"middle"}>
              <Col lg={18}>
                <div>{sector.name}</div>
              </Col>
              <Col lg={6}>
                <Checkbox
                  onChange={() => {
                    handleSelectTopic(sector);
                  }}
                  checked={Boolean(
                    selectedTopics.find((t) => t.id === sector.id)
                  )}
                />
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    </TabPanel>
  );
};

export default TopicTab;
