import { useEffect, useState, useRef, useCallback } from "react";

export const useCountdownTime = (key) => {
  const countdownKey = `countdown_timer_${key}`;
  const countdownTimeLocal = localStorage.getItem(countdownKey);
  const [timer, setTimer] = useState(
    countdownTimeLocal ? Number(countdownTimeLocal) : 0
  );
  const timerRef = useRef(timer); // Use ref to track the current timer value
  const isTimerRunning = timer > 0;

  // Update timerRef whenever timer changes
  useEffect(() => {
    timerRef.current = timer;
  }, [timer]);

  useEffect(() => {
    if (isTimerRunning) {
      const interval = setInterval(() => {
        const newTimer = timerRef.current - 1;
        setTimer(newTimer);

        if (newTimer === 0) {
          clearInterval(interval);
          localStorage.removeItem(countdownKey);
          return;
        }
        localStorage.setItem(countdownKey, String(newTimer));
      }, 1000);

      return () => clearInterval(interval); // Cleanup the interval on component unmount
    }
  }, [isTimerRunning, countdownKey]); // Depend on isTimerRunning instead of timer

  const startTimer = useCallback(() => {
    const initialTime = 60;
    setTimer(initialTime);
    localStorage.setItem(countdownKey, String(initialTime));
  }, [countdownKey]);

  return {
    timer,
    isTimerRunning,
    startTimer,
  };
};
