import React from "react";
import AuthContent from "../components/AuthContent";
import SignInForm from "./components/SignInForm";

const SignIn = () => {
  return (
    <div className="auth-container">
      <div className="auth-left">
        <AuthContent
          title={
            "Welcome Back to Bondvexity Access Your Professional Credit Insights"
          }
          text={
            "Sign in to your Bondvexity account to continue leveraging our expert, unbiased research on high-yield and leveraged loan issuers. Stay informed with real-time data, actionable trade recommendations, and advanced analytics tools designed for professional enterprise customers."
          }
        />
      </div>
      <div className="auth-right">
        <SignInForm />
      </div>
    </div>
  );
};

export default SignIn;
