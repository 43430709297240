import { FormControl, FormHelperText, FormLabel } from "@mui/joy";
import { Col, Row } from "antd";
import FormInput from "components/UI/Form/FormInput";
import { RootState, store } from "libs/store";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { defaultRule, emailRule, phoneRule } from "utils/rules";
import Button from "components/UI/Button/Button";
import userApi from "api/user";
import { setUser } from "features/userSlice";
import { toast } from "react-toastify";
import { httpRequestErrors } from "utils/errors";
import InputPhone from "components/UI/Input/InputPhone";

const Profile = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const { control, handleSubmit, reset } = useForm({});
  // const { listRegions, listSectors } = useCompanyConfigs();
  useEffect(() => {
    if (user.id) {
      resetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);

  const onSubmit = async (data: any) => {
    try {
      const { regionIds, sectorId, companyName, ...userInfo } = data;
      const updatedUser = await userApi.update(userInfo);
      // if (!isSuperAdmin) {
      //   await companyApi.upload(user.company.id, {
      //     regionIds,
      //     sectorId,
      //     name: companyName,
      //   });
      // }
      store.dispatch(setUser(updatedUser));
      toast.success("Profile updated successfully");
    } catch (error) {
      httpRequestErrors(error);
    }
  };
  const resetData = () => {
    reset({
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      email: user.email,
      companyName: user.companyName,
      regionIds: user?.company?.regions.length ? user.company.regions : [],
      sectorId: user.company?.sectors.length ? user.company.sectors[0] : "",
    });
  };
  return (
    <div>
      <h5
        style={{
          marginBottom: 16,
        }}
      >
        Profile
      </h5>
      <div className="form-content ">
        <form onSubmit={handleSubmit(onSubmit)} className="">
          <div className="form-content-item edit-profile-form">
            <Row gutter={[16, 16]}>
              <Col xs={24} lg={12}>
                <FormInput
                  control={control}
                  rules={defaultRule("First Name")}
                  name={"firstName"}
                  label={"First Name"}
                />
              </Col>
              <Col xs={24} lg={12}>
                <FormInput
                  control={control}
                  name={"lastName"}
                  label={"Last Name"}
                  rules={defaultRule("Last Name")}
                />
              </Col>
            </Row>
            {/* {!isSuperAdmin && (
              <Row>
                <Col span={24}>
                  <FormInput
                    control={control}
                    name="companyName"
                    label="Company Name"
                    disabled
                  />
                </Col>
              </Row>
            )} */}

            {/* {!isSuperAdmin && (
              <Row gutter={[16, 16]}>
                <Col span={24} lg={12}>
                  <FormSelect
                    control={control}
                    name="regionIds"
                    label="Region"
                    mutiple={true}
                    rules={defaultRule("Region")}
                    options={listRegions.map((region) => {
                      return {
                        value: region.id,
                        children: region.name,
                      };
                    })}
                  />
                </Col>
                <Col span={24} lg={12}>
                  <FormSelect
                    control={control}
                    name="sectorId"
                    label="Sector"
                    options={listSectors.map((sector) => {
                      return {
                        value: sector.id,
                        children: sector.name,
                      };
                    })}
                  />
                </Col>
              </Row>
            )} */}

            <Row>
              <Col span={24}>
                <FormInput
                  control={control}
                  name="email"
                  label="E-mail"
                  rules={emailRule}
                  disabled
                />
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Controller
                  control={control}
                  name="phone"
                  rules={phoneRule}
                  render={({ field, fieldState }) => (
                    <FormControl>
                      <FormLabel>Phone number</FormLabel>
                      <InputPhone
                        value={field.value}
                        onChange={(newValue) => field.onChange(newValue)}
                        containerClass="input-phone-bg-while"
                      />
                      {fieldState.error && (
                        <FormHelperText
                          sx={{
                            color: "red",
                          }}
                        >
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={24} md={12}>
                <Button
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    height: "44px",
                  }}
                  type="button"
                  variant="outlined"
                  onClick={resetData}
                >
                  Cancel
                </Button>
              </Col>
              <Col xs={24} md={12}>
                <Button
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    height: "44px",
                  }}
                  type="submit"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
