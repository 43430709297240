import AboutBondVexity from "./components/AboutBondVexity";
import OurCoreValue from "./components/CoreValue";
import CurrentOpportunities from "./components/CurrentOpportunities";
import TopBanner from "./components/TopBanner";
import JoinBondVexity from "./components/JoinBondVexity";
import WhyWorkAt from "./components/WhyWorkAt";
import LifeAtBondVexity from "./components/LifeAtBondVexity";

import styles from "./AboutUs.module.scss";

const AboutUs = () => {
  return (
    <div className={styles["about-us"]}>
      <TopBanner />
      <AboutBondVexity />
      <JoinBondVexity />
      <WhyWorkAt />
      <OurCoreValue />
      <CurrentOpportunities />
      <LifeAtBondVexity />
    </div>
  );
};

export default AboutUs;
