import "./style.scss";
import { useForm } from "react-hook-form";
import FormInput from "components/UI/Form/FormInput";
import FormSelect from "components/UI/Form/FormSelect";
import useResearch from "../hooks/useResearch";
import { LayoutContent } from "components/Layout/LayoutContent";
import { FormControl } from "@mui/joy";
import { HtmlEditor } from "components/Pages/Cms/UploadResearch/HtmlEditor";
import { defaultRule } from "utils/rules";
import { LIST_CONTENT_TYPE } from "constants/feed";
import { useEffect } from "react";
import { Row } from "antd";
import companyApi from "api/company";
import { CompanyDetail } from "pages/dashboard/components/CompanyTable";
import { useNavigate } from "react-router-dom";

const UploadResearch = () => {
  const { handleSubmit, control, reset, getValues } = useForm();
  const navigate = useNavigate();
  const {
    sectorOptions,
    onSubmitReseach,
    goBack,
    handleFileUpload,
    contentHtml,
    setContentHtml,
    companyOptions,
    showSelectCompany,
    companyId,
  } = useResearch({ control });

  useEffect(() => {
    if (!showSelectCompany) {
      if (companyId && companyId !== "dashboard") {
        getCompanyDetail(companyId);
        return;
      }
      reset({
        sectorId: "",
      });
      return;
    }
    reset({
      companyId: "",
      feedType: 2,
      sectorId: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSelectCompany, companyId]);

  const getCompanyDetail = async (originCompanyId: string) => {
    try {
      const dataRes: CompanyDetail = await companyApi.get(originCompanyId);
      if (dataRes?.id) {
        reset({
          sectorId: dataRes?.sectors?.length ? dataRes?.sectors[0]?.id : "",
        });
      }
    } catch (error) {
      navigate(-1);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitReseach)} className="upload-research">
      <LayoutContent
        styleHeader={{
          borderBottom: "1px solid transparent",
          marginBottom: "8px",
          paddingBottom: 0,
        }}
        title="UPLOAD RESEARCH"
        styleBody={{
          height: "100vh",
        }}
        styleTitle={{
          width: "100%",
        }}
        styleSubHeader={{
          width: "100%",
        }}
        styleChildrenBody={{
          paddingTop: 0,
        }}
        isSearch={false}
        header={
          <div className="upload-top">
            <div className="upload-btn">
              <button className="save-research" type="submit">
                Save Research
              </button>
              <button type="button" className="cancel" onClick={goBack}>
                Cancel
              </button>
            </div>
          </div>
        }
      >
        <div className="upload-container">
          <Row
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: showSelectCompany ? "1fr 2fr" : "1fr 1fr",
              gridGap: "16px",
            }}
          >
            <FormInput
              control={control}
              label="Article title"
              name="title"
              placeholder="Article title"
              styles={{
                width: "100%",
              }}
              rules={defaultRule("Article title")}
              styleFormHelperText={{
                position: "absolute",
                bottom: "-20px",
              }}
            />
            <FormInput
              control={control}
              label="Subtitle"
              name="summary"
              placeholder="Subtitle"
              styles={{
                width: "100%",
              }}
            />
          </Row>

          <Row
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: showSelectCompany
                ? "1fr 1fr 1fr"
                : "1fr 1fr",
              gridGap: "16px",
            }}
          >
            {showSelectCompany ? (
              <FormSelect
                control={control}
                name="companyId"
                label="Company"
                options={
                  companyOptions.map((elCompany) => {
                    return {
                      value: elCompany.id,
                      children: elCompany.name,
                    };
                  }) || []
                }
                onChangeSelect={(value) => {
                  if (value) {
                    const companyDetail = companyOptions.find(
                      (el) => el.id === value
                    );
                    if (companyDetail && companyDetail?.sectorId) {
                      reset({
                        ...getValues(),
                        sectorId: companyDetail?.sectorId,
                        companyId: value,
                      });
                    }
                  }
                }}
              />
            ) : null}
            <FormSelect
              control={control}
              name="feedType"
              label="Content Type"
              rules={defaultRule("Content Type")}
              options={
                LIST_CONTENT_TYPE?.map((elContentType) => {
                  return {
                    value: elContentType.value,
                    children: elContentType.name,
                  };
                }) || []
              }
              styleFormHelperText={{
                position: "absolute",
                bottom: "-20px",
              }}
            />
            <FormSelect
              control={control}
              name="sectorId"
              label="Sector"
              // rules={defaultRule("Sector")}
              options={
                sectorOptions?.map((elSector) => {
                  return {
                    value: elSector.id,
                    children: elSector.name,
                  };
                }) || []
              }
            />
          </Row>

          <FormControl
            style={{
              width: "100%",
            }}
          >
            <HtmlEditor
              value={contentHtml}
              onChange={(value) => setContentHtml(value)}
              idToolbar={"upload_research"}
              placeholder={""}
              readOnly={false}
              handleFileUpload={handleFileUpload}
            />
          </FormControl>
        </div>
      </LayoutContent>
    </form>
  );
};

export default UploadResearch;
