import {
  FormControl,
  FormHelperText,
  FormLabel,
  Option,
  OptionProps,
} from "@mui/joy";
import { Controller, ControllerProps } from "react-hook-form";
import Select, { SelectProps } from "@mui/joy/Select";
import React from "react";
import icon_select from "assets/images/icon_select.svg";

export interface Props
  extends Omit<
    ControllerProps & SelectProps<any, any, any>,
    "render" | "control"
  > {
  control: any;
  name: string;
  rules?: ControllerProps["rules"];
  options: OptionProps[];
  label: string;
  style?: React.CSSProperties;
  mutiple?: boolean;
  styleFormHelperText?: React.CSSProperties;
  onChangeSelect?: (value: any) => void;
}

const FormSelect = (props: Props) => {
  const {
    control,
    name,
    label,
    style,
    rules,
    options,
    onChangeSelect,
    styleFormHelperText,
    ...restProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <FormControl
            sx={{
              width: "100%",
              ...style,
            }}
          >
            <FormLabel>{label}</FormLabel>
            <Select
              {...restProps}
              {...field}
              multiple={props.mutiple}
              onChange={(
                _event:
                  | React.MouseEvent<Element, MouseEvent>
                  | React.KeyboardEvent<Element>
                  | React.FocusEvent<Element, Element>
                  | null,
                value: any
              ) => {
                field.onChange(value);
                onChangeSelect?.(value);
              }}
              indicator={<img src={icon_select} alt="" />}
              sx={{
                padding: "11px 16px !important",
                borderRadius: "8px",
                background: "var(--White, #FFF)",
                boxShadow: "none !important",
                "--Input-focusedHighlight": "white !important",
                height: "48px",
              }}
            >
              {options.map((option) => (
                <Option {...option}>{option.children}</Option>
              ))}
            </Select>
            {fieldState.error && (
              <FormHelperText
                sx={{
                  color: "red",
                  ...styleFormHelperText,
                }}
              >
                {fieldState.error.message}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
    />
  );
};
export default FormSelect;
