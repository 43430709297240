import WhyChoose from "components/Pages/LandingPage/WhyChoose";
import Banner from "../components/Banner";
import KeyFeatures from "components/Pages/LandingPage/KeyFeatures";
import recapsImage from "assets/images/insights/weekly-market.svg";
import commentaryImage from "assets/images/insights/expert.svg";
import ideasImage from "assets/images/insights/ideas.svg";
import searchImage from "assets/images/insights/search.svg";

import styles from "./Insights.module.scss";
import Card from "../components/Card";

const BANNER = {
  title: `In-Depth Market Analysis and 
    Expert Commentary`,
  description:
    "Stay on top of the market with Bondvexity’s expert insights, featuring timely analysis, thought leadership, and actionable recommendations tailored to today’s credit landscape.",
};

const BANNER_CARDS = [
  {
    title: "Weekly Market Recaps",
    description:
      "Our weekly updates distill the key trends and market movements in the high-yield and leveraged loan sectors, helping you stay informed without sifting through endless reports.",
    img: recapsImage,
  },
  {
    title: "Expert Commentary",
    description:
      "Dive into our in-depth articles, where Bondvexity’s seasoned analysts share their perspectives on market dynamics, issuer-specific risks, and emerging opportunities. Get insights that go beyond the headlines and provide context to complex market events.",
    img: commentaryImage,
  },
  {
    title: "Credit Trade Ideas",
    description:
      "Our insights include specific trade recommendations based on current market conditions and proprietary analysis. Whether it’s identifying undervalued bonds or highlighting potential risks, our research equips you with the tools to act decisively.",
    img: ideasImage,
  },
  {
    title: "Data-Driven Research",
    description:
      "Access live data, interactive charts, and comprehensive issuer profiles. Bondvexity’s insights blend qualitative analysis with quantitative rigor, ensuring you have a complete view of the market.",
    img: searchImage,
  },
];
const Insights = () => {
  return (
    <div className={styles["insights"]}>
      <Banner description={BANNER.description} headline={BANNER.title} />
      <div className={styles["insights-banner__cards"]}>
        {BANNER_CARDS.map(({ title, description, img }, index) => (
          <Card
            key={index}
            cardStyles={{
              backgroundColor: "#fff",
              borderRadius: 20,
              padding: 30,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              maxWidth: 300,
            }}
            description={description}
            title={title}
            image={img}
          />
        ))}
      </div>
      <div className={styles["insights-why-choose"]}>
        <WhyChoose />
      </div>
      <KeyFeatures />
    </div>
  );
};

export default Insights;
