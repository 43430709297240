import { InputProps, Input as MuiInput } from "@mui/joy";
export type Props = InputProps & {
  error?: boolean;
};
const Input = ({ error, ...restProps }: Props) => {
  return (
    <MuiInput
      sx={{
        padding: "11px 16px !important",
        borderRadius: "8px",
        background: "var(--White, #FFF)",
        boxShadow: "none !important",
        "--Input-focusedHighlight": "white !important",
        "&:focus-within": {
          outline: `1px solid ${error ? "#d32f2f" : "#0091EA"}`,
          background: error ? "#E5393508" : "none",
        },
        "&::before": {
          display: "none",
        },
      }}
      {...restProps}
    />
  );
};

export default Input;
