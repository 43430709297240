import { Menu } from "antd";
import avatar from "../../../../assets/images/default-avatar.png";
import { ReactComponent as UpdateIcon } from "../../../../assets/images/update_avatar.svg";
import "./style.scss";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { store } from "../../../../libs/store";
import { setIsLoading, setUser } from "../../../../features/userSlice";
import userApi from "api/user";
import fileApi from "api/file";

import { httpRequestErrors } from "utils/errors";
import { constructFullName } from "utils/helpers";
const MenuSettings = ({ tab, setTab, items }) => {
  const user = useSelector((state) => state.user.user);
  const handleUpdateAvatar = async (event) => {
    if (!event.target.files) return;
    const file = event.target.files[0];
    if (file.size > 1024 * 1024 * 2) {
      return toast.warning(
        "File size can't be more than 2MB.Please upload a smaller file"
      );
    }
    store.dispatch(setIsLoading(true));
    try {
      const avatarUrl = await fileApi.upload(file, user.id);
      const updatedUser = await userApi.update({ avatar: avatarUrl });
      store.dispatch(setUser(updatedUser));
    } catch (error) {
      httpRequestErrors(error);
    }
  };
  return (
    <div className="menu-settings">
      <div className="account-setting">
        <div className="avatar">
          <img src={user.avatar || avatar} alt="" />
          <input
            id="container-update-avatar"
            accept={"image/*"}
            type="file"
            style={{ display: "none" }}
            onChange={handleUpdateAvatar}
          />
          <label htmlFor="container-update-avatar">
            <div className="update-icon">
              <UpdateIcon style={{ width: 30, height: 30 }} />
            </div>
          </label>
        </div>

        <div className="account-name">
          <h5
            style={{
              marginBottom: 4,
            }}
          >
            {constructFullName(user.firstName, user.lastName)}
          </h5>
          <p
            style={{
              color: user?.userRole === 0 ? "#00bfa5" : "#0091EA",

              background:
                user?.userRole === 0
                  ? "rgba(0, 191, 165, 0.08)"
                  : "rgba(0, 145, 234, 0.08)",
            }}
          >
            {user.userRoleName}
          </p>
        </div>
      </div>
      <div className="menu-bottom">
        <Menu
          selectedKeys={[`${tab}`]}
          mode="inline"
          theme="light"
          items={items}
          onClick={({ key }) => {
            setTab(key);
          }}
        />
      </div>
    </div>
  );
};

export default MenuSettings;
