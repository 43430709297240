import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Card from "pages/landing-page/components/Card";

import styles from "./WhyWorkAt.module.scss";
import CustomArrow from "components/Pages/LandingPage/CustomArrow";

const WHY_WORKAT_ITEMS: {
  title: string;
  text: string;
}[] = [
  {
    title: "Innovative Environment",
    text: `Work with cutting-edge technology in a 
    collaborative setting that encourages creativity and forward thinking. 
    We challenge conventional approaches and value new ideas that push 
    boundaries.`,
  },
  {
    title: "Professional Growth",
    text: `Your growth is our priority. At Bondvexity, 
    you’ll have access to ongoing learning and development opportunities,
    mentorship from industry veterans, and the chance to shape your 
    career path within a fast-growing company.`,
  },
  {
    title: "Impactful Work",
    text: `Every day, your work will directly impact the 
    decisions of institutional investors, fund managers, and credit 
    professionals. Be part of a team that delivers essential insights and 
    drives real value for the financial community.`,
  },
  {
    title: "Diverse and Inclusive Culture",
    text: `We believe that diverse perspectives
    lead to better ideas and outcomes. Bondvexity is committed to 
    fostering an inclusive workplace where everyone’s voice is heard and 
    respected.`,
  },
  {
    title: "Flexible Working Environment",
    text: `We offer flexible working 
        arrangements, including remote and hybrid options, to ensure you 
        have the work-life balance you need to thrive.`,
  },
  {
    title: "Excellence",
    text: `We strive for excellence in everything we do, from our 
    research and technology to our client service.`,
  },
  {
    title: "Innovation",
    text: `We are constantly evolving, looking for new ways to 
    enhance our platform and provide greater value to our users.`,
  },
];
const WhyWorkAt = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };

  return (
    <div className={styles["container"]}>
      <div className={styles["why-work-at"]}>
        <h2>Why Work At BondVexity?</h2>
        <div className={styles["custom-carousel-container"]}>
          <Carousel
            arrows={false}
            partialVisible
            responsive={responsive}
            itemClass="custom-item-class"
            infinite={true}
            autoPlay={true}
            renderButtonGroupOutside={true}
            customButtonGroup={<CustomArrow />}
          >
            {WHY_WORKAT_ITEMS.map((item, index) => (
              <Card
                title={item.title}
                description={item.text}
                key={index}
                cardStyles={{
                  padding: 30,
                  maxWidth: "100%",
                  height: "100%",
                  margin: 10,
                  borderRadius: 20,
                  backgroundColor: index % 2 === 0 ? "#00bfa514" : "#0091ea14",
                }}
              />
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default WhyWorkAt;
