import { ReactComponent as IconHelpCircle } from "assets/images/help_circle.svg";
import { ReactComponent as IconLock } from "assets/images/icon_lock.svg";
import logo from "assets/images/icon_logo_auth.svg";

import { Col, Row } from "antd";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import authApi from "api/auth";
import { httpRequestErrors } from "utils/errors";
import FormInput from "components/UI/Form/FormInput";
import { FC } from "react";
import { passwordRule } from "utils/rules";

type Props = {
  isSetNewPassword?: boolean;
};
const ResetPasswordForm: FC<Props> = ({ isSetNewPassword }) => {
  const navigate = useNavigate();
  const [queries] = useSearchParams();
  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      password: "",
      confirm_password: "",
    },
  });
  const onSubmit = async ({
    password,
    confirm_password,
  }: {
    password: string;
    confirm_password: string;
  }) => {
    const token = queries.get("token");

    try {
      const res = await authApi.resetPassword({
        token,
        password,
        confirmedPassword: confirm_password,
        isSetNewPassword,
      });
      toast.success(res.message);
      navigate("/sign-in");
    } catch (error) {
      httpRequestErrors(error);
    }
  };
  const password = watch("password");
  return (
    <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-content">
        <img className="form-logo" src={logo} alt="" />

        <div className="form">
          <div>
            {/* <h4>Missed!</h4> */}
            <h2> {isSetNewPassword ? "Set new password" : "Reset password"}</h2>
          </div>
          <div className="form-item">
            <Row>
              <Col span={24}>
                <FormInput
                  control={control}
                  name="password"
                  rules={passwordRule}
                  type="password"
                  placeholder="************"
                  startDecorator={<IconHelpCircle />}
                  endDecorator={<IconLock />}
                  label={isSetNewPassword ? "New password" : "Password"}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormInput
                  control={control}
                  name="confirm_password"
                  rules={{
                    required: "Confirm password is required",
                    validate: (value: string) =>
                      value === password || "The passwords do not match",
                  }}
                  type="password"
                  placeholder="************"
                  startDecorator={<IconHelpCircle />}
                  endDecorator={<IconLock />}
                  label={"Confirm Password"}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="btn-container">
          <Row>
            <Col span={24}>
              <button className="btn-get-started">
                {isSetNewPassword ? "Set new password" : "Reset password"}
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </form>
  );
};

export default ResetPasswordForm;
