export type MenuItem = {
  label: string;
  key: string;
  subItems?: MenuItem[];
};

export const itemsMenu: MenuItem[] = [
  {
    label: "Solutions",
    key: "solutions",
  },
  {
    label: "Who We Serve",
    key: "who-we-serve",
  },
  {
    label: "About Us",
    key: "about-us",
    subItems: [
      {
        label: "Company",
        key: "company",
      },
      {
        label: "Career",
        key: "career",
      },
      {
        label: "Press",
        key: "press",
      },
    ],
  },
  {
    label: "Insights",
    key: "insights",
  },
];
export const itemsMenuFooter = [
  {
    label: "Solutions",
    key: "solutions",
  },
  {
    label: "Who We Serve",
    key: "who-we-serve",
  },
  {
    label: "About Us",
    key: "about-us",
  },
  {
    label: "Insights",
    key: "insights",
  },
];

export const subItemsMenuFooter = [
  {
    label: "Term & Use",
    key: "term-use",
  },
  {
    label: "Privacy Policy",
    key: "privacy-policy",
  },
  {
    label: "Cookie Policy",
    key: "cookie-policy",
  },
  {
    label: "Disclaimer",
    key: "disclaimer",
  },
];
