import { Button, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import AuthContent from "../components/AuthContent";
import logo from "assets/images/icon_logo_auth.svg";
import "../style.scss";

const SignUpSuccessPage = () => {
  const navigate = useNavigate();

  return (
    <div className="auth-container">
      <div className="auth-left">
        <AuthContent
          title={
            "Welcome Back to Bondvexity Access Your Professional Credit Insights"
          }
          text={
            "Sign in to your Bondvexity account to continue leveraging our expert, unbiased research on high-yield and leveraged loan issuers. Stay informed with real-time data, actionable trade recommendations, and advanced analytics tools designed for professional enterprise customers."
          }
        />
      </div>
      <div className="auth-right">
        <div className="auth-form">
          <div className="form-content">
            <img className="form-logo" src={logo} alt="" />

            <div className="form">
              <div>
                <h2>Thank You for Signing Up!</h2>
                <h4>
                  We’re thrilled to have you join our community of credit market
                  professionals. Our team is currently reviewing your account to
                  ensure a seamless experience tailored to your needs. <br></br>{" "}
                  A representative will be in touch shortly to confirm your
                  access and provide next steps. In the meantime, feel free to
                  reach out to us at{" "}
                  <a href="mailto:support@bondvexity.com">
                    support@bondvexity.com
                  </a>{" "}
                  if you have any questions or need assistance. <br></br> Thank
                  you for your patience—we’re committed to delivering the
                  high-quality, actionable research you expect.
                </h4>
              </div>
            </div>
            <div className="btn-container">
              <Row>
                <Col span={24}>
                  <Button
                    type="primary"
                    size="large"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    Back to Home
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpSuccessPage;
