import Banner from "pages/landing-page/components/Banner";
import Card from "pages/landing-page/components/Card";
import ourMissionImage from "assets/images/about-us/top-banner/our-mission.png";
import whyBondImage from "assets/images/about-us/top-banner/why-bond.png";

import styles from "./TopBanner.module.scss";

const BANNER = {
  title: `About Bondvexity: Pioneering a New 
      Era in Credit Research`,
  description:
    "Bondvexity was born from the need for clear, transparent, and actionable credit research in a market crowded with biased opinions and outdated platforms. Founded by buy-side professionals with decades of experience in credit markets, Bondvexity is dedicated to delivering real-time, data-driven insights that truly serve the needs of institutional investors.",
};
const TopBanner = () => {
  return (
    <div className={styles["top-banner"]}>
      <Banner description={BANNER.description} headline={BANNER.title} />
      <div className={styles["top-banner__content"]}>
        <Card
          image={ourMissionImage}
          cardStyles={{
            boxShadow: "0px 0px 223.4px -20px rgba(3, 145, 231, 0.09)",
            padding: 30,
            backgroundColor: "#fff",
            borderRadius: 20,
          }}
          imageStyles={{
            width: "100%",
            height: "auto",
            objectFit: "cover",
          }}
          title="Our Mission"
          description="We aim to revolutionize the way investors access credit research by removing the biases and noise that cloud traditional platforms. Our commitment to transparency means you get clear, unbiased insights that drive real value."
        />
        <div className={styles["top-banner__content-right"]}>
          <div className={styles["top-banner__content-right-top"]}>
            <Card
              cardStyles={{
                boxShadow: "0px 0px 223.4px -20px rgba(3, 145, 231, 0.09)",
                padding: 30,
                backgroundColor: "#fff",
                borderRadius: 20,
                maxWidth: "unset",
              }}
              title="Our Expertise"
              description="Our team consists of seasoned credit analysts and portfolio managers who bring a wealth of experience from leading investment firms. We don’t just report the news—we interpret it, providing you with the perspectives that matter most."
            />
            <Card
              cardStyles={{
                boxShadow: "0px 0px 223.4px -20px rgba(3, 145, 231, 0.09)",
                padding: 30,
                backgroundColor: "#fff",
                borderRadius: 20,
              }}
              title="Our Approach"
              description="Bondvexity combines cutting-edge technology with expert analysis to deliver a research platform that is fast, intuitive, and completely independent. We don’t push unnecessary products or services—our only agenda is to provide value to our subscribers."
            />
          </div>
          <Card
            cardStyles={{
              boxShadow: "0px 0px 223.4px -20px rgba(3, 145, 231, 0.09)",
              padding: 30,
              backgroundColor: "#fff",
              borderRadius: 20,
              display: "flex",
              gap: 30,
              maxWidth: "100%",
            }}
            image={whyBondImage}
            imageStyles={{
              marginBottom: "unset",
            }}
            title="Why BondVexity?"
            description="One price, no hidden fees. We believe in transparency, both in our research and our business model. We only increase prices as we add more value, ensuring you always get what you pay for."
          />
        </div>
      </div>
    </div>
  );
};

export default TopBanner;
