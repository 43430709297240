import React from "react";

const AuthContentMobileItem = ({ text }) => {
  return (
    <div className="auth-content-mobile-item">
      <p>{text}</p>
    </div>
  );
};

export default AuthContentMobileItem;
