import MuiButton, { ButtonProps } from "@mui/joy/Button";
import { FC } from "react";

type Props = ButtonProps & {
  height?: "medium" | "large";
};
const Button: FC<Props> = ({ style, height, sx, ...props }) => {
  return (
    <MuiButton
      sx={{
        fontWeight: 700,
        textTransform: "none",
        padding: "8px 25px",
        borderRadius: 20,
        boxShadow: "none",
        backgroundColor: props.variant === "outlined" ? "" : "#0091EA",
        border: "1px solid var(--Border-color, #D0D5DD)",
        "&:hover": {
          boxShadow: "none",
          backgroundColor: props.variant === "outlined" ? "" : "#43AEEF",
        },
        height: height === "medium" ? 36 : style?.height,
        ...style,
        ...sx,
      }}
      {...props}
    />
  );
};

export default Button;
