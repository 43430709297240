import Notfound from "pages/NotFound";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import { Path, routes } from "./route";
import ComingSoon from "pages/ComingSoon";
import ResearchViewPublic from "pages/cms/research/ResearchViewPublic";
import LandingPageLayout from "components/Layout/LandingPageLayout";
import Solutions from "pages/landing-page/solutions";
import WhoWeServe from "pages/landing-page/who-we-serve";
import AboutUs from "pages/landing-page/about-us";
import Insights from "pages/landing-page/insights";

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<LandingPageLayout />}>
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/who-we-serve" element={<WhoWeServe />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/insights" element={<Insights />} />
        </Route>

        {routes.map((path) =>
          path.isPrivate ? (
            <Route
              key={path.path}
              path={path.path}
              element={
                <PrivateRoute isRaw={path.isRaw}>
                  {path.component ? <path.component /> : <ComingSoon />}
                </PrivateRoute>
              }
            />
          ) : (
            <Route
              key={path.path}
              path={path.path}
              element={
                path.component ? (
                  <PublicRoute isRaw={path.isRaw}>
                    <path.component />
                  </PublicRoute>
                ) : null
              }
            />
          )
        )}
        <Route path={Path.ResearchPublic} element={<ResearchViewPublic />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
