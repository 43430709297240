import Banner from "pages/landing-page/components/Banner";
import Card from "pages/landing-page/components/Card";

import chartImage from "assets/images/about-us/chart.svg";
import fileImage from "assets/images/about-us/image.svg";
import settingImage from "assets/images/about-us/setting.svg";
import markettingImage from "assets/images/who-we-serve/marketing.svg";

import styles from "./CurrentOpportunity.module.scss";

const BANNER = {
  title: "Current Opportunities",
  description:
    "Explore our open positions below and find your place in the Bondvexity team. We’re looking for talented individuals in the following areas:",
};

const CARDS = [
  {
    title: "Credit Analyst",
    description:
      "Join our research team to provide in-depth analysis and unbiased recommendations on high-yield bonds and leveraged loans.",
    image: chartImage,
  },
  {
    title: "Data Scientists",
    description:
      "Work on developing advanced analytics tools and integrating real-time market data into our platform.",
    image: fileImage,
  },
  {
    title: "Software Engineers",
    description:
      "Help build and enhance our cutting-edge platform with intuitive design and user-friendly features.",
    image: settingImage,
  },
  {
    title: "Sales & Marketing",
    description:
      "Drive our growth by connecting with potential clients and showcasing the value of Bondvexity’s solutions.",
    image: markettingImage,
  },
];
const CurrentOpportunities = () => {
  return (
    <div className={styles["opportunities"]}>
      <div className={styles["opportunities-content"]}>
        <Banner headline={BANNER.title} description={BANNER.description} />
        <div className={styles["opportunities-card__content"]}>
          {CARDS.map(({ title, description, image }, index) => (
            <Card
              title={title}
              description={description}
              image={image}
              key={index}
              cardStyles={{
                borderRadius: 20,
                backgroundColor: "#fff",
                padding: 30,
              }}
            />
          ))}
        </div>

        <div className={styles["opportunities-content__bottom"]}>
          <h4>Ready to take the next step in your career?</h4>
          <p>
            Apply now by sending your resume and cover letter to{" "}
            <a href="#">careers@bondvexity.com.</a> If you don’t see a role that
            fits but still want to be part of our team, reach out—we’re always
            interested in connecting with passionate individuals.
          </p>
        </div>
      </div>
    </div>
  );
};

export default CurrentOpportunities;
