import { Col, Dropdown, Empty, Menu, TableProps, Row, Table } from "antd";
import BoxGrid from "components/UI/Box/BoxGrid";
import Tag from "./Tag";
import { checkDevice, formatDate } from "utils/helpers";
import React, { useEffect, useState } from "react";
import { httpRequestErrors } from "utils/errors";
import feedApi from "api/feed";
import { useNavigate } from "react-router-dom";
import { Path } from "routers/route";
import "./style.scss";
import { compact, includes, isEmpty, isUndefined } from "lodash";
import dayjs from "dayjs";
import { ReactComponent as ThreeDotIcon } from "assets/images/three-dots.svg";
import Button from "components/UI/Button/Button";
import ConfirmModal from "components/UI/Modal/ConfirmModal";
import { toast } from "react-toastify";
import useResearchFilter from "components/Pages/Cms/ResearchFilter/hooks/useResearchFilter";
import { ReactComponent as CalendarIcon } from "assets/images/calendar_icon.svg";
import ResearchView from "pages/cms/research/ResearchView";
import DateRangePicker from "components/UI/DateRangePicker/DateRangePicker";
import { PermissionKey, useCheckPermission } from "hooks/useCheckPermission";
import { LIST_CONTENT_TYPE } from "constants/feed";
import { SelectFilter } from "components/UI/SelectFilter";
interface Props {
  companyId?: string;
  isMyContent?: boolean;
  styleRoot?: React.CSSProperties;
  keyColumns: string[];
  isMarket?: boolean;
  isWeeklyRV?: boolean;
  sectorId?: string;
}
interface FeedDetail {
  id: string;
  name: string;
  summary: string;
  type: number;
  createdAt: string;
  isOwner: boolean;
}
const Feeds = ({
  isMyContent,
  styleRoot,
  keyColumns,
  companyId,
  isMarket,
  sectorId,
  isWeeklyRV,
}: Props) => {
  const { isMobile, isTablet, isDesktop } = checkDevice();
  const [feeds, setFeeds] = useState<any[]>([]);
  const navigate = useNavigate();
  const [openDeleteFeed, setOpenDeleteFeed] = useState(false);
  const [deleteFeedSelected, setDeleteFeedSelected] =
    useState<FeedDetail | null>(null);
  const [selectedFeed, setSelectedFeed] = useState<FeedDetail | null>(null);
  const { filter, sectorOptions, setFilter, total, setTotal, companyOptions } =
    useResearchFilter(keyColumns);

  const dashboardPermission = useCheckPermission([
    PermissionKey.EDIT_FEED_DASHBOARD,
    PermissionKey.DELETE_FEED_DASHBOARD,
  ]);
  const [isFilterMobile, setIsFilterMobile] = useState(true);

  useEffect(() => {
    if (isMobile || isTablet) {
      setIsFilterMobile(false);
    }
  }, [isMobile, isTablet]);

  useEffect(() => {
    getResearches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, sectorId]);

  const getContentTypes = () => {
    if (isMarket) {
      return [2];
    }
    if (isWeeklyRV) {
      return [9];
    }
    return filter.contentType;
  };

  const getResearches = async () => {
    try {
      const newDateRange = compact(filter.dateRange);
      let startTime = undefined;
      let endTime = undefined;

      if (newDateRange.length) {
        startTime = dayjs(newDateRange[0]).toISOString();
        endTime = dayjs(newDateRange[1]).endOf("day").toISOString();
      }
      const requestParams: any = {
        companyIds: companyId ? [companyId] : filter.companyIds,
        page: filter.page,
        limit: filter.limit,
        sectorIds: sectorId ? [sectorId] : filter.sectorIds,
        contentTypes: getContentTypes(),
        startTime,
        endTime,
        search: filter.search,
        orderBy: filter.orderBy,
        orderDirection: filter.orderDirection,
      };
      if (isMyContent) {
        requestParams.isMyContent = true;
      }
      const data = await feedApi.getList(requestParams);
      setFeeds(data?.items);
      setTotal(data?.total);
    } catch (error) {
      httpRequestErrors(error);
    }
  };

  const handleDeleteFeed = async () => {
    if (!deleteFeedSelected?.id) {
      return;
    }
    try {
      await feedApi.delete(deleteFeedSelected.id);
      if (filter.page === 1) {
        getResearches();
      } else {
        setFilter({
          ...filter,
          page: 1,
        });
      }
      setOpenDeleteFeed(false);
      toast.success("Delete research successfully");
    } catch (error) {
      setOpenDeleteFeed(false);
      httpRequestErrors(error);
    }
  };
  const handleTableChange = (pagination: any, _filters: any, sorter: any) => {
    console.log("pagination", pagination);
    if (!isEmpty(sorter)) {
      const newOrderBy = sorter.order
        ? Array.isArray(sorter.field)
          ? sorter.field[0]
          : sorter.field
        : "createdAt";

      // Determine the new order direction based on the sorter object
      const newOrderDirection = sorter.order === "ascend" ? "ASC" : "DESC";
      if (
        filter.orderBy !== newOrderBy ||
        filter.orderDirection !== newOrderDirection
      ) {
        setFilter({
          ...filter,
          page: 1,
          orderBy: newOrderBy,
          orderDirection: newOrderDirection,
        });
        return;
      }
      setFilter({
        ...filter,
        page: pagination?.pageSize === filter.limit ? pagination.current : 1,
        limit: pagination?.pageSize || 10,
      });

      return;
    }
    setFilter({
      ...filter,
      page: pagination?.pageSize === filter.limit ? pagination.current : 1,
      limit: pagination?.pageSize || 10,
    });
  };

  const handleChangeRow = (newFeed: FeedDetail) => {
    if (!isDesktop) {
      navigate(`/research-view/${newFeed.id}`);
      return;
    }
    setSelectedFeed(newFeed);
  };

  const handleRedirect = (companyId: string) => (event: any) => {
    if (!companyId) {
      return;
    }
    event.stopPropagation();
    navigate(`/company/${companyId}`);
  };

  const renderTableWidth = (currentWidth?: number) => {
    if (!isUndefined(currentWidth)) {
      return currentWidth;
    }
    if (!isDesktop) {
      return 150;
    }

    return selectedFeed?.id ? 110 : 170;
  };

  const columns: TableProps<FeedDetail>["columns"] = [
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      sorter: true,
      render: (_, record) => (
        <div
          className="research-name"
          style={{
            display: "flex",
            gap: 8,
            alignItems: "center",
            whiteSpace: "nowrap",
            // marginLeft: 8,
          }}
        >
          <p>{record.name}</p>
          <span
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {record.summary}
          </span>
        </div>
      ),
      width: !isDesktop ? 270 : undefined,
      // fixed: !isDesktop ? "left" : undefined,
    },
    {
      title: "Company",
      key: "company",
      sorter: true,
      render: (record: any) => {
        return (
          <p
            className="company-column"
            onClick={handleRedirect(record?.company?.id || "")}
          >
            {record?.company?.name || ""}
          </p>
        );
      },
      width: renderTableWidth(),
    },
    {
      title: "Sector",
      dataIndex: ["sector", "name"],
      key: "sector",
      render: (record) => (
        <p
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {record}
        </p>
      ),
      sorter: true,
      width: renderTableWidth(),
    },

    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (_, record) => <Tag type={record.type} />,
      width: renderTableWidth(170),
    },
    {
      title: "Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, record) => (
        <p
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {formatDate(record.createdAt)}
        </p>
      ),
      width: renderTableWidth(),
      sorter: true,
    },
    {
      title: "",
      key: "action",
      render: (_, record: any) => {
        if (!record.isOwner && !dashboardPermission) {
          return (
            <div
              style={{
                height: 36,
              }}
            />
          );
        }
        return (
          <Dropdown
            overlayClassName="custom-dropdown-overlay"
            trigger={["click"]}
            dropdownRender={() => (
              <Menu
                onClick={(e) => e.domEvent.stopPropagation()}
                items={[
                  {
                    key: "1",
                    label: <span className="label-checkbox">Edit</span>,
                    onClick: (e) => {
                      navigate(
                        Path.ResearchEdit.replace(
                          ":researchId",
                          record.id
                        ).replace(
                          ":companyId",
                          companyId
                            ? companyId
                            : dashboardPermission
                            ? "dashboard"
                            : record.company?.id
                        )
                      );
                    },
                  },
                  {
                    key: "2",
                    label: <span className="label-checkbox">Delete</span>,
                    onClick: (e) => {
                      setOpenDeleteFeed(true);
                      setDeleteFeedSelected(record);
                    },
                  },
                ]}
              />
            )}
          >
            <Button
              variant="outlined"
              sx={{
                padding: 0,
                width: 36,
                height: 12,
                borderRadius: 8,
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                },
                border: "none",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <ThreeDotIcon />
            </Button>
          </Dropdown>
        );
      },
      width: 60,
    },
  ];
  const renderButtonClear = () => {
    return (
      <div
        className="clear-all-btn"
        style={{
          width: isMobile ? "100%" : undefined,
          justifyContent: isMobile ? "space-between" : undefined,
        }}
        onClick={() => {
          setFilter({
            sectorIds: [],
            contentType: [],
            companyIds: [],
            dateRange: [null, null],
          });
        }}
      >
        <p>Clear all</p>
        <svg
          style={{
            width: 20,
            height: 20,
          }}
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.8941 11.3943L8.5 8.00014M8.5 8.00014L5.10589 4.60603M8.5 8.00014L11.8941 4.60603M8.5 8.00014L5.10589 11.3943"
            stroke="#E53935"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </div>
    );
  };
  const iShowClearAll =
    Boolean(filter.sectorIds?.length) ||
    Boolean(filter.contentType?.length) ||
    Boolean(compact(filter.dateRange)?.length) ||
    Boolean(filter.companyIds?.length);

  return (
    <React.Fragment>
      {!isDesktop ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 40,
            height: 40,
            borderRadius: "20px",
            backgroundColor: "#FFFFFF",
            position: "absolute",
            right: 20,
            top: companyId ? -87 : -40,
            cursor: "pointer",
          }}
          onClick={() => setIsFilterMobile(!isFilterMobile)}
        >
          {isFilterMobile ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.39126 1C1.62318 1 1 1.63366 1 2.41369V3.24573C1 3.82353 1.21972 4.37918 1.61276 4.79713L5.92009 9.37718L5.92198 9.37429C6.75352 10.2256 7.22138 11.3764 7.22138 12.5763V16.6402C7.22138 16.9117 7.50551 17.0851 7.74133 16.957L10.1943 15.6203C10.5646 15.4181 10.7957 15.0252 10.7957 14.5986V12.5657C10.7957 11.3725 11.2569 10.2265 12.0809 9.37718L16.3882 4.79713C16.7803 4.37918 17 3.82353 17 3.24573V2.41369C17 1.63366 16.3778 1 15.6097 1H2.39126Z"
                fill="#0091EA"
                stroke="#0091EA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.39126 1C1.62318 1 1 1.63366 1 2.41369V3.24573C1 3.82353 1.21972 4.37918 1.61276 4.79713L5.92009 9.37718L5.92198 9.37429C6.75352 10.2256 7.22138 11.3764 7.22138 12.5763V16.6402C7.22138 16.9117 7.50551 17.0851 7.74133 16.957L10.1943 15.6203C10.5646 15.4181 10.7957 15.0252 10.7957 14.5986V12.5657C10.7957 11.3725 11.2569 10.2265 12.0809 9.37718L16.3882 4.79713C16.7803 4.37918 17 3.82353 17 3.24573V2.41369C17 1.63366 16.3778 1 15.6097 1H2.39126Z"
                stroke="#667085"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
        </div>
      ) : null}

      <Row>
        <Col
          span={selectedFeed?.id ? 17 : 24}
          style={{
            transition: "all 0.3s",
            height: !isDesktop ? "auto" : "calc(100vh - 80px)",
            overflow: "auto",
            paddingTop: 20,
            paddingRight: !isDesktop ? 20 : 30,
            paddingBottom: 20,
            ...styleRoot,
          }}
        >
          {isFilterMobile ? (
            <Row
              justify={"space-between"}
              style={{
                marginBottom: 20,
                gap: 16,
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: 16,
                  flexWrap: "wrap",
                  width: !isDesktop ? "100%" : undefined,
                }}
              >
                {includes(keyColumns, "type") ? (
                  <SelectFilter
                    placeholder="Content Type"
                    value={filter.contentType}
                    options={LIST_CONTENT_TYPE}
                    onChange={(newValue: number[]) => {
                      setFilter({ contentType: newValue });
                    }}
                  />
                ) : null}

                {includes(keyColumns, "company") && (
                  <SelectFilter
                    placeholder="Company"
                    value={filter?.companyIds}
                    options={
                      companyOptions?.length
                        ? companyOptions?.map((company) => {
                            return {
                              name: company.name,
                              value: company.id,
                            };
                          })
                        : []
                    }
                    onChange={(newValue: string[]) =>
                      setFilter({ companyIds: newValue })
                    }
                  />
                )}
                {includes(keyColumns, "sector") && (
                  <SelectFilter
                    placeholder="Sector"
                    value={filter?.sectorIds}
                    options={
                      sectorOptions?.length
                        ? sectorOptions?.map((elSector) => {
                            return {
                              name: elSector.name,
                              value: elSector.id,
                            };
                          })
                        : []
                    }
                    onChange={(newValue: string[]) =>
                      setFilter({ sectorIds: newValue })
                    }
                  />
                )}
                {isDesktop ? (
                  <DateRangePicker
                    value={filter.dateRange as any}
                    suffixIcon={<CalendarIcon />}
                    className="date-picker"
                    onChange={(dates) => {
                      setFilter({ dateRange: dates });
                    }}
                  />
                ) : null}

                {iShowClearAll && !isDesktop && renderButtonClear()}
              </div>
              {iShowClearAll && isDesktop && renderButtonClear()}
            </Row>
          ) : null}

          {/* feed table */}
          <Row>
            <Col span={24}>
              {!feeds?.length ? (
                <Empty />
              ) : (
                <BoxGrid style={{ width: "unset" }} className={"custom-table"}>
                  <Table
                    scroll={
                      isDesktop
                        ? undefined
                        : {
                            x: 860,
                          }
                    }
                    style={{ width: "100%" }}
                    dataSource={feeds}
                    columns={columns.filter((column) =>
                      keyColumns.includes(column.key as string)
                    )}
                    onChange={handleTableChange}
                    pagination={{
                      current: filter.page,
                      pageSize: filter.limit,
                      className: "custom-table-pagination",
                      total,
                    }}
                    onRow={(record) => {
                      return {
                        onClick: () => handleChangeRow(record),
                      };
                    }}
                    rowClassName={(record, index) => {
                      return `${
                        record.id === selectedFeed?.id ? "selected-row" : ""
                      } ${index % 2 !== 0 ? "even-row" : ""}`;
                    }}
                  />
                </BoxGrid>
              )}
            </Col>
          </Row>

          <ConfirmModal
            open={openDeleteFeed}
            onClose={() => setOpenDeleteFeed(false)}
            onConfirm={handleDeleteFeed}
            variant="danger"
            message="Are you sure you want to delete this research?"
          />
        </Col>
        {isDesktop ? (
          <Col className={"feed-drawer"} span={selectedFeed?.id ? 7 : 0}>
            <div className="icon" onClick={() => setSelectedFeed(null)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M14.2426 14.2426L10 9.99993M10 9.99993L5.75736 5.75729M10 9.99993L14.2426 5.75729M10 9.99993L5.75736 14.2426"
                  stroke="#667085"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </div>
            <ResearchView
              researchId={selectedFeed?.id}
              showRecentResearch={false}
              isLinkCompany={companyId ? true : false}
            />
          </Col>
        ) : null}
      </Row>
    </React.Fragment>
  );
};

export default Feeds;
