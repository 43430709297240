import { FeedType } from "../constants/feed";
import axiosApiInstance from "../utils/axiosClient";

const feedApi = {
  getList: async (params?: {
    companyIds?: string[];
    page: number;
    limit: number;
    orderBy: string;
    orderDirection: "ASC" | "DESC";
    search?: string;
    sectorIds: string[];
    contentTypes: number[];
    startTime?: string;
    endTime?: string;
  }) => {
    const res = await axiosApiInstance.get("/feeds", {
      params,
    });
    return res.data;
  },
  get: async (id: string) => {
    const res = await axiosApiInstance.get(`/feeds/${id}`);
    return res.data;
  },
  preview: async (id: string) => {
    const res = await axiosApiInstance.get(`/feeds/${id}/preview`);
    return res.data;
  },
  create: async (data: any, feedType = FeedType.PERSONAL, isDraft = false) => {
    const formattedData = { ...data, type: feedType, isDraft };
    const res = await axiosApiInstance.post("/feeds", formattedData);
    return res.data;
  },
  upload: async (
    feedId: string,
    data: any,
    feedType: number,
    isDraft = false
  ) => {
    const formattedData = { ...data, type: feedType, isDraft };
    const res = await axiosApiInstance.patch(`/feeds/${feedId}`, formattedData);
    return res.data;
  },
  edit: async (id: string, data: any, feedType: number, isDraft = false) => {
    const formattedData = { ...data, type: feedType, isDraft };
    const res = await axiosApiInstance.patch(`/feeds/${id}`, formattedData);
    return res.data;
  },
  delete: async (id: string) => {
    const res = await axiosApiInstance.delete(`/feeds/${id}`);
    return res.data;
  },
  generatePdf: async (
    id: string,
    header: string,
    link: string,
    time: string,
    read: string
  ) => {
    const res = await axiosApiInstance.post(`/feeds/${id}/generate-pdf`, {
      header,
      link,
      time,
      read,
    });
    return res.data;
  },
  saveContent: async (id: string) => {
    const res = await axiosApiInstance.post(`/feeds/${id}/save-to-my-content`);
    return res.data;
  },
};
export default feedApi;
