import React from "react";
import logo from "assets/images/icon_logo_auth.svg";

import { Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { emailRule } from "utils/rules";
import authApi from "api/auth";
import FormInput from "components/UI/Form/FormInput";
import { httpRequestErrors } from "utils/errors";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
    },
  });

  const onSubmit = async (data: { email: string }) => {
    try {
      await authApi.forgotPassword(data);
      navigate("/confirm-email", {
        state: data,
      });
    } catch (error) {
      httpRequestErrors(error);
    }
  };
  return (
    <div className="auth-form">
      <form className="form-content" onSubmit={handleSubmit(onSubmit)}>
        <img className="form-logo" src={logo} alt="" />

        <div className="form">
          <div>
            <h4>I missed!</h4>
            <h2>Forgot Password</h2>
          </div>
          <div className="form-item">
            <Row>
              <Col span={24}>
                <FormInput
                  name="email"
                  control={control}
                  rules={emailRule}
                  label={"E-mail"}
                  placeholder="you@company.com"
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="btn-container">
          <Row>
            <Col span={24}>
              <button type="submit" className="btn-get-started">
                Send
              </button>
            </Col>
          </Row>
          <div className="text-bottom">
            <p>
              Back to&nbsp;
              <span onClick={() => navigate("/sign-in")}>Sign In</span>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
