import { CSSProperties } from "react";
import styles from "./Card.module.scss";

interface Props {
  image?: string;
  title: string;
  description: string;
  cardStyles?: CSSProperties;
  imageStyles?: CSSProperties;
  imageConatinerStyles?: CSSProperties;
}
const Card = ({
  image,
  title,
  description,
  cardStyles,
  imageStyles,
  imageConatinerStyles,
}: Props) => {
  return (
    <div style={cardStyles} className={`${styles["card"]} custom-card`}>
      <div style={imageConatinerStyles} className="card-image-container">
        {image && (
          <img loading="lazy" style={imageStyles} src={image} alt={title} />
        )}
      </div>
      <div>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Card;
