import { useEffect, useState } from "react";
import "../uploadResearch/style.scss";
import { useParams } from "react-router-dom";
import { checkDevice, formatDate, getMinuteRead } from "utils/helpers";
import feedApi from "../../../api/feed";
import { BlockDetail } from "./type";
import Tag from "pages/dashboard/components/Tag";
import { Button, Skeleton, Tooltip } from "antd";
import { ReactComponent as DownloadIcon } from "../../../assets/images/download.svg";
import { ReactComponent as PrinterIcon } from "../../../assets/images/printer_minimalistic.svg";
import styles from "./ResearchViewPublic.module.scss";
import { toast } from "react-toastify";
import { HtmlEditor } from "components/Pages/Cms/UploadResearch/HtmlEditor";
import Footer from "components/Global/Footer";
const ResearchViewPublic = () => {
  const { isDesktop, isMobile } = checkDevice();

  const [blocks, setBlocks] = useState<BlockDetail[]>([]);

  const [research, setResearch] = useState<any>(null);

  const { id: researchId } = useParams();
  const shareLink = `Article: ${researchId}`;

  useEffect(() => {
    if (researchId) {
      // fetch research
      getResearch(researchId);
    }
  }, [researchId]);

  const getResearch = async (id: string) => {
    const researchData = await feedApi.preview(id);
    if (researchData) {
      setBlocks(researchData.blocks);
      setResearch({
        ...researchData,
      });
    }
  };

  const onGeneratePdf = (isPrint: boolean) => async () => {
    if (!research?.id) {
      return;
    }
    try {
      const dataRes: {
        data: Buffer;
      } = await feedApi.generatePdf(
        research.id,
        `${isPrint ? "Print" : "Download"} for BONDVEXITY on ${formatDate(
          new Date()
        )}`,
        shareLink,
        getMinuteRead(blocks[0]?.content || ""),
        getMinuteRead(blocks[0]?.content || "")
      );
      if (!dataRes?.data) {
        toast.error("Failed to download research.");
        return;
      }

      // Convert Buffer to Uint8Array
      const uint8Array = new Uint8Array(dataRes.data);

      // Convert Uint8Array to Blob
      const blob = new Blob([uint8Array], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      if (isPrint) {
        // Open the URL in a new tab
        const newWindow = window.open(url);

        if (newWindow) {
          // Trigger the print dialog in the new tab
          newWindow.onload = () => {
            newWindow.print();
          };
        }
        return;
      }

      // Create a link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${research.name}-${new Date().getTime()}.pdf`
      );

      // Append to the document and trigger download
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
      toast.success("Article downloaded successfully.");
    } catch (error) {
      toast.error("An error occurred while downloading the research.");
      console.error(error);
    }
  };

  if (!research?.id) {
    return (
      <div
        style={{
          padding: 20,
          backgroundColor: "#F3F5F6",
        }}
      >
        <Skeleton />
      </div>
    );
  }

  return (
    <div>
      <div
        className={styles["container"]}
        style={{
          minWidth: isDesktop ? undefined : "auto",
        }}
      >
        {/* <div
        style={{
          marginBottom: 15,
        }}
      >
        <div className={styles["article-title"]}>
          <p>{research.name}</p>
        </div>
      </div> */}
        <div className={styles["article-info"]}>
          <Tag type={research.type} />

          <div className={styles["article-actions-container"]}>
            <Tooltip placement="bottom" title="Download">
              <Button
                style={{
                  backgroundColor: "transparent",
                  padding: "0px",
                  width: "32px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={onGeneratePdf(false)}
              >
                <DownloadIcon />
              </Button>
            </Tooltip>
            <Tooltip placement="bottom" title={"Print"}>
              <Button
                style={{
                  backgroundColor: "transparent",
                  padding: "0px",
                  width: "32px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={onGeneratePdf(true)}
              >
                <PrinterIcon />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className={styles["block-container"]}>
          <div
            id="research-view"
            style={{
              padding: isDesktop ? "32px" : "16px",
            }}
          >
            <p className={styles["research-name"]}>{research.name}</p>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 24,
                paddingBottom: 16,
                borderBottom: "1px solid #cccccc",
              }}
            >
              {research.summary ? (
                <p
                  className={styles["user-select"]}
                  style={{
                    color: "#667085",
                    fontSize: 18,
                    fontWeight: 400,
                    lineHeight: "27px",
                    fontFamily: "Google Sans",
                  }}
                >
                  {research.summary}
                </p>
              ) : null}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: research.summary ? 16 : 0,
                }}
              >
                <p
                  style={{
                    color: "#667085",
                    lineHeight: "24px",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  {formatDate(research.createdAt)}
                </p>
                <div
                  style={{
                    width: 4,
                    minWidth: 4,
                    height: 4,
                    backgroundColor: "#667085",
                    margin: "0 8px",
                    borderRadius: "50%",
                  }}
                ></div>
                <p
                  style={{
                    color: "#667085",
                    lineHeight: "24px",
                    fontSize: 16,
                    fontWeight: 500,
                  }}
                >
                  {getMinuteRead(blocks[0]?.content || "")}
                </p>
              </div>
            </div>
            {Boolean(blocks.length) && (
              <HtmlEditor
                idToolbar="research-view-public"
                value={blocks[0].content}
                placeholder={""}
                readOnly={true}
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles["footer"]}>
        <Footer />
      </div>
    </div>
  );
};

export default ResearchViewPublic;
