import { Tag as TagA } from "antd";
import { LIST_CONTENT_TYPE } from "constants/feed";
import { FC } from "react";

type Props = {
  type: number;
};
const Tag: FC<Props> = ({ type }) => {
  const item =
    LIST_CONTENT_TYPE.find((item) => item.value === type) ||
    LIST_CONTENT_TYPE[0];
  let borderColor = " #ADC6FF";
  let backgroundColor = "#F0F5FF";
  let color = "#2F54EB";

  switch (item.value) {
    case 1:
      borderColor = "#FFD591";
      backgroundColor = "#FFF7E6";
      color = "#FA8C16";
      break;
    case 2:
      borderColor = "#B7EB8F";
      backgroundColor = "#F6FFED";
      color = "#52C41A";
      break;
    case 3:
      borderColor = "#91CAFF";
      backgroundColor = "#E6F4FF";
      color = "#1677FF";
      break;

    case 4:
      borderColor = "#FFA39E";
      backgroundColor = "#FFF1F0";
      color = "#F5222D";
      break;
    case 5:
      borderColor = "#87E8DE";
      backgroundColor = "#E6FFFB";
      color = "#13C2C2";
      break;
    case 6:
      borderColor = "#D3ADF7";
      backgroundColor = "#F9F0FF";
      color = "#722ED1";
      break;
    case 7:
      borderColor = "#ffadd2";
      backgroundColor = "#fff0f6";
      color = "#c41d7f";
      break;
    case 8:
      borderColor = "#fff7e6";
      backgroundColor = "#fff7e6";
      color = "#d46b08";
      break;
    case 9:
      borderColor = "#fcffe6";
      backgroundColor = "#fcffe6";
      color = "#7cb305";
      break;
    case 10:
      borderColor = "#D9D9D9";
      backgroundColor = "rgba(0, 0, 0, 0.02)";
      color = "#000000E0";
      break;
  }

  return (
    <TagA
      style={{
        textAlign: "center",
        fontSize: 14,
        borderRadius: 5,
        fontWeight: 500,
        background: backgroundColor,
        padding: "3px 6px",
        border: `1px solid ${borderColor}`,
        color: color,
        lineHeight: "18px",
        margin: 0,
      }}
    >
      {item.name}
    </TagA>
  );
};

export default Tag;
