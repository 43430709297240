import { Checkbox, TabPanel } from "@mui/joy";
import { List, Row } from "antd";
import Input from "components/UI/Input/Input";
import { CompanyDetail } from "pages/dashboard/components/CompanyTable";
import { ReactComponent as SearchIcon } from "assets/images/icon_search.svg";
import { useCallback, useEffect, useState } from "react";
import companyApi from "api/company";
import { debounce } from "lodash";
import { Selected } from "types/alert-settings";

interface Props {
  handleSelectCompany: (company: Selected) => void;
  selectedCompanies: Selected[];
}
const CompanyTab = ({ handleSelectCompany, selectedCompanies }: Props) => {
  const [companies, setCompanies] = useState<CompanyDetail[]>([]);
  const [searchText, setSearchText] = useState("");
  const getCompanies = useCallback(async (searchText: string) => {
    const dataRes: {
      items: CompanyDetail[];
      total: number;
    } = await companyApi.getList({
      page: 1,
      limit: 100,
      search: searchText,
      orderBy: "name",
      orderDirection: "ASC",
    });
    setCompanies(dataRes?.items ?? []);
  }, []);

  useEffect(() => {
    getCompanies("");
  }, [getCompanies]);

  const handleChangeInputSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    debounceSearch(e.target.value);
  };

  const debounceSearch = useCallback(
    debounce((value: string) => {
      getCompanies(value);
    }, 300),
    []
  );

  return (
    <TabPanel
      value="company"
      sx={{
        paddingTop: 0,
      }}
    >
      <Row
        style={{
          marginBottom: 8,
        }}
      >
        <Input
          onChange={handleChangeInputSearch}
          value={searchText}
          fullWidth
          placeholder="Search Company"
          startDecorator={<SearchIcon />}
        />
      </Row>
      <Row>
        <List
          style={{
            width: "100%",
            height: "400px",
            overflowY: "auto",
            paddingRight: "10px",
          }}
          dataSource={companies}
          itemLayout="horizontal"
          renderItem={(company) => {
            return (
              <List.Item
                actions={[
                  <Checkbox
                    checked={Boolean(
                      selectedCompanies.find((item) => item.id === company.id)
                    )}
                    onChange={() => handleSelectCompany(company)}
                  />,
                ]}
              >
                <List.Item.Meta
                  title={company.name}
                  description={company.sectors
                    .map((sector) => sector.name)
                    .join(", ")}
                />
              </List.Item>
            );
          }}
        />
      </Row>
    </TabPanel>
  );
};

export default CompanyTab;
