import axiosApiInstance from "../utils/axiosClient";

const configApi = {
  aboutUs: async () => {
    const res = await axiosApiInstance.get("/configs/about-us");
    return res.data;
  },
  regions: async () => {
    const res = await axiosApiInstance.get("/configs/regions");
    return res.data;
  },
  sectors: async () => {
    const res = await axiosApiInstance.get("/configs/sectors");
    return res.data;
  },
  creditRisks: async () => {
    const res = await axiosApiInstance.get("/configs/credit-risk");
    return res.data;
  },
  bondVexityViews: async () => {
    const res = await axiosApiInstance.get("/configs/bond-vexity-view");
    return res.data;
  },
  getIndustries: async () => {
    const res = await axiosApiInstance.get("/configs/industries");
    return res.data;
  },
  companies: async () => {
    const res = await axiosApiInstance.get("/configs/companies");
    return res.data;
  },
};

export default configApi;
