import { ICompany } from "./company";
export enum EFeedBlockType {
  TEXT = 0,
  FILE = 1,
  QUOTE = 2,
  CODE_BLOCK = 3,
}
export enum EFeedType {
  PERSONAL,
  GENERAL,
}

export interface IFeedBlock {
  type: EFeedBlockType;
  content: string;
  order: number;
  files: any;
}

export enum EFeedBlockTypeValue {
  TEXT = "text",
  FILE = "file",
  QUOTE = "quote",
  CODE_BLOCK = "embed",
}

export interface IFeed {
  createdAt: Date;
  updatedAt: Date;
  deletedAt: string;
  createdBy: string;
  deletedBy: string;
  id: string;
  name: string;
  summary: string;
  featureImage: string;
  isDraft: boolean;
  type: number;
  blocks: IFeedBlock[];
  industryId: string;
  company: ICompany;
  isSaveToContent?: boolean;
  recentResearch: RecentResearchItem[];
  isOwner?: boolean;
}

export interface RecentResearchItem {
  id: string;
  name: string;
  type: number;
  createdAt: Date;
  summary: string;
  content: string;
}

export const defaultFeed: IFeed = {
  createdAt: new Date(),
  updatedAt: new Date(),
  deletedAt: "",
  createdBy: "",
  deletedBy: "",
  id: "",
  name: "",
  summary: "",
  featureImage: "",
  isDraft: false,
  type: 0,
  blocks: [],
  industryId: "",
  company: {
    createdAt: "",
    updatedAt: "",
    deletedAt: "",
    createdBy: "",
    deletedBy: "",
    id: "",
    name: "",
    description: "",
  },
  recentResearch: [],
};
