import { motion } from "framer-motion";
import Footer from "components/Global/Footer";
import Header from "components/Global/Header";
import { Outlet } from "react-router-dom";
const LandingPageLayout = () => {
  return (
    <motion.div initial="initial" animate="animate" className={"main-content"}>
      <Header />
      <Outlet />
      <Footer />
    </motion.div>
  );
};

export default LandingPageLayout;
