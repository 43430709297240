import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  ModalProps,
  Select,
  Option,
} from "@mui/joy";

import "./style.scss";
import { Col, Row } from "antd";
import { Controller, useForm } from "react-hook-form";
import { defaultRule } from "utils/rules";
import icon_select from "assets/images/icon_select.svg";
import { useEffect } from "react";
import { httpRequestErrors } from "utils/errors";
import companyApi from "api/company";
import { trimObject } from "utils/helpers";
import { toast } from "react-toastify";

type FormState = {
  name: string;
  sectorId: string;
  companyId?: string;
  bondVexityViewId: string;
  creditRiskId: string;
};

type Props = Omit<ModalProps, "children"> & {
  company: FormState;
  isUserUpdateCompany?: boolean;
  callback: () => void;
  listCreditRisks: {
    id: string;
    name: string;
  }[];
  listSectors: {
    id: string;
    name: string;
  }[];
  listBondVexityViews: {
    id: string;
    name: string;
  }[];
};

const FormCompanyModal: React.FC<Props> = ({
  open,
  onClose,
  company,
  isUserUpdateCompany,
  callback,
  listSectors,
  listCreditRisks,
  listBondVexityViews,
}) => {
  const { control, handleSubmit, reset } = useForm<FormState>({
    defaultValues: {
      name: "",
      sectorId: "",
      bondVexityViewId: "",
      creditRiskId: "",
    },
  });

  useEffect(() => {
    if (!open) {
      return;
    }
    reset({
      name: company.name,
      sectorId: company.sectorId,
      creditRiskId: company.creditRiskId,
      bondVexityViewId: company.bondVexityViewId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, company]);

  const onSubmit = async (data: FormState) => {
    try {
      const bodyRequest = trimObject(data);
      if (company.companyId) {
        await companyApi.upload(company.companyId, {
          name:
            isUserUpdateCompany && company.companyId
              ? company.name
              : bodyRequest.name,
          creditRiskId: bodyRequest.creditRiskId,
          sectorId: bodyRequest.sectorId,
          bondVexityViewId: bodyRequest.bondVexityViewId,
        });
        if (isUserUpdateCompany) {
          toast.success("Update company successfully!");
        } else {
          toast.success("Edit company successfully!");
        }
      } else {
        await companyApi.create({
          name: bodyRequest.name,
          creditRiskId: bodyRequest.creditRiskId,
          sectorId: bodyRequest.sectorId,
          bondVexityViewId: bodyRequest.bondVexityViewId,
        });
        if (isUserUpdateCompany) {
          toast.success("Update company successfully!");
        } else {
          toast.success("Add company successfully!");
        }
      }
      callback();
    } catch (error) {
      httpRequestErrors(error);
    }
  };

  return (
    <Modal open={open} onClose={onClose} className="custom-modal" style={{}}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>
          {" "}
          {isUserUpdateCompany
            ? "Update company"
            : company.companyId
            ? "Edit company"
            : "Add company"}
        </DialogTitle>
        <Divider />
        <form className="form-content" onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Row>
              <Col span={24}>
                <Controller
                  name="name"
                  control={control}
                  disabled={
                    isUserUpdateCompany && company.companyId && company.name
                      ? true
                      : false
                  }
                  rules={defaultRule("Company name")}
                  render={({ field, fieldState }) => (
                    <FormControl>
                      <FormLabel required>Company name</FormLabel>
                      <Input {...field} placeholder="Company name" />
                      {fieldState.error && (
                        <FormHelperText
                          sx={{
                            color: "red",
                          }}
                        >
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Col>
            </Row>

            <Row
              style={{
                marginTop: 16,
              }}
            >
              <Col span={24}>
                <Controller
                  name="sectorId"
                  control={control}
                  rules={defaultRule("Sector")}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl>
                        <FormLabel required>Sector</FormLabel>
                        <Select
                          {...field}
                          value={field.value}
                          onChange={(event, newValue) =>
                            field.onChange(newValue)
                          }
                          indicator={<img src={icon_select} alt="" />}
                        >
                          {listSectors.map((sector) => {
                            return (
                              <Option key={sector.id} value={sector.id}>
                                {sector.name}
                              </Option>
                            );
                          })}
                        </Select>
                        {fieldState.error && (
                          <FormHelperText
                            sx={{
                              color: "red",
                            }}
                          >
                            {fieldState.error.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    );
                  }}
                />
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 16,
              }}
            >
              <Col span={24}>
                <Controller
                  name="creditRiskId"
                  control={control}
                  rules={defaultRule("Credit Risk")}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl>
                        <FormLabel required>Credit Risk</FormLabel>
                        <Select
                          {...field}
                          value={field.value}
                          onChange={(event, newValue) =>
                            field.onChange(newValue)
                          }
                          indicator={<img src={icon_select} alt="" />}
                        >
                          {listCreditRisks.map((creditRisk) => {
                            return (
                              <Option key={creditRisk.id} value={creditRisk.id}>
                                {creditRisk.name}
                              </Option>
                            );
                          })}
                        </Select>
                        {fieldState.error && (
                          <FormHelperText
                            sx={{
                              color: "red",
                            }}
                          >
                            {fieldState.error.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    );
                  }}
                />
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 16,
              }}
            >
              <Col span={24}>
                <Controller
                  name="bondVexityViewId"
                  control={control}
                  rules={defaultRule("Bondvexity View")}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl>
                        <FormLabel required>Bondvexity View</FormLabel>
                        <Select
                          {...field}
                          value={field.value}
                          onChange={(event, newValue) =>
                            field.onChange(newValue)
                          }
                          indicator={<img src={icon_select} alt="" />}
                        >
                          {listBondVexityViews.map((bondView) => {
                            return (
                              <Option key={bondView.id} value={bondView.id}>
                                {bondView.name}
                              </Option>
                            );
                          })}
                        </Select>
                        {fieldState.error && (
                          <FormHelperText
                            sx={{
                              color: "red",
                            }}
                          >
                            {fieldState.error.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    );
                  }}
                />
              </Col>
            </Row>
          </DialogContent>
          <DialogActions>
            {isUserUpdateCompany ? (
              <Button
                style={{
                  width: "100%",
                  height: 44,
                  background: "#0091EA",
                  borderRadius: 30,
                }}
                type="submit"
              >
                Save
              </Button>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 16,
                }}
              >
                <Button
                  onClick={(e) => onClose?.(e, "backdropClick")}
                  variant="outlined"
                  style={{
                    width: "100%",
                    height: 44,
                    borderRadius: 30,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    width: "100%",
                    height: 44,
                    background: "#0091EA",
                    borderRadius: 30,
                  }}
                  type="submit"
                >
                  {company.companyId ? "Edit" : "Add"}
                </Button>
              </div>
            )}
          </DialogActions>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export default FormCompanyModal;
