import { FC } from "react";
import icon_arrow_outward from "../../../assets/images/icon_arrow_outward.svg";

type Props = {
  title: string;
  text: string;
  subtitle: string;
  style?: React.CSSProperties;
};
const WhyChooseItem: FC<Props> = ({ title, text, style, subtitle }) => {
  return (
    <div className="why-choose-item" style={style}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <h3>{title}</h3>
        <a
          href="#"
          style={{
            marginTop: "10px",
          }}
        >
          <img src={icon_arrow_outward} alt="" />
        </a>
      </div>
      <p className="subtitle">{subtitle}</p>
      <p>{text}</p>
    </div>
  );
};

export default WhyChooseItem;
