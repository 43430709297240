import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
  ModalProps,
} from "@mui/joy";
type Props = Omit<ModalProps, "children"> & {
  header?: string;
  onConfirm?: () => void;
  message?: React.ReactNode;
  variant?: "neutral" | "danger" | "warning";
  customActions?: React.ReactNode;
  children?: React.ReactNode;
  buttonAction?: React.ReactNode;
};
const ConfirmModal: React.FC<Props> = ({
  open,
  onConfirm,
  title,
  message,
  onClose,
  variant,
  buttonAction,
}) => {
  return (
    <Modal open={open} onClose={onClose} className="custom-modal">
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>{title || "Confirmation"}</DialogTitle>
        <Divider />
        <DialogContent>
          {message || "Are you sure you want to proceed?"}
        </DialogContent>
        <DialogActions>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              gap: 16,
            }}
          >
            {buttonAction ? (
              buttonAction
            ) : (
              <>
                <Button
                  onClick={(e) => onClose?.(e, "backdropClick")}
                  variant="outlined"
                  style={{
                    width: "100%",
                    height: 44,
                    borderRadius: 30,
                  }}
                >
                  Cancel
                </Button>
                <Button
                  style={{
                    width: "100%",
                    height: 44,
                    borderRadius: 30,
                  }}
                  variant="solid"
                  color={variant || "neutral"}
                  onClick={onConfirm}
                >
                  Proceed
                </Button>
              </>
            )}
          </div>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default ConfirmModal;
