import "./style.scss";
import { Tabs, TabList, Tab, TabPanel } from "@mui/joy";
import { ReactComponent as UploadResearchIcon } from "../../../assets/images/upload_research_icon.svg";
import { tabClasses } from "@mui/joy/Tab";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Home from "../../../components/Pages/Cms/Company/Home/Home";
import ComingSoon from "pages/ComingSoon";
import Button from "components/UI/Button/Button";
import { LayoutContent } from "components/Layout/LayoutContent";
import { Row } from "antd";
import { PermissionKey, useCheckPermission } from "hooks/useCheckPermission";
import UploadImageTab from "./components/UploadImageTab";
import { FileTargetType } from "types/file";
import React, { useEffect, useState } from "react";
import { CompanyDetail } from "pages/dashboard/components/CompanyTable";
import companyApi from "api/company";
import { httpRequestErrors } from "utils/errors";
import { checkDevice } from "utils/helpers";
import { backIn } from "framer-motion";
import { includes } from "lodash";

const TABS = [
  {
    path: "home",
    name: "Home",
  },
  {
    path: "pricing",
    name: "Pricing",
  },
  {
    path: "capital-structure",
    name: "Capital Structure",
  },
  {
    path: "financial",
    name: "Summary Financials",
  },
  {
    path: "org-chart",
    name: "Org Chart",
  },
  // {
  //   path: "documents-key-filings",
  //   name: "Documents / Key Filings",
  // },
  // {
  //   path: "calendar",
  //   name: "Calendar",
  // },
  // {
  //   path: "holdings",
  //   name: "Top Holders",
  // },
];

const Company = () => {
  const { isDesktop, isMobile } = checkDevice();

  const navigate = useNavigate();
  const [query, setQuery] = useSearchParams();
  const { id: companyId } = useParams();
  const tabs = query.get("tab") || "home";
  const editFeedPermission = useCheckPermission(
    PermissionKey.EDIT_FEED_DASHBOARD
  );
  const [isOwnerCompany, setIsOwnerCompany] = useState(false);

  useEffect(() => {
    if (companyId) {
      getCompanyDetail(companyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyId]);

  const getCompanyDetail = async (originCompanyId: string) => {
    try {
      const dataRes: CompanyDetail = await companyApi.get(originCompanyId);
      if (dataRes?.id) {
        setIsOwnerCompany(dataRes?.isOwnerCompany || false);
      }
    } catch (error) {
      navigate(-1);
    }
  };

  const [company, setCompany] = useState<CompanyDetail>();

  useEffect(() => {
    if (companyId) {
      companyApi
        .get(companyId)
        .then((data) => {
          setCompany(data);
        })
        .catch((error) => {
          httpRequestErrors(error);
        });
    }
  }, [companyId]);
  const renderTabContent = (
    value: string,
    tabCompany: FileTargetType,
    customStyle?: React.CSSProperties
  ) => {
    return (
      <TabPanel
        value={value}
        sx={{
          paddingLeft: 0,
          paddingRight: 0,
          paddingBottom: 0,
          marginRight: isMobile ? "20px !important" : "30px !important",
          paddingTop: "20px !important",
          ...customStyle,
        }}
      >
        <UploadImageTab
          tabCompany={tabCompany}
          isOwnerCompany={company?.isOwnerCompany || false}
        />
      </TabPanel>
    );
  };

  return (
    <LayoutContent
      title={company?.name ?? "COMPANY"}
      styleHeader={{
        borderBottom: "1px solid transparent",
      }}
      header={
        <Row>
          {(editFeedPermission || isOwnerCompany) && isDesktop ? (
            <Button
              startDecorator={<UploadResearchIcon />}
              sx={{
                bgcolor: "#0091EA",
                borderRadius: 18,
                padding: "12px 35px",
                alignItems: "center",
                textAlign: "center",
              }}
              onClick={() => navigate("/upload-research/" + companyId)}
            >
              <span>Upload research</span>
            </Button>
          ) : null}
        </Row>
      }
      styleChildrenBody={{
        paddingRight: 0,
        paddingTop: 0,
      }}
      styleContainer={{
        height: "100%",
      }}
      styleBody={{
        height: "100%",
      }}
      styleTitle={
        isDesktop
          ? undefined
          : {
              height: 40,
              display: "flex",
              alignItems: "center",
            }
      }
    >
      <Tabs
        defaultValue={tabs}
        className="custom-tabs"
        sx={{
          backgroundColor: "transparent",
        }}
        onChange={(_event, value) => {
          query.set("tab", String(value));
          setQuery(query);
        }}
      >
        <TabList
          style={{
            overflow: "auto",
            marginTop: !isDesktop ? 10 : 0,
          }}
          sx={{
            [`& .${tabClasses.root}`]: {
              fontSize: "sm",
              //   fontWeight: "lg",
              [`&[aria-selected="true"]`]: {
                color: "#0091EA",
                bgcolor: "transparent",
                fontWeight: "700",
              },
              [`&.${tabClasses.focusVisible}`]: {
                outlineOffset: "-4px",
              },
            },
          }}
        >
          {TABS.map((tab) => (
            <Tab
              key={tab.path}
              onChange={(e) => console.log("evet1", e.currentTarget)}
              value={tab.path}
              style={{
                whiteSpace: "nowrap",
                minWidth: isMobile
                  ? includes(["Home", "Pricing"], tab.name)
                    ? "80px"
                    : "140px"
                  : undefined,
              }}
            >
              {tab.name}
            </Tab>
          ))}
        </TabList>
        <div className="tabs-content">
          <TabPanel
            value={"home"}
            sx={{
              padding: 0,
            }}
          >
            <Home />
          </TabPanel>
          {renderTabContent("pricing", FileTargetType.COMPANY_SUMMARY)}
          {renderTabContent(
            "capital-structure",
            FileTargetType.COMPANY_CAPITAL_STRUCTURE
          )}

          {renderTabContent("financial", FileTargetType.COMPANY_FINANCIAL)}
          {renderTabContent("org-chart", FileTargetType.ORG_CHART)}
          {renderTabContent(
            "documents-key-filings",
            FileTargetType.DOCUMENT_KEY_FILINGS
          )}

          {TABS.map((tab) => {
            return (
              ["calendar"].includes(tab.path) && (
                <TabPanel key={tab.path} value={tab.path}>
                  <ComingSoon />
                </TabPanel>
              )
            );
          })}
        </div>
      </Tabs>
    </LayoutContent>
  );
};

export default Company;
