import React from "react";
import { useNavigate } from "react-router-dom";

const NavRight = () => {
  const navigate = useNavigate();
  return (
    <div className="nav-right">
      <a className="login" onClick={() => navigate("/sign-in")}>
        Login
      </a>
      <div className="btn-join-now" onClick={() => navigate("/sign-up")}>
        Join Now
      </div>
    </div>
  );
};

export default NavRight;
