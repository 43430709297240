import HomePage from "pages/home";
import ConfirmEmailPage from "pages/auth/ConfirmEmail";
import ForgotPassword from "pages/auth/ForgotPassword";
import ResetPassword from "pages/auth/resetPassword";
import SignIn from "pages/auth/signIn";
import SignUp from "pages/auth/signUp";
import VerifyAccount from "pages/auth/verifyAccount";
import UploadResearch from "pages/cms/uploadResearch";
import Settings from "pages/cms/settings";
import Company from "pages/cms/company";
import ResearchEdit from "pages/cms/research";
import ResearchView from "pages/cms/research/ResearchView";
import Dashboard from "pages/dashboard";
import Companies from "pages/cms/company/Companies";
import MyContent from "pages/my-content";
import Market from "pages/market";
import Sector from "pages/sector";
import ComingSoonPage from "pages/coming-soon";
import TopPicks from "pages/top-pick";
import WeeklyRelativeValue from "pages/weekly-relative-value";
import Users from "pages/user/User";
import SignUpSuccessPage from "pages/auth/SignUpSuccess";
export enum Path {
  Home = "/",
  Dashboard = "/dashboard",
  Company = "/company/:id",
  Companies = "/company",
  SignIn = "/sign-in",
  SignUp = "/sign-up",
  ForgotPassword = "/forgot-password",
  ResetPassword = "/reset-password",
  ConfirmEmail = "/confirm-email",
  SignUpSuccess = "/sign-up-success",
  VerifyAccount = "/verify",
  Settings = "/settings",
  UploadResearch = "/upload-research/:companyId",
  ResearchEdit = "/research-edit/:companyId/:researchId",
  ResearchView = "/research-view/:researchId",
  ResearchPublic = "/research/:id",
  Sector = "/sector/:sectorId",
  Market = "/market",
  SavedContent = "/saved-content",
  More = "/more",
  WeeklyRV = "/weekly-rv",
  TopPicks = "/top-picks",
  Calendar = "/calendar",
  Events = "/events",
  News = "/news",
  Resources = "/resources",
  Webinars = "/webinars",
  Podcasts = "/podcasts",
  Users = "/users",
}

interface IPath {
  path: string;
  component: any;
  isPrivate?: boolean;
  name?: string;
  icon?: React.ReactNode;
  isHeader?: boolean;
  isInvisible?: boolean;
  isExternal?: boolean;
  color?: string;
  isRaw?: boolean;
}

export const routes: IPath[] = [
  {
    path: Path.Home,
    component: HomePage,
    isPrivate: false,
    isInvisible: true,
    isRaw: true,
  },
  {
    path: Path.SignIn,
    component: SignIn,
    isPrivate: false,
    isInvisible: true,
  },
  {
    path: Path.SignUp,
    component: SignUp,
    isPrivate: false,
    isInvisible: true,
  },
  {
    path: Path.VerifyAccount,
    component: VerifyAccount,
    isPrivate: false,
    isInvisible: true,
  },
  {
    path: Path.ConfirmEmail,
    component: ConfirmEmailPage,
    isPrivate: false,
    isInvisible: true,
  },
  {
    path: Path.SignUpSuccess,
    component: SignUpSuccessPage,
    isPrivate: false,
    isInvisible: true,
  },
  {
    path: Path.ResetPassword,
    component: ResetPassword,
    isPrivate: false,
    isInvisible: true,
  },
  {
    path: Path.ForgotPassword,
    component: ForgotPassword,
    isPrivate: false,
    isInvisible: true,
  },
  {
    path: Path.Dashboard,
    component: Dashboard,
    isPrivate: true,
  },
  {
    path: Path.Settings,
    component: Settings,
    isPrivate: true,
  },
  {
    path: `${Path.Settings}/:action`,
    component: Settings,
    isPrivate: true,
  },
  {
    path: `${Path.Settings}/:action/:notificationId`,
    component: Settings,
    isPrivate: true,
  },
  {
    path: Path.UploadResearch,
    component: UploadResearch,
    isPrivate: true,
  },
  {
    path: Path.ResearchEdit,
    component: ResearchEdit,
    isPrivate: true,
  },
  {
    path: Path.ResearchView,
    component: ResearchView,
    isPrivate: true,
  },
  {
    path: Path.Companies,
    component: Companies,
    isPrivate: true,
  },
  {
    path: Path.Company,
    component: Company,
    isPrivate: true,
  },
  {
    path: Path.SavedContent,
    component: MyContent,
    isPrivate: true,
  },
  {
    path: Path.Market,
    component: Market,
    isPrivate: true,
  },
  {
    path: Path.Sector,
    component: Sector,
    isPrivate: true,
  },
  {
    path: Path.Events,
    component: ComingSoonPage,
    isPrivate: true,
  },
  {
    path: Path.News,
    component: ComingSoonPage,
    isPrivate: true,
  },
  {
    path: Path.Resources,
    component: ComingSoonPage,
    isPrivate: true,
  },
  {
    path: Path.Webinars,
    component: ComingSoonPage,
    isPrivate: true,
  },
  {
    path: Path.Podcasts,
    component: ComingSoonPage,
    isPrivate: true,
  },
  {
    path: Path.WeeklyRV,
    component: WeeklyRelativeValue,
    isPrivate: true,
  },
  {
    path: Path.TopPicks,
    component: TopPicks,
    isPrivate: true,
  },
  {
    path: Path.Calendar,
    component: ComingSoonPage,
    isPrivate: true,
  },
  {
    path: Path.Users,
    component: Users,
    isPrivate: true,
  },
];
