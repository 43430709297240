import ellipse_60 from "assets/images/ellipse_60.svg";
import dashboardImage from "assets/images/dashboard-new.png";
import { motion } from "framer-motion";

import { useNavigate } from "react-router-dom";
import "./style.scss";
import {
  btnGroup,
  container,
  fadeInDown,
  fadeInLeft,
  fadeInRight,
  fadedText,
  letter as letterVariants,
} from "./animation-variants";
import { checkDevice } from "utils/helpers";
import { includes } from "lodash";

const TopBanner = () => {
  const navigate = useNavigate();
  const { isDesktop, isMobile } = checkDevice();
  const title =
    "Get Actionable, Transparent, & Unbiased Corporate Credit Research — Without the Noise.";
  return (
    <motion.div
      animate="animate"
      initial="initial"
      className="top-banner"
      variants={!isMobile ? container : undefined}
    >
      <img className="animation-ellipse" src={ellipse_60} alt="" />
      <img className="animation-ellipse-bottom" src={ellipse_60} alt="" />
      <div className="banner-content">
        {/* /Welcome to Bondvexity */}
        <motion.h2
          variants={!isMobile ? fadedText : undefined}
          initial="initial"
          animate="animate"
        >
          {title.split(" ").map((word, index) => (
            <motion.div
              key={index}
              style={{
                display: "inline-flex",
              }}
            >
              {word.split("").map((letter, index) => (
                <motion.span
                  style={{
                    color: includes(["Corporate", "Credit", "Research"], word)
                      ? "rgb(10 100 155)"
                      : undefined,
                  }}
                  key={index}
                  variants={!isMobile ? letterVariants : undefined}
                >
                  {letter}
                </motion.span>
              ))}
            </motion.div>
          ))}
        </motion.h2>
        <motion.h4
          style={{
            fontSize: 21,
          }}
          variants={!isMobile ? fadeInLeft : undefined}
        >
          Real-time recommendations on high-yield bonds and leveraged loans:
          built for the buy-side by the buy-side.
        </motion.h4>

        {/* <motion.p
          variants={!isMobile ? fadeInRight : undefined}
          className="
        "
        >
          Our seasoned investors deliver unparalleled insights into high-yield
          bonds and leveraged loans, providing you with the critical analysis
          and trade recommendations you need. Join our community to access
          premium credit research, market insights, and powerful tools designed
          to keep you ahead in the dynamic bond market. Sign up now to
          experience the Bondvexity advantage.
        </motion.p> */}
        <motion.button
          variants={!isMobile ? btnGroup : undefined}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="btn-get-started"
          style={{
            marginTop: 30,
          }}
          onClick={() => navigate("/sign-up")}
        >
          Get Started
        </motion.button>
      </div>
      <motion.div
        variants={!isMobile ? fadeInDown : undefined}
        className="dashboard"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 10,
          background: "#FFFFFF",
          boxShadow: "0px 0px 223.4px -20px rgba(3, 145, 231, 0.09)",
        }}
      >
        <img
          className="dashboard-image"
          src={dashboardImage}
          alt="dashboard"
          style={{
            borderBottomLeftRadius: 10,
            borderBottomRightRadius: 10,
          }}
        />
      </motion.div>
    </motion.div>
  );
};

export default TopBanner;
