import logo_icon_long from "assets/images/logo_header.svg";

import { useEffect } from "react";
import "./style.scss";

const HeaderAuth = () => {
  return (
    <a href="/" className="header-auth">
      <img src={logo_icon_long} alt="logo" />
    </a>
  );
};

const PublicLayout = ({ children }: any) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeaderAuth />
      <div className="children">{children}</div>
    </>
  );
};

export default PublicLayout;
