import { Checkbox, Col, Row } from "antd";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import authApi from "api/auth";
import FormInput from "components/UI/Form/FormInput";
import { httpRequestErrors } from "utils/errors";
import { emailRule, passwordRule } from "utils/rules";
import { ReactComponent as IconHelpCircle } from "assets/images/help_circle.svg";
import { ReactComponent as IconLock } from "assets/images/icon_lock.svg";
import logo from "assets/images/icon_logo_auth.svg";

type FormState = {
  email: string;
  password: string;
};

const SignInForm = () => {
  const navigate = useNavigate();
  const { control, handleSubmit } = useForm<FormState>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data: FormState) => {
    try {
      const { token } = await authApi.signIn(data);
      localStorage.setItem("access_token", token);
      navigate("/dashboard");
    } catch (error) {
      httpRequestErrors(error);
    }
  };

  return (
    <form className="auth-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-content">
        <img className="form-logo" src={logo} alt="" />

        <div className="form">
          <div>
            <h4>Welcome Back!</h4>
            <h2>Sign In</h2>
          </div>
          <div className="form-item">
            <Row>
              <Col span={24}>
                <FormInput
                  placeholder="you@company.com"
                  name="email"
                  control={control}
                  rules={emailRule}
                  label={"E-mail"}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormInput
                  control={control}
                  name="password"
                  rules={passwordRule}
                  label={"Password"}
                  type="password"
                  placeholder="************"
                  startDecorator={<IconLock />}
                  endDecorator={<IconHelpCircle />}
                />
              </Col>
            </Row>
          </div>

          <div className="remember-fogot">
            <Checkbox>Remember me</Checkbox>
            <span onClick={() => navigate("/forgot-password")}>
              Forgot password?
            </span>
          </div>
        </div>
        <div className="btn-container">
          <Row>
            <Col span={24}>
              <button className="btn-get-started" type="submit">
                Sign In
              </button>
            </Col>
          </Row>
          <div className="text-bottom">
            <p>
              Don't have an account?&nbsp;
              <span onClick={() => navigate("/sign-up")}>
                Create free account
              </span>
            </p>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SignInForm;
