import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./style.scss";

export type Props = {
  value: string;
  onChange: (newValue: string) => void;
  placeholder?: string;
  containerClass?: string;
};
const InputPhone = ({
  value,
  onChange,
  placeholder = "+1 (555) 000-0000",
  containerClass,
}: Props) => {
  return (
    <PhoneInput
      containerClass={`${containerClass} input-phone`}
      placeholder={placeholder}
      value={value}
      country={"us"}
      onChange={onChange}
    />
  );
};

export default InputPhone;
