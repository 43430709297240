import { CircularProgress } from "@mui/joy";
import { useSelector } from "react-redux";
const Loading = () => {
  const isLoading = useSelector((state) => state.user.isLoading);
  if (!isLoading) return null;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0,0,0,0.5)",
        zIndex: 1000000000,
      }}
    >
      <CircularProgress size="lg" />
    </div>
  );
};

export default Loading;
