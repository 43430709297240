import { isArray, isString } from "lodash";
import { toast } from "react-toastify";

export const httpRequestErrors = (errors) => {
  const errorResponse = errors.response?.data;
  if (isArray(errorResponse?.errors) && errorResponse?.errors?.length) {
    errorResponse.errors.forEach((error) => {
      toast.error(error);
    });

    return;
  }

  if (errorResponse?.message) {
    if (isArray(errorResponse.message)) {
      return toast.error(errorResponse.message[0]);
    }
    return toast.error(errorResponse.message);
  }

  if (isString(errorResponse?.errors)) {
    toast.error(errorResponse.errors);
    return;
  }

  toast.error("Something went wrong!");
};
