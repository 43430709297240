import { Col } from "antd";
import { LayoutContent } from "components/Layout/LayoutContent";
import ComingSoon from "pages/ComingSoon";
import { useLocation } from "react-router-dom";
import { Path } from "routers/route";
import { checkDevice } from "utils/helpers";

const ComingSoonPage = () => {
  const location = useLocation();
  const { isDesktop } = checkDevice();
  const pathName = location.pathname;
  let title = "";
  switch (pathName) {
    case Path.Events:
      title = "EVENTS";
      break;
    case Path.News:
      title = "NEWS";
      break;
    case Path.Resources:
      title = "RESOURCES";
      break;
    case Path.Webinars:
      title = "WEBINARS";
      break;
    case Path.Podcasts:
      title = "PODCASTS";
      break;
    case Path.TopPicks:
      title = "TOP PICKS";
      break;
    case Path.Calendar:
      title = "CALENDAR";
      break;
    case Path.WeeklyRV:
      title = "WEEKLY RELATIVE VALUE";
      break;
  }
  return (
    <LayoutContent
      title={title}
      header={<div />}
      styleTitle={
        isDesktop
          ? undefined
          : {
              height: 40,
              display: "flex",
              alignItems: "center",
            }
      }
    >
      <Col
        style={{
          marginTop: 16,
          marginBottom: 12,
          paddingRight: !isDesktop ? 20 : undefined,
        }}
      >
        <ComingSoon />
      </Col>
    </LayoutContent>
  );
};

export default ComingSoonPage;
