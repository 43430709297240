import { RootState } from "libs/store";
import { isNumber } from "lodash";
import { useSelector } from "react-redux";

export enum PermissionKey {
  EDIT_FEED_DASHBOARD,
  DELETE_FEED_DASHBOARD,
  VIEW_FEED_DASHBOARD,
  VIEW_FEED,
  ADD_FEED,
  EDIT_FEED,
  DELETE_FEED,
  VIEW_COMPANY,
  ADD_COMPANY,
  EDIT_COMPANY,
  DELETE_COMPANY,
  UPDATE_COMPANY_SUMMARY,
  UPDATE_COMPANY_CAPITAL_STRUCTURE,
  UPDATE_COMPANY_FINANCIAL,
  UPDATE_COMPANY_ORG_CHART,
}

export const useCheckPermission = (permissions: number[] | number) => {
  const user = useSelector((state: RootState) => state.user.user);
  const userPermissions = user.permissions as {
    key: number;
    id: string;
  }[];
  const permissionKeys = userPermissions.map((permission) => permission.key);
  if (isNumber(permissions)) {
    return permissionKeys.includes(permissions);
  }

  return permissionKeys.some((permission) => permissions.includes(permission));
};
