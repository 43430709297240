import { motion } from "framer-motion";
import Footer from "components/Global/Footer";
import Header from "components/Global/Header";
import FormJoin from "components/Pages/LandingPage/FormJoin";
import KeyFeatures from "components/Pages/LandingPage/KeyFeatures";
import TopBanner from "components/Pages/LandingPage/TopBanner";
import WhyChoose from "components/Pages/LandingPage/WhyChoose";

const HomePage = () => {
  return (
    <motion.div initial="initial" animate="animate" className="main-content">
      <Header />
      <TopBanner />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <WhyChoose />
        <KeyFeatures />
      </div>
      <FormJoin />
      <Footer />
    </motion.div>
  );
};

export default HomePage;
