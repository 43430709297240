import React, { useState } from "react";
import { MenuItem, itemsMenu } from "../../../common";
import { Dropdown } from "antd";
import { useNavigate } from "react-router-dom";

const Navigation = () => {
  const [current, setCurrent] = useState<string>("");
  const navigate = useNavigate();
  const handleClick = (e: MenuItem) => {
    setCurrent(e.key);
    navigate(`/${e.key}`);
  };

  return (
    <div className="navigation">
      <div className="navigtion-container">
        {itemsMenu.map((item, index) => {
          return item.subItems ? (
            <Dropdown
              menu={{
                items: item.subItems.map((subItem) => {
                  return {
                    label: (
                      <p
                        key={subItem.key}
                        onClick={() => handleClick(item)}
                        className="sub-menu-text"
                      >
                        {subItem.label}
                      </p>
                    ),
                    key: subItem.key,
                  };
                }),
              }}
            >
              <p
                key={index}
                onClick={() => handleClick(item)}
                className={`${current === item.key && "menu-active"}`}
              >
                {item.label}
              </p>
            </Dropdown>
          ) : (
            <p
              key={index}
              onClick={() => handleClick(item)}
              className={`${current === item.key && "menu-active"}`}
            >
              {item.label}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default Navigation;
