import axiosApiInstance from "utils/axiosClient";

const uploadFile = async (
  file: File,
  path: string,
  callBack: (newValue: boolean) => void
) => {
  callBack(true);
  const { data: presignedUrl } = await axiosApiInstance.post("/presigned-url", {
    fileName: `${path}/${file.name}`,
    fileType: file.type,
  });

  await fetch(presignedUrl, {
    headers: {
      "Content-Type": file.type,
    },
    body: file,
    method: "PUT",
  });
  callBack(false);
  return `${path}/${file.name}`;
};

const fileApi = {
  upload: uploadFile,
  uploadFiles: async (
    files: File[],
    path: string,
    callBack: (newValue: boolean) => void
  ) => {
    for (const file of files) {
      await uploadFile(file, path, callBack);
    }
  },
  coverFileToHtml: async (formData: FormData) => {
    const res = await axiosApiInstance.post(`/presigned-file`, formData);
    return res.data;
  },
};

export default fileApi;
