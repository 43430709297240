import { Option, Select, Switch } from "@mui/joy";
import icon_select from "../../../../assets/images/icon_select.svg";
import { Col, Row, Tooltip } from "antd";
import { TIMEZONES } from "constants/notifications";
import RealTimeAlert from "./email-notification/RealtimeAlert";
import Digest from "./email-notification/Digest";

import styles from "./EmailNotification.module.scss";
import { useCallback, useEffect, useState } from "react";
import userApi from "api/user";
import { toast } from "react-toastify";
import { RootState, store } from "libs/store";
import { useSelector } from "react-redux";
import { setUser } from "features/userSlice";

const EmailNotification = () => {
  const [timezones, setTimezones] = useState<string[]>([]);
  const { timezone: userTz, ...restUser } = useSelector(
    (state: RootState) => state.user.user
  );

  const [timezone, setTimezone] = useState<string>(userTz);

  useEffect(() => {
    setTimezone(userTz);
  }, [userTz]);

  const handleChangeTimezone = async (value: string) => {
    if (!value || value === timezone) return;
    try {
      await userApi.updateTimezone(value);
      toast.success("Timezone updated");
      setTimezone(value);
      store.dispatch(setUser({ ...restUser, timezone: value }));
    } catch (error) {
      toast.error("Failed to update timezone");
    }
  };

  const getTimezones = useCallback(async () => {
    const dataRes: string[] = await userApi.getTimezones();
    setTimezones(dataRes ?? []);
  }, []);

  useEffect(() => {
    getTimezones();
  }, [getTimezones]);

  return (
    <div>
      <div className={styles["setting-item"]}>
        <div className={styles["setting-description"]}>
          <h5>Email Notifications</h5>
          <span className={styles["subtitle"]}>
            {" "}
            Get email alerts for the topic that you care about, at a time that
            suits you.
          </span>
        </div>
      </div>
      {/* Daily Email Summary */}
      {/* <Row
        gutter={[24, 24]}
        className={styles["setting-item"]}
        style={{
          justifyContent: "space-between",
        }}
      >
        <Col className={styles["setting-description"]} md={24}>
          <div className={styles["setting-description__header"]}>
            <h5>Today's Research - Daily Email Summary</h5>
            <Tooltip title="Turn on/off this notification">
              <Switch />
            </Tooltip>
          </div>
          <span className={styles["subtitle"]}>
            We will send a daily summary of all research posted on the platform.
          </span>
        </Col>
      </Row> */}
      {/* Weekly summary */}
      {/* <Row
        gutter={[24, 24]}
        className={styles["setting-item"]}
        style={{
          justifyContent: "space-between",
        }}
      >
        <Col className={styles["setting-description"]} md={24}>
          <div className={styles["setting-description__header"]}>
            <h5>Weekly Summary</h5>
            <Tooltip title="Turn on/off this notification">
              <Switch />
            </Tooltip>
          </div>
          <span className={styles["subtitle"]}>
            We will send a weekly overview of the latest research.
          </span>
        </Col>
      </Row> */}
      {/* Real-time alerts */}
      <RealTimeAlert />
      {/* Digests */}
      <Digest />
      {/* Timezone */}
      <div className={styles["setting-item"]}>
        <div className={styles["setting-description"]}>
          <h5>Time zones</h5>
          <span className={styles["subtitle"]}>
            We will use your time zone to ensure that your alerts are sent at
            the correct time of day.
          </span>
        </div>
        <Select
          onChange={(e, value) => {
            handleChangeTimezone(value as string);
          }}
          value={timezone}
          indicator={<img src={icon_select} alt="" />}
        >
          {timezones.map((timezone) => (
            <Option key={timezone} value={timezone}>
              {timezone}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default EmailNotification;
