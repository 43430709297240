import { createSlice } from "@reduxjs/toolkit";
import { store } from "libs/store";
import { debounce } from "utils/helpers";
export const AuthState = {
  LOGIN: "LOGIN",
  TWO_FACTOR_AUTH: "TWO_FACTOR_AUTH",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  NEW_PASSWORD: "NEW_PASSWORD",
  SIGN_UP: "SIGN_UP",
  VERIFY_EMAIL: "VERIFY_EMAIL",
};
const defaultUser = {
  avatar: "",
  companyName: "",
  email: "",
  firstName: "",
  id: "",
  lastName: "",
  phone: "",
  userRole: 0,
  userRoleName: "Member",
  aboutUsIds: [],
  company: {
    id: "",
    name: "",
    regions: [],
    sectors: [],
    description: "",
  },
  permissions: [],
  timezone: "",
};

const initialState = {
  user: defaultUser,
  authState: AuthState.LOGIN,
  prevAuthState: null,
  isLoading: false,
  showAPIWarning: true,
  sectors: [],
  timezone: ""
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    login: (state, action) => {
      state.user = action.payload;
    },
    setUser: (state, action) => {
      state.user = Object.assign(state.user || {}, action.payload);
    },
    setAuthState: (state, action) => {
      if (state.authState) {
        state.prevAuthState = state.authState;
      }
      state.authState = action.payload;
    },
    logout: (state) => {
      localStorage.clear();
      state.user = null;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setShowAPIWarning: (state, action) => {
      state.showAPIWarning = action.payload;
    },
    setSectors: (state, action) => {
      state.sectors = action.payload;
    },
  },
});

export const {
  login,
  logout,
  setAuthState,
  setUser,
  setIsLoading,
  setShowAPIWarning,
  setSectors,
} = userSlice.actions;

export const closeLoading = debounce(() => {
  store.dispatch(setIsLoading(false));
}, 500);
export default userSlice.reducer;
