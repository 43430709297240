import { Col, Row } from "antd";
import authApi from "api/auth";
import FormInput from "components/UI/Form/FormInput";
import { useForm } from "react-hook-form";
import { passwordRule } from "utils/rules";
import { ReactComponent as IconHelpCircle } from "assets/images/help_circle.svg";
import { ReactComponent as IconLock } from "assets/images/icon_lock.svg";
import { toast } from "react-toastify";
import { httpRequestErrors } from "utils/errors";
const ChangePassword = () => {
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      Oldpassword: "",
      password: "",
      confirmedPassword: "",
    },
  });
  const onSubmit = async (values: any) => {
    try {
      await authApi.changePassword(values);
      toast.success("Password changed successfully");
    } catch (error: any) {
      httpRequestErrors(error);
    }
  };

  const password = watch("password");

  return (
    <form
      className="auth-form change-password-form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="form-content">
        <div className="form">
          <h5> {"Change password"}</h5>
          <div className="form-item">
            <Row>
              <Col span={24}>
                <FormInput
                  control={control}
                  name="oldPassword"
                  rules={passwordRule}
                  type="password"
                  placeholder="************"
                  startDecorator={<IconHelpCircle />}
                  endDecorator={<IconLock />}
                  label={"Password"}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormInput
                  control={control}
                  name="password"
                  rules={passwordRule}
                  type="password"
                  placeholder="************"
                  startDecorator={<IconHelpCircle />}
                  endDecorator={<IconLock />}
                  label={"New Password"}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <FormInput
                  control={control}
                  name="confirmedPassword"
                  rules={{
                    required: "Confirm password is required",
                    validate: (value: string) =>
                      value === password || "The new passwords do not match",
                  }}
                  type="password"
                  placeholder="************"
                  startDecorator={<IconHelpCircle />}
                  endDecorator={<IconLock />}
                  label={"Confirm New Password"}
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="btn-container">
          <Row>
            <Col span={24}>
              <button className="btn-get-started">{"Change password"}</button>
            </Col>
          </Row>
        </div>
      </div>
    </form>
  );
};
export default ChangePassword;
