import { Button, Typography, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import NotFoundImg from "../assets/images/404.png";

const { Title, Text } = Typography;

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <Row justify="center" align="middle" style={{ height: "100vh" }}>
      <Col>
        <img src={NotFoundImg} alt="" width={500} />
        <Title level={1} style={{ marginTop: 20 }}>
          Something went wrong.
        </Title>
        <Text>Sorry, We can't find the page you're looking for.</Text>
        <Button
          type="primary"
          onClick={() => {
            navigate("/");
          }}
          style={{ marginTop: 10 }}
        >
          Go back to Home
        </Button>
      </Col>
    </Row>
  );
}
