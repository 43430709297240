import { RecentResearchItem } from "types/research";
import styles from "./ResearchView.module.scss";
import { checkDevice, formatDate, getMinuteRead } from "utils/helpers";
import { useNavigate } from "react-router-dom";

interface Props {
  recentResearch: RecentResearchItem[];
  type: number;
}
export const RecentResearchCard = ({ recentResearch, type }: Props) => {
  const navigate = useNavigate();
  const { isDesktop, isMobile } = checkDevice();

  return (
    <div className={styles["recent-research"]}>
      <p className={styles["header-title"]}>{`Recent Research`}</p>
      {recentResearch.map((item, index) => {
        return (
          <div
            key={item.id}
            className={styles["item"]}
            style={{
              marginBottom: 8,
              // backgroundColor:
              // index % 2 !== 0 ? "rgb(0 145 234 / 10%)" : "#FFFFFF",
            }}
            onClick={() => {
              navigate(`/research-view/${item.id}`);
            }}
          >
            <div className={styles["content"]}>
              <p
                className={styles["label"]}
                style={{
                  whiteSpace: isDesktop ? "nowrap" : "normal",
                }}
              >
                {item.name}{" "}
                <span
                  style={{
                    color: "#667085",
                    fontWeight: 400,
                  }}
                >
                  {item.summary || ""}
                </span>
              </p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <p className={styles["date"]}>{formatDate(item.createdAt)}</p>
                <p className={styles["type"]}>
                  {getMinuteRead(item.content || "")}
                </p>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              style={{
                minWidth: 24,
              }}
            >
              <mask
                id="mask0_2208_8009"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="24"
                height="24"
              >
                <rect width="24" height="24" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_2208_8009)">
                <path
                  d="M6.29425 17.6443L5.25 16.6L15.0905 6.75H6.14425V5.25H17.6443V16.75H16.1443V7.80375L6.29425 17.6443Z"
                  fill="#667085"
                />
              </g>
            </svg>
          </div>
        );
      })}
    </div>
  );
};
