import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import PublicLayout from "components/Layout/PublicLayout";

const PublicRoute = ({ children, isRaw = false }: any) => {
  const auth = useAuth();

  if (!auth.isAuthenticated()) {
    return isRaw ? children : <PublicLayout>{children}</PublicLayout>;
  }
  return <Navigate to="/settings" />;
};

export default PublicRoute;
