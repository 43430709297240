import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
} from "@mui/joy";
import Button from "components/UI/Button/Button";
import { useState } from "react";
import { Alert, Selected } from "types/alert-settings";
import { Sector } from "types/company";
import SelectSection from "./SelectSection";

interface RealTimeAlertFormModalProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (
    companies: { id: string; name: string }[],
    topics: { id: string; name: string }[],
    id?: string
  ) => void;
  value?: Alert;
}

const RealTimeAlertFormModal = ({
  value,
  open,
  onClose,
  onSubmit,
}: RealTimeAlertFormModalProps) => {
  const [selectedCompanies, setSelectedCompanies] = useState<Selected[]>(
    value?.companies ?? []
  );
  const [selectedTopics, setSelectedTopics] = useState<Selected[]>(
    value?.topics ?? []
  );

  const handleSelectTopic = (topic: Sector) => {
    if (selectedTopics.find((t) => t.id === topic.id)) {
      setSelectedTopics((prev) => prev.filter((t) => t.id !== topic.id));
    } else {
      setSelectedTopics((prev) => [...prev, topic]);
    }
  };
  const handleSelectCompany = (company: Selected) => {
    if (selectedCompanies.find((c) => c.id === company.id)) {
      setSelectedCompanies((prev) => prev.filter((c) => c.id !== company.id));
    } else {
      setSelectedCompanies((prev) => [...prev, company]);
    }
  };
  const resetSelect = () => {
    // setSelectedCompanies([]);
    // setSelectedTopics([]);
  };

  const handleSubmit = () => {
    onSubmit(
      selectedCompanies.map((c) => ({ id: c.id, name: c.name })),
      selectedTopics.map((t) => ({ id: t.id, name: t.name })),
      value?.id
    );
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{
        background: "#162f3f80",
        "& .MuiModalDialog-root": {
          width: 800,
          backgroundColor: "white",
        },
        "& .MuiModal-backdrop": {
          backdropFilter: "none",
        },
      }}
    >
      <ModalDialog variant="outlined">
        <DialogTitle>Create Real-Time Alert</DialogTitle>
        <Divider />
        <DialogContent>
          <SelectSection
            handleSelectCompany={handleSelectCompany}
            handleSelectTopic={handleSelectTopic}
            resetSelect={resetSelect}
            selectedCompanies={selectedCompanies}
            selectedTopics={selectedTopics}
          />
        </DialogContent>
        <DialogActions>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              gap: 16,
            }}
          >
            <Button
              onClick={onClose}
              variant="outlined"
              style={{
                width: "100%",
                height: 44,
                borderRadius: 30,
              }}
            >
              Cancel
            </Button>
            <Button
              style={{
                width: "100%",
                height: 44,
                borderRadius: 30,
              }}
              variant="solid"
              color="primary"
              disabled={!selectedCompanies.length && !selectedTopics.length}
              onClick={handleSubmit}
            >
              {value ? "Update" : "Create"}
            </Button>
          </div>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

export default RealTimeAlertFormModal;
