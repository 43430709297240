import { User } from "types/user";
import axiosApiInstance from "../utils/axiosClient";
import { QueryState } from "types/base";

const userApi = {
  getMe: async () => {
    const res = await axiosApiInstance.get("/user/me");
    const { data } = res;
    return {
      ...data,
      company: data?.company
        ? {
            ...data.company,
            regions: data.company.regions?.map(
              (region: { id: string; name: string }) => region.id
            ),
            sectors: data.company.sectors?.map(
              (sector: { id: string; name: string }) => sector.id
            ),
          }
        : null,
    };
  },
  update: async (data: Partial<User>) => {
    const res = await axiosApiInstance.patch("/auth/update-profile", data);
    return res.data;
  },
  getList: async (params?: QueryState) => {
    const res = await axiosApiInstance.get("/user", {
      params,
    });
    return res.data;
  },
  create: async (bodyRequest: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    roleKey: number;
  }) => {
    await axiosApiInstance.post(`/user`, bodyRequest);
  },
  updateUser: async (
    userId: string,
    bodyRequest: {
      firstName: string;
      lastName: string;
      phone: string;
      email: string;
      roleKey: number;
    }
  ) => {
    await axiosApiInstance.patch(`/user/${userId}`, bodyRequest);
  },
  delete: async (userId: string) => {
    await axiosApiInstance.delete(`/user/${userId}`);
  },
  getTimezones: async () => {
    const res = await axiosApiInstance.get("/user/timezones");
    return res.data;
  },
  updateTimezone: async (timezone: string) => {
    await axiosApiInstance.put("/user/me", { timezone });
  },
};

export default userApi;
