export const FeedBlockType = {
  TEXT: 0,
  FILE: 1,
  QUOTE: 2,
  CODE_BLOCK: 3,
};

export const FeedType = {
  PERSONAL: 0,
  GENERAL: 1,
};

export const CONTENT_TYPE = ["Research", "News"];

export const LIST_CONTENT_TYPE = [
  { name: "Research", value: 0 },
  { name: "News", value: 1 },
  { name: "Market", value: 2 },
  { name: "Sector Intel", value: 3 },
  { name: "Expert Call", value: 4 },
  { name: "Earnings", value: 5 },
  { name: "Covenants", value: 6 },
  { name: "Conference", value: 7 },
  { name: "Initiation of Coverage", value: 8 },
  { name: "Rel Val", value: 9 },
  { name: "Other", value: 10 },
];
