import { QueryState } from "types/base";
import axiosApiInstance from "../utils/axiosClient";
import { FileTargetType } from "types/file";

const companyApi = {
  getList: async (params?: QueryState) => {
    const res = await axiosApiInstance.get("/companies", {
      params,
    });
    return res.data;
  },
  get: async (id: string) => {
    const res = await axiosApiInstance.get(`/companies/${id}`);
    return res.data;
  },
  create: async (bodyRequest: {
    name: string;
    creditRiskId: string;
    sectorId: string;
    bondVexityViewId: string;
  }) => {
    await axiosApiInstance.post(`/companies`, bodyRequest);
  },
  upload: async (
    companyId: string,
    bodyRequest: {
      name: string;
      creditRiskId: string;
      sectorId: string;
      bondVexityViewId: string;
    }
  ) => {
    await axiosApiInstance.patch(`/companies/${companyId}`, bodyRequest);
  },
  delete: async (companyId: string) => {
    await axiosApiInstance.delete(`/companies/${companyId}`);
  },
  checkDelete: async (companyId: string) => {
    const res = await axiosApiInstance.post(
      `/companies/${companyId}/check-delete`
    );
    return res.data;
  },
  getImageTab: async (id: string, tab: FileTargetType) => {
    const res = await axiosApiInstance.get(`/companies/${id}/${tab}`);
    return res.data;
  },
  postImageTab: async (
    id: string,
    bodyRequest: {
      fileName: string;
      fileType: string;
      tab: FileTargetType;
    }
  ) => {
    const res = await axiosApiInstance.post(
      `/companies/${id}/update-image-tab`,
      bodyRequest
    );
    return res.data;
  },
  deleteImageTab: async (id: string, tab: FileTargetType) => {
    const res = await axiosApiInstance.delete(`/companies/${id}/${tab}`);
    return res.data;
  },
};
export default companyApi;
