import { Row } from "antd";
import { ReactComponent as LockIcon } from "assets/images/icon-lock.svg";
import styles from "./RestrictUpload.module.scss";
import { Link } from "react-router-dom";
const RestrictUpload = () => {
  return (
    <Row className={styles["restrict-upload"]}>
      <LockIcon />
      <p className={styles["title"]}>
        You do not have permission to upload image
      </p>
      <p className={styles["description"]}>
        You currently do not have permission to upload or edit images on this
        platform. This could be due to your current user role or settings. If
        you need to upload images or believe this restriction is in error,
        please contact your administrator or review your account permissions
      </p>
      <Link className={styles["btn"]} to="/dashboard">
        Go back to Dashboard
      </Link>
    </Row>
  );
};

export default RestrictUpload;
