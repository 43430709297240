import configApi from "api/config";
import { ResearchFilter } from "pages/cms/company/context/CompanyContext";
import { useCallback, useEffect, useState } from "react";
import { debounce, includes, orderBy } from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "libs/store";

const useResearchFilter = (keyColumns: string[]) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [filter, setFilterState] = useState<ResearchFilter>({
    sectorIds: [],
    contentType: [],
    dateRange: [null, null],
    limit: 10,
    page: 1,
    companyIds: [],
    search: "",
    orderBy: "createdAt",
    orderDirection: "DESC",
  });

  const [search, setSearch] = useState("");
  const [total, setTotal] = useState(0);
  const [companyOptions, setCompanyOptions] = useState<
    { id: string; name: string }[]
  >([]);
  const [sectorOptions, setSectorOptions] = useState<
    { id: string; name: string }[]
  >([]);

  const sectors = useSelector((state: RootState) => state.user.sectors);

  useEffect(() => {
    const getOptions = async () => {
      if (includes(keyColumns, "company")) {
        const companies = await configApi.companies();
        setCompanyOptions(
          [{ id: "no-company", name: "No Company" }].concat(
            orderBy(companies || [], "name", "asc")
          )
        );
      }
    };

    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(keyColumns)]);

  useEffect(() => {
    if (sectors && sectors.length) {
      setSectorOptions(
        [{ id: "no-sector", name: "No Sector" }].concat(sectors || [])
      );
    }
  }, [sectors]);

  const setFilter = (value: Partial<ResearchFilter>) => {
    setFilterState((prev) => ({ ...prev, ...value }));
  };

  const closeFilter = () => {
    setOpenFilter(false);
  };
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value); // Update the search state with the new input value
    debounceSearchValue(event.target.value);
  };

  const debounceSearchValue = useCallback(
    debounce((newSearch: string) => {
      const newFilter = {
        ...filter,
        search: newSearch,
      };
      setFilterState(newFilter);
    }, 400),
    [filter]
  );

  return {
    openFilter,
    setOpenFilter,
    filter,
    setFilter,
    sectorOptions,
    closeFilter,
    setFilterState,
    handleInputChange,
    search,
    total,
    setTotal,
    companyOptions,
  };
};
export default useResearchFilter;
