import AuthContent from "../components/AuthContent";
import "../style.scss";
import SignUpForm from "./components/SignUpForm";

const SignUp = () => {
  return (
    <div className="auth-container">
      <div className="auth-left">
        <AuthContent
          title={
            "Join Bondvexity Today Unlock Unmatched Corporate Credit Insights"
          }
          text={
            "Welcome to Bondvexity, where professional enterprise customers gain access to expert, unbiased research on high-yield and leveraged loan issuers. Join our platform to enhance your investment strategies with comprehensive analysis, actionable recommendations, and advanced analytics tools."
          }
        />
      </div>
      <div className="auth-right">
        <SignUpForm />
      </div>
    </div>
  );
};

export default SignUp;
