import { Avatar, Col, Drawer, Row } from "antd";
import configApi from "api/config";
import { ReactComponent as LogoShortIcon } from "assets/images/logo_icon.svg";
import { setSectors, setUser } from "features/userSlice";
import { RootState, store } from "libs/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Path } from "routers/route";
import { getFirstAndLastInitials } from "utils/helpers";
import { MENU } from "./menu";
import styles from "./Sidebar.module.scss";
import { ReactComponent as IconSetting } from "assets/images/icon_setting.svg";
import { ReactComponent as ChevronDown } from "assets/images/chevron-down.svg";
import { SearchGlobal } from "components/Global/Form/SearchGlobal";

export const SidebarMobile = () => {
  const user = useSelector((state: RootState) => state.user.user);
  const { dispatch } = store;
  const location = useLocation();
  const navigate = useNavigate();
  const link = location.pathname;
  const menuActive = localStorage.getItem("menu_active") || "dashboard";
  const menuChildActive = localStorage.getItem("menu_child_active") || "";
  const menuLocalChildCollapse =
    localStorage.getItem("menu_child_collapse") || "";

  const [isExpand, setIsExpand] = useState(false);
  const [listMenu, setListMenu] = useState<any>([]);
  const [menuChildCollapse, setMenuChildCollapse] = useState<string>("");

  useEffect(() => {
    if (link.startsWith("/sector/")) {
      localStorage.setItem("menu_active", "sector");
      localStorage.setItem("menu_child_active", link.replace("/sector/", ""));
      localStorage.setItem("menu_child_collapse", "sector");
      return;
    }
    const pathToMenuMapping: any = {
      [Path.Dashboard]: { menuActive: "dashboard", menuChildActive: "" },
      [Path.Companies]: { menuActive: "company", menuChildActive: "" },
      [Path.Market]: { menuActive: "market", menuChildActive: "" },
      [Path.Sector]: { menuActive: "sector", menuChildActive: "" },
      [Path.TopPicks]: { menuActive: "top-picks", menuChildActive: "" },
      [Path.Calendar]: { menuActive: "calendar", menuChildActive: "" },
      [Path.SavedContent]: {
        menuActive: "saved-content",
        menuChildActive: "",
      },
      [Path.Events]: { menuActive: "", menuChildActive: "events" },
      [Path.News]: { menuActive: "", menuChildActive: "news" },
      [Path.Resources]: { menuActive: "", menuChildActive: "resources" },
      [Path.Webinars]: { menuActive: "", menuChildActive: "webinars" },
      [Path.Podcasts]: { menuActive: "", menuChildActive: "podcasts" },
    };

    const { menuActive, menuChildActive } = pathToMenuMapping[link] || {
      menuActive: "",
      menuChildActive: "",
    };

    if (menuActive) {
      localStorage.setItem("menu_active", menuActive);
      localStorage.setItem("menu_child_active", "");
      localStorage.setItem("menu_child_collapse", "");
    }

    if (menuChildActive) {
      localStorage.setItem("menu_active", "more");
      localStorage.setItem("menu_child_active", menuChildActive);
      localStorage.setItem("menu_child_collapse", "more");
    }
  }, [link]);

  useEffect(() => {
    if (!menuLocalChildCollapse) {
      return;
    }
    setMenuChildCollapse(menuLocalChildCollapse);
  }, [menuLocalChildCollapse]);

  useEffect(() => {
    if (!user) {
      return;
    }
    getListMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getListMenu = async () => {
    const sectorResults = await configApi.sectors();
    dispatch(setSectors(sectorResults));
    const newMenus =
      user.userRole === 0 ? MENU : MENU.filter((item) => item.id !== "users");
    const newListMenu = newMenus.map((menuItem) => {
      return {
        ...menuItem,
        children:
          menuItem.id === "sector" && sectorResults?.length
            ? sectorResults.map((sector: any) => {
                return {
                  id: sector.id,
                  name: sector.name,
                  path: Path.Sector.replace(":sectorId", sector.id),
                };
              })
            : menuItem.children,
      };
    });
    setListMenu(newListMenu);
  };

  const handleChangeExpand = () => {
    setIsExpand((pre) => !pre);
  };

  const renderExpand = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {isExpand ? (
          <svg
            onClick={handleChangeExpand}
            className="expand"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M22.7882 22.7883L16 16M16 16L9.21177 9.21181M16 16L22.7882 9.21181M16 16L9.21177 22.7883"
              stroke="#667085"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        ) : (
          <svg
            onClick={handleChangeExpand}
            className="expand"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.66699 9.33333C6.66699 8.97971 6.80747 8.64057 7.05752 8.39052C7.30756 8.14048 7.6467 8 8.00033 8H24.0003C24.3539 8 24.6931 8.14048 24.9431 8.39052C25.1932 8.64057 25.3337 8.97971 25.3337 9.33333C25.3337 9.68696 25.1932 10.0261 24.9431 10.2761C24.6931 10.5262 24.3539 10.6667 24.0003 10.6667H8.00033C7.6467 10.6667 7.30756 10.5262 7.05752 10.2761C6.80747 10.0261 6.66699 9.68696 6.66699 9.33333ZM6.66699 16C6.66699 15.6464 6.80747 15.3072 7.05752 15.0572C7.30756 14.8071 7.6467 14.6667 8.00033 14.6667H24.0003C24.3539 14.6667 24.6931 14.8071 24.9431 15.0572C25.1932 15.3072 25.3337 15.6464 25.3337 16C25.3337 16.3536 25.1932 16.6928 24.9431 16.9428C24.6931 17.1929 24.3539 17.3333 24.0003 17.3333H8.00033C7.6467 17.3333 7.30756 17.1929 7.05752 16.9428C6.80747 16.6928 6.66699 16.3536 6.66699 16ZM6.66699 22.6667C6.66699 22.313 6.80747 21.9739 7.05752 21.7239C7.30756 21.4738 7.6467 21.3333 8.00033 21.3333H24.0003C24.3539 21.3333 24.6931 21.4738 24.9431 21.7239C25.1932 21.9739 25.3337 22.313 25.3337 22.6667C25.3337 23.0203 25.1932 23.3594 24.9431 23.6095C24.6931 23.8595 24.3539 24 24.0003 24H8.00033C7.6467 24 7.30756 23.8595 7.05752 23.6095C6.80747 23.3594 6.66699 23.0203 6.66699 22.6667Z"
              fill="#667085"
            />
          </svg>
        )}
        <Link
          to={Path.Dashboard}
          onClick={() => {
            localStorage.setItem("menu_active", "dashboard");
            localStorage.setItem("menu_child_active", "");
            localStorage.setItem("menu_child_collapse", "");
            setMenuChildCollapse("");
          }}
        >
          <LogoShortIcon className="logo" />
        </Link>
      </div>
    );
  };
  return (
    <div className="header">
      {renderExpand()}
      <div
        style={{
          marginLeft: 20,
          flex: 1,
        }}
      >
        <SearchGlobal />
      </div>

      <Drawer
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="root-avatar">
              <Avatar
                src={user?.avatar}
                style={{
                  backgroundColor: "#0091EA",
                  color: "#fff",
                  width: 46,
                  height: 46,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: 16,
                  fontWeight: 700,
                }}
              >
                {getFirstAndLastInitials(user?.firstName, user?.lastName)}
              </Avatar>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p className="fullName">
                    {`${user?.firstName || ""} ${user.lastName || ""} `.trim()}
                  </p>
                  <p
                    style={{
                      width: "fit-content",
                      color: user?.userRole === 0 ? "#00bfa5" : "#0091EA",
                      textAlign: "right",
                      fontSize: 12,
                      fontWeight: 500,
                      lineHeight: "18px",
                      padding: "5px 16px",
                      borderRadius: 5,
                      background:
                        user?.userRole === 0
                          ? "rgba(0, 191, 165, 0.08)"
                          : "rgba(0, 145, 234, 0.08)",
                      marginLeft: 16,
                    }}
                  >
                    {user?.userRoleName}
                  </p>
                </div>

                <p className="email">{user?.email}</p>
              </div>
            </div>
            <Row
              style={{
                width: "100%",
                marginTop: 8,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Col
                span={12}
                style={{
                  paddingRight: 8,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: 36,
                    padding: "0px 16px",
                    cursor: "pointer",
                    borderRadius: 12,
                    gap: 5,
                    background: "#f9f9f9",
                  }}
                  onClick={() => {
                    navigate("/settings");
                    setIsExpand(false);
                  }}
                >
                  <IconSetting />
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 500,
                      lineHeight: "22px",
                      color: "#667085",
                    }}
                  >
                    Setting
                  </p>
                </div>
              </Col>
              <Col
                span={12}
                style={{
                  paddingLeft: 8,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: 36,
                    padding: "0px 16px",
                    cursor: "pointer",
                    borderRadius: 12,
                    gap: 5,
                    background: "#f9f9f9",
                  }}
                  onClick={() => {
                    setIsExpand(false);
                    localStorage.clear();
                    store.dispatch(
                      setUser({
                        id: "",
                        firstName: "",
                        lastName: "",
                        email: "",
                        avatar: "",
                        userRole: 2, //  default as MEMBER
                        userRoleName: "",
                        company: {
                          id: "",
                          name: "",
                          regions: [],
                          sectors: [],
                          description: "",
                        },
                      })
                    );

                    navigate("/");
                  }}
                >
                  <ChevronDown />
                  <p
                    style={{
                      color: "#E53935",
                      fontSize: 14,
                      fontWeight: 500,
                      lineHeight: "22px",
                    }}
                  >
                    Log out
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        }
        placement="left"
        className="drawer-mobile"
        open={isExpand}
        width="100%"
        rootClassName="root-drawer-mobile"
        rootStyle={{
          top: "54px !important",
        }}
        closeIcon={<div />}
      >
        <div
          className={styles["cms-menu-container"]}
          style={{
            height: "calc(100% - 150px)",
            overflow: "auto",
          }}
        >
          {listMenu.map((item: any) => {
            const isActive = menuActive === item.id;
            const isChildCollapse = menuChildCollapse === item.id;

            return (
              <div key={item.id}>
                <div
                  className={`${styles["menu-item"]} ${
                    isActive ? styles["menu-active"] : ""
                  }`}
                  style={{
                    marginLeft: isExpand ? 16 : 8,
                    marginRight: isExpand ? 16 : 8,
                  }}
                  onClick={() => {
                    if (item.children?.length) {
                      if (isExpand) {
                        setMenuChildCollapse(
                          menuChildCollapse === item.id ? "" : item.id
                        );
                      }

                      localStorage.setItem(
                        "menu_child_collapse",
                        menuChildCollapse === item.id ? "" : item.id
                      );

                      return;
                    }
                    localStorage.setItem("menu_active", item.id);
                    localStorage.setItem("menu_child_active", "");
                    localStorage.setItem("menu_child_collapse", "");
                    navigate(item.path);
                    setMenuChildCollapse("");
                    setIsExpand(false);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    {isActive ? item.iconActive : item.icon}
                    {isExpand && <p>{item.name}</p>}
                  </div>
                  {item.children?.length ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d={
                          isChildCollapse
                            ? "M5 12.5L10 7.5L15 12.5"
                            : "M5 7.5L10 12.5L15 7.5"
                        }
                        stroke={isActive ? "white" : "#667085"}
                        strokeWidth="1.66667"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : null}
                </div>

                {item.children?.length && isChildCollapse && isExpand ? (
                  <div
                    style={{
                      marginLeft: 33,
                      borderLeft: "1px solid #D0D5DD",
                      display: "flex",
                      flexDirection: "column",
                      marginTop: 8,
                      marginRight: 16,
                      marginBottom: 13,
                    }}
                  >
                    {item.children.map((child: any, indexChild: number) => {
                      return (
                        <div
                          key={indexChild}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginLeft: 8,
                            // marginBottom:
                            //   indexChild + 1 === item.children?.length ? 0 : 8,
                            height: 40,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            localStorage.setItem("menu_active", item.id);
                            localStorage.setItem("menu_child_active", child.id);
                            navigate(child.path);
                            setIsExpand(false);
                          }}
                        >
                          <p
                            style={{
                              fontWeight:
                                menuChildActive === child.id ? 600 : 400,
                              fontSize: 16,
                              color:
                                menuChildActive === child.id
                                  ? "#162F3F"
                                  : "#667085",
                            }}
                          >
                            {child.name}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </Drawer>
    </div>
  );
};
