import { Menu } from "antd";
import React from "react";
import "./style.scss";
import EmailNotification from "./EmailNotification";
import ChangePassword from "pages/cms/settings/components/ChangePassword";
import Profile from "./Profile";
interface SettingContentProps {
  tab: string;
  setTab: React.Dispatch<React.SetStateAction<string>>;
  items: { key: string; label: string }[];
}

const SettingContent = ({ tab, setTab, items }: SettingContentProps) => {
  return (
    <>
      <div className="menu-content">
        <Menu
          selectedKeys={[`${tab}`]}
          mode="inline"
          theme="light"
          items={items}
          onClick={({ key }) => {
            setTab(key);
          }}
        />
      </div>
      <div className="setting-content">
        {tab === "3" && <EmailNotification />}
        {tab === "4" && <ChangePassword />}
        {tab === "1" && <Profile />}
      </div>
    </>
  );
};

export default SettingContent;
