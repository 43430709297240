import axiosApiInstance from "../utils/axiosClient";

const searchGlobalApi = {
  getList: async (params?: {
    page: number;
    limit: number;
    search?: string;
  }) => {
    const res = await axiosApiInstance.get("/search-global", {
      params,
    });
    return res.data;
  },
};
export default searchGlobalApi;
