export const passwordRule = {
  required: "Password is required",
  minLength: {
    value: 8,
    message: "Password must have at least 8 characters",
  },
  maxLength: {
    value: 64,
    message: "Password must not exceed 64 characters",
  },
  pattern: {
    value:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,64}$/,
    message:
      "Password must include at least one uppercase letter, one lowercase letter, one digit, and one special character (e.g., @, $, !, %, *, ?, &, #)",
  },
};

export const emailRule = {
  required: "E-mail is required",
  pattern: {
    value:
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    message: "Entered value does not match email format",
  },
};

export const defaultRule = (name) => {
  return {
    required: `${name} is required`,
  };
};

export const phoneRule = {
  required: false,
  pattern: {
    value: /^\+?\d{10,}$/,
    message: "Invalid phone number",
  },
};
