import { Tooltip } from "antd";
import { Quill } from "react-quill";
import ImageResize from "quill-image-resize-module-react";

window.Quill = Quill;

Quill.register("modules/imageResize", ImageResize);
var Icons = Quill.import("ui/icons") as any;
Icons["bold"] = `<svg xmlns="http://www.w3.org/2000/svg" width="24"
 height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_626_5275" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_626_5275)">
<path d="M6.7998 19V5H12.3248C13.4081 5 14.4081 5.33333 15.3248 6C16.2415 6.66667 16.6998 7.59167 16.6998 8.775C16.6998 9.625 16.5081 10.2792 16.1248 10.7375C15.7415 11.1958 15.3831 11.525 15.0498 11.725C15.4665 11.9083 15.929 12.25 16.4373 12.75C16.9456 13.25 17.1998 14 17.1998 15C17.1998 16.4833 16.6581 17.5208 15.5748 18.1125C14.4915 18.7042 13.4748 19 12.5248 19H6.7998ZM9.82481 16.2H12.4248C13.2248 16.2 13.7123 15.9958 13.8873 15.5875C14.0623 15.1792 14.1498 14.8833 14.1498 14.7C14.1498 14.5167 14.0623 14.2208 13.8873 13.8125C13.7123 13.4042 13.1998 13.2 12.3498 13.2H9.82481V16.2ZM9.82481 10.5H12.1498C12.6998 10.5 13.0998 10.3583 13.3498 10.075C13.5998 9.79167 13.7248 9.475 13.7248 9.125C13.7248 8.725 13.5831 8.4 13.2998 8.15C13.0165 7.9 12.6498 7.775 12.1998 7.775H9.82481V10.5Z" fill="#B3B7C2"/>
</g>
</svg>`;

Icons[
  "italic"
] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_626_5276" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_626_5276)">
<path d="M5 19V16.5H9L12 7.5H8V5H18V7.5H14.5L11.5 16.5H15V19H5Z" fill="#B3B7C2"/>
</g>
</svg>`;

Icons[
  "underline"
] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_626_5277" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_626_5277)">
<path d="M5 21V19H19V21H5ZM12 17C10.3167 17 9.00833 16.475 8.075 15.425C7.14167 14.375 6.675 12.9833 6.675 11.25V3H9.25V11.4C9.25 12.3333 9.48333 13.0917 9.95 13.675C10.4167 14.2583 11.1 14.55 12 14.55C12.9 14.55 13.5833 14.2583 14.05 13.675C14.5167 13.0917 14.75 12.3333 14.75 11.4V3H17.325V11.25C17.325 12.9833 16.8583 14.375 15.925 15.425C14.9917 16.475 13.6833 17 12 17Z" fill="#B3B7C2"/>
</g>
</svg>`;

Icons.align[
  ""
] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_626_5282" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_626_5282)">
<path d="M3 21V19H21V21H3ZM3 17V15H15V17H3ZM3 13V11H21V13H3ZM3 9V7H15V9H3ZM3 5V3H21V5H3Z" fill="#B3B7C2"/>
</g>
</svg>`;

Icons.align.center = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_626_5283" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_626_5283)">
<path d="M3 21V19H21V21H3ZM7 17V15H17V17H7ZM3 13V11H21V13H3ZM7 9V7H17V9H7ZM3 5V3H21V5H3Z" fill="#B3B7C2"/>
</g>
</svg>`;

Icons.align.right = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_626_5284" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_626_5284)">
<path d="M3 5V3H21V5H3ZM9 9V7H21V9H9ZM3 13V11H21V13H3ZM9 17V15H21V17H9ZM3 21V19H21V21H3Z" fill="#B3B7C2"/>
</g>
</svg>`;

Icons.blockquote = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_608_7398" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_608_7398)">
<path d="M5.7 18L8 14C6.9 14 5.95833 13.6083 5.175 12.825C4.39167 12.0417 4 11.1 4 10C4 8.9 4.39167 7.95833 5.175 7.175C5.95833 6.39167 6.9 6 8 6C9.1 6 10.0417 6.39167 10.825 7.175C11.6083 7.95833 12 8.9 12 10C12 10.3833 11.9542 10.7375 11.8625 11.0625C11.7708 11.3875 11.6333 11.7 11.45 12L8 18H5.7ZM14.7 18L17 14C15.9 14 14.9583 13.6083 14.175 12.825C13.3917 12.0417 13 11.1 13 10C13 8.9 13.3917 7.95833 14.175 7.175C14.9583 6.39167 15.9 6 17 6C18.1 6 19.0417 6.39167 19.825 7.175C20.6083 7.95833 21 8.9 21 10C21 10.3833 20.9542 10.7375 20.8625 11.0625C20.7708 11.3875 20.6333 11.7 20.45 12L17 18H14.7Z" fill="#B3B7C2"/>
</g>
</svg>`;

Icons[
  "code-block"
] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_608_7414" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_608_7414)">
<path d="M8.6 15.6L5 12L8.6 8.4L10 9.825L7.825 12L10 14.175L8.6 15.6ZM15.4 15.6L14 14.175L16.175 12L14 9.825L15.4 8.4L19 12L15.4 15.6ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V15H5V19H9V21H5ZM15 21V19H19V15H21V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H15ZM3 9V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H9V5H5V9H3ZM19 9V5H15V3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V9H19Z" fill="#B3B7C2"/>
</g>
</svg>`;

Icons[
  "link"
] = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_626_5176" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_626_5176)">
<path d="M11 17H7C5.61667 17 4.4375 16.5125 3.4625 15.5375C2.4875 14.5625 2 13.3833 2 12C2 10.6167 2.4875 9.4375 3.4625 8.4625C4.4375 7.4875 5.61667 7 7 7H11V9H7C6.16667 9 5.45833 9.29167 4.875 9.875C4.29167 10.4583 4 11.1667 4 12C4 12.8333 4.29167 13.5417 4.875 14.125C5.45833 14.7083 6.16667 15 7 15H11V17ZM8 13V11H16V13H8ZM13 17V15H17C17.8333 15 18.5417 14.7083 19.125 14.125C19.7083 13.5417 20 12.8333 20 12C20 11.1667 19.7083 10.4583 19.125 9.875C18.5417 9.29167 17.8333 9 17 9H13V7H17C18.3833 7 19.5625 7.4875 20.5375 8.4625C21.5125 9.4375 22 10.6167 22 12C22 13.3833 21.5125 14.5625 20.5375 15.5375C19.5625 16.5125 18.3833 17 17 17H13Z" fill="#B3B7C2"/>
</g>
</svg>`;

// Modules object for setting up the Quill editor
export const modules = (props: string, readOnly?: boolean) => ({
  toolbar: {
    container: "#" + props,
  },
  imageResize: readOnly
    ? false
    : {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
});

// Quill Toolbar component
const QuillToolbar = (props: any) => {
  return (
    <div id={props.toolbarId}>
      <span className="ql-formats">
        <Tooltip placement="bottom" title="Bold">
          <button className="ql-bold"></button>
        </Tooltip>
        <Tooltip placement="bottom" title="Italic">
          <button className="ql-italic"></button>
        </Tooltip>
        <Tooltip placement="bottom" title="Underline">
          <button className="ql-underline"></button>
        </Tooltip>
      </span>
      <span className="ql-formats custom-header">
        <Tooltip placement="bottom" title="Header 1">
          <button className="ql-header" value="1"></button>
        </Tooltip>
        <Tooltip placement="bottom" title="Header 2">
          <button className="ql-header" value="2"></button>
        </Tooltip>
        <Tooltip placement="bottom" title="Header 3">
          <button className="ql-header" value="3">
            <span
              dangerouslySetInnerHTML={{
                __html: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<mask id="mask0_626_5164" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
<rect width="24" height="24" fill="#D9D9D9"/>
</mask>
<g mask="url(#mask0_626_5164)">
<path d="M2 19.2857V5H4.85714V10.7143H10.5714V5H13.4286V19.2857H10.5714V13.5714H4.85714V19.2857H2ZM16.2857 19.2857V17.1429H20.5714V15.2857H16.2857V13.4286H20.5714V11.5714H16.2857V9.42857H22V19.2857H16.2857Z" fill="#B3B7C2"/>
<path d="M16.2857 9.42857H20.5714V11.5714H16.2857V13.4286H20.5714V15.2857H16.2857V17.1429H20.5714V19.2857H16.2857V9.42857Z" fill="#B3B7C2"/>
</g>
</svg>`,
              }}
            />
          </button>
        </Tooltip>
      </span>
      <span className="ql-formats custom-align">
        <Tooltip placement="bottom" title="Align-left">
          <button type="button" className="ql-align" value=""></button>
        </Tooltip>
        <Tooltip placement="bottom" title="Align-center">
          <button type="button" className="ql-align" value="center"></button>
        </Tooltip>
        <Tooltip placement="bottom" title="Align-right">
          <button type="button" className="ql-align" value="right"></button>
        </Tooltip>
      </span>
      <span className="ql-formats custom-code">
        <Tooltip placement="bottom" title="Blockquote">
          <button className="ql-blockquote"></button>
        </Tooltip>
        <Tooltip placement="bottom" title="Code block">
          <button className="ql-code-block"></button>
        </Tooltip>
      </span>

      <span className="ql-formats custom-list">
        <Tooltip placement="bottom" title="Ordered">
          <button className="ql-list" value="ordered"></button>
        </Tooltip>
        <Tooltip placement="bottom" title="Bullet">
          <button className="ql-list" value="bullet"></button>
        </Tooltip>
        <Tooltip placement="bottom" title="Indent -1">
          <button className="ql-indent" value="-1"></button>
        </Tooltip>
        <Tooltip placement="bottom" title="Indent +1">
          <button className="ql-indent" value="+1"></button>
        </Tooltip>
      </span>

      <span className="ql-formats custom-color">
        <select className="ql-color"></select>
        <select className="ql-background"></select>
      </span>
      <span className="ql-formats custom-link">
        <Tooltip placement="bottom" title="Link">
          <button className="ql-link"></button>
        </Tooltip>
        <Tooltip placement="bottom" title="Add image">
          <button className="ql-image"></button>
        </Tooltip>
        <Tooltip placement="bottom" title="Upload file .doc, .docx, .pdf">
          <button className="ql-doc">
            <input
              key={Math.random()} // This ensures the input is re-rendered, resetting its value
              id="container-update-file-research"
              accept=".doc,.docx,.pdf"
              type="file"
              style={{ display: "none" }}
              onChange={props.handleFileUpload}
            />
            <label htmlFor="container-update-file-research">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                style={{
                  cursor: "pointer",
                }}
              >
                <mask id="mask0_608_7410" x="0" y="0" width="24" height="24">
                  <rect width="24" height="24" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_608_7410)">
                  <path
                    d="M11 20H6.5C4.98333 20 3.6875 19.475 2.6125 18.425C1.5375 17.375 1 16.0917 1 14.575C1 13.275 1.39167 12.1167 2.175 11.1C2.95833 10.0833 3.98333 9.43333 5.25 9.15C5.66667 7.61667 6.5 6.375 7.75 5.425C9 4.475 10.4167 4 12 4C13.95 4 15.6042 4.67917 16.9625 6.0375C18.3208 7.39583 19 9.05 19 11C20.15 11.1333 21.1042 11.6292 21.8625 12.4875C22.6208 13.3458 23 14.35 23 15.5C23 16.75 22.5625 17.8125 21.6875 18.6875C20.8125 19.5625 19.75 20 18.5 20H13V12.85L14.6 14.4L16 13L12 9L8 13L9.4 14.4L11 12.85V20Z"
                    fill="#B3B7C2"
                  />
                </g>
              </svg>
            </label>
          </button>
        </Tooltip>
      </span>
    </div>
  );
};
export default QuillToolbar;
