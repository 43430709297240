import { DatePicker } from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import Dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import styles from "./DateRangePicker.module.scss";
import "./styles.scss";
import { MENU_SELECT_DATE_RANGE } from "./constant";
import { checkDevice, getCustomDateRange } from "utils/helpers";
import Button from "../Button/Button";
import { compact } from "lodash";
interface Props extends Omit<RangePickerProps, "renderExtraFooter"> {
  value: [Dayjs.Dayjs | null, Dayjs.Dayjs | null];
}

const DateRangePicker = ({ onChange, value, ...restProps }: Props) => {
  const { isMobile } = checkDevice();

  const [selected, setSelected] = React.useState<string | null>(null);

  const [open, setOpen] = useState(false);
  const [customRange, setCustomRange] =
    useState<[Dayjs.Dayjs | null, Dayjs.Dayjs | null]>(value);

  useEffect(() => {
    setCustomRange(value);
  }, [value]);

  const onSubmit = () => {
    onChange?.(customRange, [
      customRange[0]?.format("YYYY-MM-DD") || "",
      customRange[1]?.format("YYYY-MM-DD") || "",
    ]);
    setOpen(false);
  };
  const onCancel = () => {
    setCustomRange(value);
    setOpen(false);
  };
  return (
    <div
      style={{
        position: "relative",
        minWidth: isMobile ? "100%" : "auto",
      }}
    >
      <DatePicker.RangePicker
        {...restProps}
        className={`custom-date-range-picker ${
          isMobile ? "custom-date-range-picker-mobile" : ""
        }`}
        onClick={() => setOpen(true)}
        panelRender={(panelNode) => {
          return (
            <div style={{ display: "flex" }}>
              <Sidebar
                value={value}
                onChange={onChange}
                onClose={() => {
                  setOpen(false);
                }}
                setCustomRange={setCustomRange}
                setSelected={setSelected}
                selected={selected}
              />
              <div>
                {panelNode}
                <Footer onSubmit={onSubmit} onCancel={onCancel} />
              </div>
            </div>
          );
        }}
        value={customRange}
        inputReadOnly={true}
        open={open}
        onFocus={() => {
          setOpen(true);
        }}
        onBlur={onCancel}
        onCalendarChange={() => {
          setSelected("custom");
        }}
        clearIcon={null}
        onChange={(dates) => {
          setCustomRange([dates ? dates[0] : null, dates ? dates[1] : null]);
        }}
      />
      {Boolean(compact(customRange)?.length) && !open ? (
        <svg
          onClick={() => {
            onChange?.([null, null], ["", ""]);
          }}
          style={{
            position: "absolute",
            right: "10px",
            top: "50%",
            transform: "translateY(-50%)",
            cursor: "pointer",
          }}
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="16" height="16" rx="8" fill="#F5F5F5" />
          <path
            d="M10.9698 10.9698L8 8M8 8L5.03015 5.03015M8 8L10.9698 5.03015M8 8L5.03015 10.9698"
            stroke="#667085"
            strokeLinecap="round"
          />
        </svg>
      ) : null}
    </div>
  );
};
export default DateRangePicker;

interface FooterProps {
  onSubmit: () => void;
  onCancel: () => void;
}
const Footer = ({ onSubmit, onCancel }: FooterProps) => {
  return (
    <div className={styles["footer"]}>
      <div className={styles["footer-btn-container"]}>
        <Button onClick={onCancel} variant="outlined">
          Cancle
        </Button>
        <Button onClick={onSubmit}>Apply</Button>
      </div>
    </div>
  );
};
interface SideBarProps {
  value: null | (Dayjs.Dayjs | null)[] | undefined;
  onChange?: (
    dates: [Dayjs.Dayjs | null, Dayjs.Dayjs | null],
    formatString: [string, string]
  ) => void;
  onClose: () => void;
  setCustomRange: React.Dispatch<
    React.SetStateAction<[Dayjs.Dayjs | null, Dayjs.Dayjs | null]>
  >;
  selected: string | null;
  setSelected: React.Dispatch<React.SetStateAction<string | null>>;
}
const Sidebar = ({
  onChange,
  onClose,
  setCustomRange,
  selected,
  setSelected,
}: SideBarProps) => {
  const handleSelect = (id: string) => {
    setSelected(id);
    if (id !== "custom") {
      const [start, end] = getCustomDateRange(id);
      setCustomRange([start, end]);
      onChange?.(
        [start, end],
        [start?.format("YYYY-MM-DD") || "", end?.format("YYYY-MM-DD") || ""]
      );
      onClose();
    }
  };
  return (
    <div className={styles["sidebar"]}>
      <div>
        {MENU_SELECT_DATE_RANGE.map((item) => (
          <p
            key={item.id}
            className={`${styles["select-item"]} ${
              selected === item.id ? styles["select-item-active"] : ""
            }`}
            onClick={() => handleSelect(item.id)}
          >
            {item.value}
          </p>
        ))}
      </div>
    </div>
  );
};
