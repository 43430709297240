import { Row } from "antd";
import ComingSoonImg from "../assets/images/comingsoon.png";

export default function ComingSoon() {
  return (
    <Row justify="center" align="middle">
      <img
        src={ComingSoonImg}
        alt=""
        style={{
          objectFit: "cover",
          height: "80%",
          width: "100%",
        }}
      />
    </Row>
  );
}
