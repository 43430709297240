import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Row, Skeleton, Tooltip } from "antd";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Tag from "pages/dashboard/components/Tag";
import { LayoutContent } from "components/Layout/LayoutContent";
import { HtmlEditor } from "components/Pages/Cms/UploadResearch/HtmlEditor";
import Footer from "components/Global/Footer";

import arrow_back_gray from "../../../assets/images/arrow_back_gray.svg";
import { ReactComponent as Download } from "../../../assets/images/download.svg";
import { ReactComponent as Printer } from "../../../assets/images/printer_minimalistic.svg";
import { ReactComponent as Share } from "../../../assets/images/share.svg";
import feedApi from "../../../api/feed";

import { RecentResearchCard } from "./RecentResearchCard";
import { LegalComplianceCard } from "./LegalComplianceCard";
import { checkDevice, formatDate, getMinuteRead } from "utils/helpers";
import { IFeed, defaultFeed } from "types/research";
import { BlockDetail } from "./type";

import styles from "./ResearchView.module.scss";
import { PermissionKey, useCheckPermission } from "hooks/useCheckPermission";
import Button from "components/UI/Button/Button";
import { Button as MuiButton } from "antd";
import { Path } from "routers/route";

interface Props {
  researchId?: string;
  showRecentResearch?: boolean;
  isLinkCompany?: boolean;
}
const ResearchView = ({
  researchId,
  isLinkCompany,
  showRecentResearch = true,
}: Props) => {
  const { isDesktop } = checkDevice();
  const [blocks, setBlocks] = useState<BlockDetail[]>([]);

  const [research, setResearch] = useState<IFeed>(defaultFeed);

  const navigate = useNavigate();

  const { researchId: id } = useParams();
  const domain = window.location.host;
  const shareLink = `${domain}/research/${researchId ?? id}`;
  const newResearchId = researchId || id;
  const isInMiniViewMode = Boolean(researchId);
  const dashboardPermission = useCheckPermission([
    PermissionKey.EDIT_FEED_DASHBOARD,
    PermissionKey.DELETE_FEED_DASHBOARD,
  ]);
  const isLinkCompanyLocal = localStorage.getItem("isLinkCompany");
  useEffect(() => {
    if (newResearchId) {
      // fetch research
      getResearch(newResearchId);
    }
    return () => {
      setResearch(defaultFeed);
      setBlocks([]);
    };
  }, [newResearchId]);

  useEffect(() => {
    if (isInMiniViewMode) {
      localStorage.setItem("isLinkCompany", isLinkCompany ? "true" : "false");
    }
  }, [isInMiniViewMode]);

  const getResearch = async (id: string) => {
    const researchData = await feedApi.get(id);
    if (researchData) {
      setBlocks(researchData.blocks);
      setResearch({
        ...researchData,
      });
    }
  };

  const goBack = () => {
    // Go previous page
    navigate(-1);
  };

  const onSaveFeed = async () => {
    // if (research.isSaveToContent) {
    //   toast.success("This article has already been saved.");
    //   return;
    // }
    try {
      const result = await feedApi.saveContent(research.id);
      setResearch({
        ...research,
        isSaveToContent: result,
      });
      toast.success(
        result
          ? "Article saved successfully."
          : "Article removed from saved content."
      );
    } catch (error) {
      toast.error("Failed to save article.");
    }
  };

  const onGeneratePdf = async (isPrint: boolean) => {
    if (!research?.id) {
      return;
    }
    try {
      const dataRes: {
        data: Buffer;
      } = await feedApi.generatePdf(
        research.id,
        `${isPrint ? "Print" : "Download"} for BONDVEXITY on ${formatDate(
          new Date()
        )}`,
        `Article: ${researchId ?? id}`,
        formatDate(research.createdAt),
        getMinuteRead(blocks[0]?.content || "")
      );
      if (!dataRes?.data) {
        toast.error("Failed to download research.");
        return;
      }

      // Convert Buffer to Uint8Array
      const uint8Array = new Uint8Array(dataRes.data);

      // Convert Uint8Array to Blob
      const blob = new Blob([uint8Array], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      if (isPrint) {
        // Open the URL in a new tab
        const newWindow = window.open(url);

        if (newWindow) {
          // Trigger the print dialog in the new tab
          newWindow.onload = () => {
            newWindow.print();
          };
        }
        return;
      }

      // Create a link element
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `${research.name}-${new Date().getTime()}.pdf`
      );

      // Append to the document and trigger download
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(url);
      toast.success("Article downloaded successfully.");
    } catch (error) {
      toast.error("An error occurred while downloading the research.");
      console.error(error);
    }
  };

  const onCopy = () => {
    toast.success("The research URL has been shared and copied.");
  };

  const onEditResearch = () => {
    if (!newResearchId) {
      return;
    }
    if (isLinkCompanyLocal === "true" && research.company?.id) {
      navigate(
        Path.ResearchEdit.replace(":researchId", newResearchId).replace(
          ":companyId",
          research.company?.id
        )
      );
      return;
    }
    navigate(
      Path.ResearchEdit.replace(":researchId", newResearchId).replace(
        ":companyId",
        "dashboard"
      )
    );
  };

  const actions = [
    {
      title: "Save to My Content",
      icon: (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            cursor: "pointer",
          }}
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.449 5.12787C16.449 2.83547 14.8818 1.9165 12.6253 1.9165H7.32623C5.1391 1.9165 3.5 2.77281 3.5 4.97498V17.2448C3.5 17.8497 4.15079 18.2306 4.67794 17.9349L9.99624 14.9516L15.2686 17.9299C15.7966 18.2273 16.449 17.8463 16.449 17.2406V5.12787Z"
            stroke={research.isSaveToContent ? "#FFFFFF" : "#667085"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.89258 7.52318H12.9912"
            stroke={research.isSaveToContent ? "#FFFFFF" : "#667085"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      title: "Download",
      icon: <Download />,
    },
    {
      title: "Print",
      icon: <Printer />,
    },
  ];

  if (!research?.id) {
    return (
      <Skeleton
        className="skeleton"
        style={{
          marginTop: researchId ? 16 : 27,
          paddingLeft: researchId ? 16 : 30,
          paddingRight: !isDesktop ? 20 : researchId ? 16 : 30,
        }}
      />
    );
  }

  return (
    <LayoutContent
      styleHeader={{
        borderBottom: "1px solid transparent",
        paddingRight: isInMiniViewMode ? 0 : 20,
        marginTop: isInMiniViewMode ? 16 : 0,
        paddingBottom: 0,
      }}
      styleChildrenBody={{
        padding: "0px 16px 0px 0px",
      }}
      styleContainer={
        isDesktop
          ? {}
          : {
              paddingRight: 20,
            }
      }
      styleBody={{
        // height: "calc(100vh - 106px)",
        width: "100%",
        overflowX: "hidden",
      }}
      isSearch={isInMiniViewMode ? false : true}
      showHeader={isInMiniViewMode ? false : true}
      title={
        isDesktop ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={goBack}
          >
            <div className={styles["icon-back"]}>
              <img src={arrow_back_gray} alt="" />
            </div>
            <h5
              style={{
                fontSize: 18,
                fontWeight: 700,
                color: "#162F3F",
              }}
            >
              Article
            </h5>
          </div>
        ) : null
      }
      header={
        <Row>
          {isDesktop &&
          (isLinkCompanyLocal === "true" || dashboardPermission) ? (
            <Button
              sx={{
                bgcolor: "#0091EA",
                borderRadius: 18,
                padding: "8px 18px",
                alignItems: "center",
                textAlign: "center",
                height: 48,
              }}
              onClick={onEditResearch}
            >
              <span>Edit research</span>
            </Button>
          ) : null}
        </Row>
      }
      subHeader={
        <div
          className={styles["research-header"]}
          style={{
            padding: !isDesktop
              ? "0px"
              : isInMiniViewMode
              ? "16px 16px 0px 16px"
              : "0px 0px 0px 30px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              {research.company ? (
                <h3
                  style={{
                    marginBottom: 10,
                  }}
                >
                  Company:{" "}
                  <Link to={`/company/${research.company.id}`}>
                    {research.company.name}
                  </Link>
                </h3>
              ) : null}

              <div
                style={{
                  display: "flex",
                  flexDirection: isInMiniViewMode ? "column" : "row",
                  gap: 12,
                  width: "100%",
                }}
              >
                <div
                  className={styles["info"]}
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Tag type={research.type} />
                  </div>
                </div>

                <div
                  className={styles["actions-container"]}
                  style={
                    {
                      // paddingLeft: isInMiniViewMode ? 0 : 15,
                      // borderLeft: isInMiniViewMode ? "none" : "1px solid #d0d5dd",
                    }
                  }
                >
                  <div className={styles["icons-container"]}>
                    {actions.map((action, index) => {
                      const isSaveToContent =
                        research.isSaveToContent &&
                        action.title === "Save to My Content";
                      return (
                        <Tooltip
                          placement="bottom"
                          title={
                            isSaveToContent
                              ? "Remove saved content"
                              : action.title
                          }
                          key={index}
                        >
                          <MuiButton
                            icon={action.icon}
                            onClick={() => {
                              switch (action.title) {
                                case "Download":
                                  return onGeneratePdf(false);
                                case "Print":
                                  return onGeneratePdf(true);
                                default:
                                  return onSaveFeed();
                              }
                            }}
                            style={{
                              backgroundColor: isSaveToContent
                                ? "#0091EA"
                                : "transparent",
                              borderColor: isSaveToContent
                                ? "#0091EA"
                                : undefined,
                            }}
                          />
                        </Tooltip>
                      );
                    })}

                    <Tooltip placement="bottom" title={"Share"}>
                      <MuiButton
                        style={{
                          backgroundColor: "transparent",
                        }}
                        icon={
                          <CopyToClipboard text={shareLink} onCopy={onCopy}>
                            <Share
                              style={{
                                cursor: "pointer",
                              }}
                            />
                          </CopyToClipboard>
                        }
                      ></MuiButton>
                    </Tooltip>
                  </div>
                  {isInMiniViewMode && (
                    <Link
                      to={`/research-view/${researchId}`}
                      className={styles["view-link"]}
                    >
                      Full Article
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    >
      <div
        style={{
          maxWidth: !isDesktop ? "none" : isInMiniViewMode ? "none" : "60%",
          minWidth: !isDesktop ? "none" : isInMiniViewMode ? "none" : 728,
          width: !isDesktop ? "100%" : "none",
          margin: !isDesktop ? "none" : "0 auto",
        }}
      >
        <div
          style={{
            backgroundColor: "#FFFFFF",
            borderRadius: 10,
            padding: !isDesktop ? 16 : researchId ? 0 : 32,
            boxShadow: researchId ? "initial" : "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <p className={`${styles["research-name"]}`}>{research.name}</p>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 24,
              paddingBottom: 16,
              borderBottom: "1px solid #cccccc",
            }}
          >
            {research.summary ? (
              <p
                className={styles["user-select"]}
                style={{
                  color: "#667085",
                  fontSize: 18,
                  fontWeight: 400,
                  lineHeight: "27px",
                  fontFamily: "Google Sans",
                }}
              >
                {research.summary}
              </p>
            ) : null}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: research.summary ? 16 : 0,
              }}
            >
              <p
                style={{
                  color: "#667085",
                  lineHeight: "24px",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                {formatDate(research.createdAt)}
              </p>
              <div
                style={{
                  width: 4,
                  minWidth: 4,
                  height: 4,
                  backgroundColor: "#667085",
                  margin: "0 8px",
                  borderRadius: "50%",
                }}
              ></div>
              <p
                style={{
                  color: "#667085",
                  lineHeight: "24px",
                  fontSize: 16,
                  fontWeight: 500,
                }}
              >
                {getMinuteRead(blocks[0]?.content || "")}
              </p>
            </div>
          </div>

          {Boolean(blocks.length) && blocks[0].content && (
            <HtmlEditor
              idToolbar="research-view"
              value={blocks[0].content}
              placeholder={""}
              readOnly={true}
            />
          )}
        </div>
        {showRecentResearch && research.recentResearch?.length ? (
          <RecentResearchCard
            recentResearch={research.recentResearch}
            type={research.type}
          />
        ) : null}
        {showRecentResearch ? <LegalComplianceCard /> : null}
      </div>
      {showRecentResearch ? (
        <div className={styles["footer"]}>
          <Footer
            rootStyle={{
              marginBottom: "0px !important",
            }}
          />
        </div>
      ) : null}
    </LayoutContent>
  );
};

export default ResearchView;
