import {
  container,
  fadeInLeft,
  fadedText,
  letter,
} from "components/Pages/LandingPage/animation-variants";
import { motion } from "framer-motion";

import styles from "./Banner.module.scss";

const Banner = ({
  headline,
  description,
}: {
  headline: string;
  description: string;
}) => {
  return (
    <motion.div
      variants={container}
      animate="animate"
      initial="initial"
      className={styles["banner"]}
    >
      <motion.h2>{headline}</motion.h2>
      <motion.p variants={fadeInLeft} className="description">
        {description}
      </motion.p>
    </motion.div>
  );
};

export default Banner;
