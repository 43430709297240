import configApi from "api/config";
import { RootState } from "libs/store";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useCompanyConfigs = () => {
  const [listCreditRisks, setListCreditRisks] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);

  const [listBondVexityViews, setListBondVexityViews] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);
  const sectors = useSelector((state: RootState) => state.user.sectors);

  const [listSectors, setListSectors] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);

  useEffect(() => {
    getCompanyConfig();
  }, []);

  const getCompanyConfig = async () => {
    const creditRiskRes = await configApi.creditRisks();
    const bondVexityViewsRes = await configApi.bondVexityViews();
    setListCreditRisks(creditRiskRes || []);
    setListBondVexityViews(bondVexityViewsRes || []);
  };

  useEffect(() => {
    if (sectors && sectors.length) {
      setListSectors(sectors);
    }
  }, [sectors]);

  return {
    listCreditRisks,
    listSectors,
    listBondVexityViews,
  };
};
export default useCompanyConfigs;
