import { Col } from "antd";

import { LayoutContent } from "components/Layout/LayoutContent";
import { PermissionKey, useCheckPermission } from "hooks/useCheckPermission";
import Feeds from "pages/dashboard/components/Feeds";
import { checkDevice } from "utils/helpers";

export default function MyContent() {
  const editFeedPermission = useCheckPermission(
    PermissionKey.EDIT_FEED_DASHBOARD
  );
  const { isDesktop } = checkDevice();

  const keyColumns = [
    "name",
    "company",
    "sector",
    "user",
    "type",
    "createdAt",
  ].concat(editFeedPermission && isDesktop ? ["action"] : []);

  return (
    <LayoutContent
      title="SAVED CONTENT"
      header={<div />}
      styleChildrenBody={{
        paddingRight: 0,
        paddingTop: 0,
      }}
      styleTitle={
        isDesktop
          ? undefined
          : {
              height: 40,
              display: "flex",
              alignItems: "center",
            }
      }
      styleContainer={{
        height: "100%",
      }}
      styleBody={{
        height: "100%",
      }}
    >
      <Col>
        <Feeds isMyContent={true} keyColumns={keyColumns} />
      </Col>
    </LayoutContent>
  );
}
