import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  ModalProps,
  Select,
  Option,
} from "@mui/joy";

import { Col, Row } from "antd";
import { Controller, useForm } from "react-hook-form";
import { defaultRule, emailRule, phoneRule } from "utils/rules";
import icon_select from "assets/images/icon_select.svg";
import { useEffect } from "react";
import { httpRequestErrors } from "utils/errors";
import { trimObject } from "utils/helpers";
import { toast } from "react-toastify";
import FormInput from "components/UI/Form/FormInput";
import InputPhone from "components/UI/Input/InputPhone";
import userApi from "api/user";

type FormState = {
  id: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  roleKey: number;
  isActive: boolean;
};

type Props = Omit<ModalProps, "children"> & {
  userState: FormState;
  callback: () => void;
};

const FormUserModal: React.FC<Props> = ({
  open,
  onClose,
  userState,
  callback,
}) => {
  const { control, handleSubmit, reset } = useForm<FormState>({
    defaultValues: {
      id: userState.id,
      firstName: userState.firstName,
      lastName: userState.lastName,
      phone: userState.phone,
      email: userState.email,
      roleKey: userState.roleKey,
      isActive: userState.isActive,
    },
  });

  useEffect(() => {
    if (!open) {
      return;
    }
    reset({
      id: userState.id,
      firstName: userState.firstName,
      lastName: userState.lastName,
      phone: userState.phone,
      email: userState.email,
      roleKey: userState.roleKey,
      isActive: userState.isActive,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSubmit = async (data: FormState) => {
    try {
      const bodyRequest: any = trimObject(data);
      delete bodyRequest.id;
      if (userState.id) {
        await userApi.updateUser(userState.id, bodyRequest);
        toast.success("Edit user successfully!");
      } else {
        await userApi.create(bodyRequest);
        toast.success("Add user successfully!");
      }
      callback();
    } catch (error) {
      httpRequestErrors(error);
    }
  };

  return (
    <Modal open={open} onClose={onClose} className="custom-modal" style={{}}>
      <ModalDialog variant="outlined" role="alertdialog">
        <DialogTitle>{userState.id ? "Edit user" : "Add user"}</DialogTitle>
        <Divider />
        <form className="form-content" onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <Row
              style={{
                marginTop: 16,
                paddingRight: 2,
                paddingLeft: 2,
              }}
            >
              <Col
                span={12}
                style={{
                  paddingRight: 10,
                }}
              >
                <FormInput
                  placeholder="First Name"
                  name="firstName"
                  control={control}
                  label={"First Name"}
                  rules={defaultRule("First Name")}
                />
              </Col>
              <Col
                span={12}
                style={{
                  paddingLeft: 10,
                }}
              >
                <FormInput
                  placeholder="Last Name"
                  name="lastName"
                  control={control}
                  label={"Last Name"}
                  rules={defaultRule("Last Name")}
                />
              </Col>
            </Row>

            <Row
              style={{
                marginTop: 16,
                paddingRight: 2,
                paddingLeft: 2,
              }}
            >
              <Col span={24}>
                <FormInput
                  placeholder="you@company.com"
                  name="email"
                  disabled={userState.id ? true : false}
                  control={control}
                  rules={emailRule}
                  label={"E-mail"}
                />
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 16,
                paddingRight: 2,
                paddingLeft: 2,
              }}
            >
              <Col span={24}>
                <Controller
                  control={control}
                  name="phone"
                  rules={phoneRule}
                  render={({ field, fieldState }) => (
                    <FormControl>
                      <FormLabel>Phone number</FormLabel>
                      <InputPhone
                        value={field.value}
                        onChange={(newValue) => field.onChange(newValue)}
                      />
                      {fieldState.error && (
                        <FormHelperText
                          sx={{
                            color: "red",
                          }}
                        >
                          {fieldState.error.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              </Col>
            </Row>
            <Row
              style={{
                marginTop: 16,
                paddingRight: 2,
                paddingLeft: 2,
              }}
            >
              <Col span={24}>
                <Controller
                  name="roleKey"
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <FormControl>
                        <FormLabel required>Role</FormLabel>
                        <Select
                          {...field}
                          value={field.value}
                          onChange={(event, newValue) =>
                            field.onChange(newValue)
                          }
                          indicator={<img src={icon_select} alt="" />}
                          style={{
                            height: 48,
                            padding: "11px 16px",
                          }}
                        >
                          {[
                            {
                              id: 0,
                              name: "System Admin",
                            },
                            {
                              id: 2,
                              name: "Member",
                            },
                          ].map((elRole) => {
                            return (
                              <Option key={elRole.id} value={elRole.id}>
                                {elRole.name}
                              </Option>
                            );
                          })}
                        </Select>
                        {fieldState.error && (
                          <FormHelperText
                            sx={{
                              color: "red",
                            }}
                          >
                            {fieldState.error.message}
                          </FormHelperText>
                        )}
                      </FormControl>
                    );
                  }}
                />
              </Col>
            </Row>
            {userState.id && (
              <Row
                style={{
                  marginTop: 16,
                  paddingRight: 2,
                  paddingLeft: 2,
                }}
              >
                <Col span={24}>
                  <Controller
                    name="isActive"
                    control={control}
                    render={({ field, fieldState }) => {
                      return (
                        <FormControl>
                          <FormLabel required>Is Active</FormLabel>
                          <Select
                            {...field}
                            value={field.value}
                            onChange={(event, newValue) =>
                              field.onChange(newValue)
                            }
                            indicator={<img src={icon_select} alt="" />}
                            style={{
                              height: 48,
                              padding: "11px 16px",
                            }}
                          >
                            {[
                              {
                                id: true,
                                name: "Active",
                              },
                              {
                                id: false,
                                name: "Inactive",
                              },
                            ].map((item, idx) => {
                              return (
                                <Option key={idx} value={item.id}>
                                  {item.name}
                                </Option>
                              );
                            })}
                          </Select>
                          {fieldState.error && (
                            <FormHelperText
                              sx={{
                                color: "red",
                              }}
                            >
                              {fieldState.error.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      );
                    }}
                  />
                </Col>
              </Row>
            )}
          </DialogContent>
          <DialogActions>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                gap: 16,
              }}
            >
              <Button
                onClick={(e) => onClose?.(e, "backdropClick")}
                variant="outlined"
                style={{
                  width: "100%",
                  height: 44,
                  borderRadius: 30,
                }}
              >
                Cancel
              </Button>
              <Button
                style={{
                  width: "100%",
                  height: 44,
                  background: "#0091EA",
                  borderRadius: 30,
                }}
                type="submit"
              >
                {userState.id ? "Edit" : "Add"}
              </Button>
            </div>
          </DialogActions>
        </form>
      </ModalDialog>
    </Modal>
  );
};

export default FormUserModal;
