import Card from "pages/landing-page/components/Card";
import dashboardImage from "assets/images/about-us/dashboard.svg";

import styles from "./CoreValue.module.scss";

const CORE_VALUES = [
  {
    title: "Integrity",
    description:
      "We operate with the highest level of integrity, always putting the interests of our clients first.",
  },
  {
    title: "Excellence",
    description:
      "We strive for excellence in everything we do, from our research and technology to our client service.",
  },
  {
    title: "Innovation",
    description:
      "We are constantly evolving, looking for new ways to enhance our platform and provide greater value to our users.",
  },
];
const OurCoreValue = () => {
  return (
    <div className={styles["core-value-container"]}>
      <div className={styles["core-value"]}>
        <h2>Our Core Values</h2>
        <div className={styles["core-value__body"]}>
          <div className={styles["core-value__content"]}>
            {CORE_VALUES.map((value, index) => {
              return (
                <div className={styles["core-value__item"]}>
                  <div className={styles["core-value__item-no"]}>
                    <span>{`0${index + 1}`}</span>
                  </div>
                  <Card
                    key={index}
                    title={value.title}
                    description={value.description}
                    cardStyles={{
                      backgroundColor: "#fff",
                      borderRadius: 20,
                      marginBottom: 20,
                      maxWidth: "100%",
                    }}
                  />
                </div>
              );
            })}
          </div>
          <img src={dashboardImage} alt="core-value" />
        </div>
      </div>
    </div>
  );
};

export default OurCoreValue;
