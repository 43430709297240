import Banner from "../components/Banner";
import Card from "../components/Card";
import WhyChoose from "components/Pages/LandingPage/WhyChoose";
import KeyFeatures from "components/Pages/LandingPage/KeyFeatures";

import moneyImage from "assets/images/who-we-serve/money.svg";
import marketingImage from "assets/images/who-we-serve/marketing.svg";
import funcProjectionImage from "assets/images/who-we-serve/fund-projection.svg";
import buySideImage from "assets/images/who-we-serve/buy-side.svg";

import styles from "./WhoWeServe.module.scss";

const BANNER = {
  title:
    "Empowering Professional Investors with Clear, Actionable Credit Insights",
  description:
    "Bondvexity serves a diverse range of professionals who need fast, reliable, and unbiased credit research. We are dedicated to providing insights that drive decision-making and strategy across the financial spectrum:",
};

const SECTIONS = [
  {
    title: "Institutional Investors & Asset Managers",
    description:
      "From hedge funds to pension funds, Bondvexity provides  in-depth analysis that helps you navigate the credit market with confidence. Our research identifies market inefficiencies, highlights relative value opportunities, and supports portfolio allocation decisions.",
    image: moneyImage,
  },
  {
    title: "Fund Managers & Analysts",
    description:
      "Access concise, no-BS research tailored to support your investment strategies. We filter the noise, deliver key insights, and provide data-driven recommendations that keep your fund ahead of market trends.",
    image: funcProjectionImage,
  },
  {
    title: "Advisory Firms & Consultants",
    description:
      "Enhance your advisory capabilities with insights that go beyond standard financial reports. Bondvexity’s independent research supports restructuring, M&A due diligence, and strategic financial consulting with real-world, actionable data.",
    image: marketingImage,
  },
  {
    title: "Buy-Side Professionals",
    description:
      "As a platform built by investors for investors, we understand your needs. Bondvexity cuts through the clutter with clear, actionable insights backed by real market experience—no sell-side agendas or unnecessary fluff.",
    image: buySideImage,
  },
];
const WhoWeServe = () => {
  return (
    <div className={styles["who-we-serve"]}>
      <Banner description={BANNER.description} headline={BANNER.title} />
      <div className={styles["who-we-serve__content"]}>
        {SECTIONS.map(({ description, image, title }, index) => (
          <Card
            title={title}
            description={description}
            image={image}
            key={index}
            cardStyles={{
              maxWidth: 440,
              borderRadius: 20,
              backgroundColor: "#fff",
              padding: 30,
            }}
          />
        ))}
      </div>
      <div className={styles["why-choose-container"]}>
        <WhyChoose />
        <KeyFeatures />
      </div>
    </div>
  );
};

export default WhoWeServe;
