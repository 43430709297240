import Button from "components/UI/Button/Button";
import lifeAtImageRight from "assets/images/about-us/life-at1.png";
import lifeAtImageLeft from "assets/images/about-us/life-at2.png";

import styles from "./LifeAtBondVexity.module.scss";

const LifeAtBondVexity = () => {
  return (
    <div className={styles["life-at-bondvexity"]}>
      <div className={styles["life-at-bondvexity__content"]}>
        <div className={styles["life-at-bondvexity__left"]}>
          <div className={styles["text"]}>
            <h2>Life at Bondvexity</h2>
            <p>
              Join a dynamic team where your contributions are valued, your
              growth is supported, and your work makes a difference. At
              Bondvexity, we’re not just building a platform, we’re building the
              future of credit research.
            </p>
            <p>Let’s transform the industry together!</p>
          </div>
          <button>Get Started</button>
        </div>

        <div className={styles["life-at-bondvexity__right"]}>
          <img
            src={lifeAtImageLeft}
            alt="life-at-bondvexity-left"
            className={styles["img-left"]}
          />
          <img
            src={lifeAtImageRight}
            alt="life-at-bondvexity-right"
            className={styles["img-right"]}
          />
        </div>
      </div>
    </div>
  );
};

export default LifeAtBondVexity;
