import { Row, Col } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "assets/images/icon_logo_auth.svg";
import { useEffect } from "react";
import { useCountdownTime } from "hooks/count-down-time";
import authApi from "api/auth";
import { httpRequestErrors } from "utils/errors";

const ConfirmEmailForgotPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isTimerRunning, startTimer, timer } =
    useCountdownTime("forgot_password");
  const { isSetNewPassword, email } = location.state;

  useEffect(() => {
    if (!location.state) {
      navigate("/sign-in");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  const handleResend = async () => {
    if (isTimerRunning) {
      return;
    }

    try {
      await authApi.forgotPassword({
        email,
        isSetNewPassword: isSetNewPassword || false,
      });
      startTimer();
    } catch (error) {
      httpRequestErrors(error);
    }
  };

  return (
    <div className="auth-form">
      <div className="form-content">
        <img className="form-logo" src={logo} alt="" />

        <div className="form">
          <div>
            <h2> Check your inbox</h2>
            <h4>
              {" "}
              {isSetNewPassword
                ? "We have sent you a link to verify your account. Please check the mailbox at"
                : "We’ve sent you a forgot password link to the email address "}{" "}
              <span
                className=""
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  color: "blue",
                }}
              >
                {location.state?.email}
              </span>
            </h4>
          </div>
        </div>
        <div className="btn-container">
          <Row>
            <Col span={24}>
              <button
                disabled={isTimerRunning}
                type="submit"
                className={`btn-get-started ${
                  isTimerRunning ? "btn-disabled" : ""
                }`}
                onClick={handleResend}
              >
                {isTimerRunning ? `Resend in ${timer}s` : "Resend link"}
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmailForgotPassword;
