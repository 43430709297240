import { FormControl, FormLabel } from "@mui/joy";
import { TimePicker as AntdTimePicker, type TimePickerProps } from "antd";
import styles from "./TimePicker.module.scss";

interface Props extends TimePickerProps {
  title: string;
}
const TimePicker = ({ title, ...restProps }: Props) => {
  return (
    <FormControl className={styles["time-picker"]}>
      <FormLabel>{title}</FormLabel>
      <AntdTimePicker {...restProps} />
    </FormControl>
  );
};

export default TimePicker;
