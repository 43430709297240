import { Row } from "antd";
import { useState } from "react";
import "./style.scss";
import Button from "components/UI/Button/Button";
import { LayoutContent } from "components/Layout/LayoutContent";
import { PermissionKey, useCheckPermission } from "hooks/useCheckPermission";
import CompanyTable from "pages/dashboard/components/CompanyTable";
import { checkDevice } from "utils/helpers";

const Companies = () => {
  const addCompanyPermission = useCheckPermission(PermissionKey.ADD_COMPANY);
  const [openFormCompanyByParent, setOpenFormCompanyParent] = useState(false);
  const { isDesktop } = checkDevice();
  const keyColumns = [
    "name",
    "sectors",
    "creditRisks",
    "bondVexityViews",
    "user",
  ].concat(isDesktop ? ["action"] : []);

  return (
    <LayoutContent
      title="COMPANIES"
      styleTitle={
        isDesktop
          ? undefined
          : {
              height: 40,
              display: "flex",
              alignItems: "center",
            }
      }
      styleContainer={
        isDesktop
          ? {}
          : {
              paddingRight: 20,
            }
      }
      header={
        <Row>
          {addCompanyPermission && isDesktop ? (
            <Button
              sx={{
                bgcolor: "#0091EA",
                borderRadius: 18,
                padding: "12px 35px",
                alignItems: "center",
                textAlign: "center",
                height: 48,
              }}
              onClick={() => {
                setOpenFormCompanyParent(true);
              }}
            >
              <span>Add company</span>
            </Button>
          ) : null}
        </Row>
      }
    >
      <CompanyTable
        keyColumns={keyColumns}
        openFormCompanyByParent={openFormCompanyByParent}
        setOpenFormCompanyParent={setOpenFormCompanyParent}
      />
    </LayoutContent>
  );
};

export default Companies;
