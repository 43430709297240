import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment/moment";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isBetween from "dayjs/plugin/isBetween";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { Alert, AlertFrequency } from "types/alert-settings";

dayjs.extend(isToday);
dayjs.extend(isBetween);
dayjs.extend(quarterOfYear);

export const readFileAsBlob = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader() as any;
    reader.onload = () => {
      // Resolve with the resulting Blob
      resolve(new Blob([reader?.result], { type: file.type }));
    };

    reader.onerror = (error: any) => {
      reject(error);
    };

    reader.readAsArrayBuffer(file);
  });
};

export const formatDate = (
  value: any,
  formatString: string = "hh:mmA DD MMM YY"
) => {
  return moment(value).format(formatString);
};

export const constructFullName = (firstName?: string, lastName?: string) => {
  let fullName = "";
  if (firstName) {
    fullName += ` ${firstName}`;
  }
  if (lastName) {
    fullName += ` ${lastName}`;
  }
  return fullName || "Anonymous";
};

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (this: ThisParameterType<T>, ...args: Parameters<T>) => void {
  let timeout: ReturnType<typeof setTimeout>;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

export function trimValue<T>(value: T) {
  if (typeof value === "string") {
    return value.trim();
  }

  return value;
}

export const trimObject = (object: any) => {
  const newObject: Record<string, any> = {};

  Object.keys(object).forEach((key: string) => {
    newObject[key] = trimValue(object[key]);
  });

  return newObject;
};

export const generatePDF = (
  name: string,
  elementId: string,
  type: "download" | "print",
  callback: () => void
) => {
  const input = document.getElementById(elementId);
  const imgHeight = 44; // Example height in mm
  if (input) {
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm

      const canvasHeight = canvas.height;
      const canvasWidth = canvas.width;

      // Calculate the height in mm
      let imgHeightInMm = (imgHeight / canvasHeight) * canvasWidth;
      imgHeightInMm = (canvas.height * imgWidth) / canvas.width;

      let heightLeft = imgHeightInMm;
      let position = 0;

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeightInMm);
      heightLeft -= pageHeight;

      // Add new pages if the content overflows
      while (heightLeft >= 0) {
        position = heightLeft - imgHeightInMm; // top position of the next image part on the new page
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeightInMm);
        heightLeft -= pageHeight;
      }
      callback();
      if (type === "download") {
        pdf.save(`${name}_${new Date().getTime()}.pdf`);
        return;
      }
      const pdfBlob = pdf.output("blob");
      const blobURL = URL.createObjectURL(pdfBlob);

      // Open the PDF in a new window/tab
      const printWindow = window.open(blobURL);

      // Attempt to print the PDF automatically (may not work in all browsers)
      if (printWindow) {
        setTimeout(() => {
          try {
            printWindow.print();
          } catch (e) {
            toast.error(
              "Please click the print button in the PDF viewer to print your document."
            );
          }
        }, 500); // Adjust delay as needed
      } else {
        toast.error(
          "Failed to open the print preview. Please check your popup blocker settings."
        );
      }
    });
  }
};

export const getFirstAndLastInitials = (
  firstName?: string,
  lastName?: string
): string => {
  if (!firstName && !lastName) {
    return "N/A";
  }
  const fullName = `${firstName || ""} ${lastName || ""}`.trim();
  const words = fullName.split(" ");
  const firstInitial = words[0][0];
  const lastInitial = words[words.length - 1][0];
  return firstInitial + lastInitial;
};

export const isAllowedFileType = (fileName: string): boolean => {
  // Define the allowed extensions
  const allowedExtensions = /(\.doc|\.docx|\.pdf)$/i;

  // Check if the file name matches any of the allowed extensions
  return allowedExtensions.test(fileName);
};

export const getCustomDateRange = (
  rangeType: string
): [dayjs.Dayjs, dayjs.Dayjs] => {
  const now = dayjs();

  switch (rangeType) {
    case "last24Hours":
      return [now.subtract(24, "hour"), now];

    case "last7Days":
      return [now.subtract(7, "day"), now];

    case "last30Days":
      return [now.subtract(30, "day"), now];
    case "last90Days":
      return [now.subtract(90, "day"), now];

    case "thisMonth":
      return [now.startOf("month"), now.endOf("month")];

    case "lastMonth":
      const lastMonth = now.subtract(1, "month");
      return [lastMonth.startOf("month"), lastMonth.endOf("month")];

    case "thisQuarter":
      return [now.startOf("quarter"), now.endOf("quarter")];

    case "lastQuarter":
      const lastQuarter = now.subtract(1, "quarter");
      return [lastQuarter.startOf("quarter"), lastQuarter.endOf("quarter")];

    case "thisYear":
      return [now.startOf("year"), now.endOf("year")];

    case "lastYear":
      const lastYear = now.subtract(1, "year");
      return [lastYear.startOf("year"), lastYear.endOf("year")];

    default:
      return [now.subtract(24, "hour"), now];
  }
};

export const renderAuthor = (user: { firstName: string; lastName: string }) => {
  if (!user || (!user.firstName && !user.lastName)) {
    return "Anonymous";
  }
  return `${user.firstName || ""} ${user.lastName || ""}`.trim();
};

export const getMinuteRead = (value: string) => {
  const wordsPerMinute = 150; // Average reading speed
  const words = value.split(" ").length;
  const minutes = Math.ceil(words / wordsPerMinute);
  return `${minutes} minute(s) read`;
};

export const isValidFormatId = (value: string) => {
  const regex =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
  return regex.test(value);
};

export const checkDevice = () => {
  const width = window.innerWidth;
  return {
    isMobile: width <= 500,
    isTablet: width > 500 && width <= 1028,
    isDesktop: width > 1028,
  };
};

export const renderFrequency = (alert: Alert) => {
  if (alert.frequency === AlertFrequency.DAILY) {
    return `Daily at ${alert.deliveryTime}`;
  }

  return `${alert.weekDays
    ?.map((el) => {
      return dayjs().day(el).format("dddd");
    })
    .join(", ")} at ${alert.deliveryTime}`;
};
